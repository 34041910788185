import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useDownloadContact = (CardId:any) => {
  const [data, setData] = useState<any>([]);
  const user = useSelector((state: RootState) => state.user);
//   console.log(user)


    const fetched = async () => {
      try {
        await axiosBaseInstance
          .get(`/Creator/GetContact-DownloadUrl?CardId=${CardId}`, {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res) => {
            console.log(res.data.data);

            setData(res.data.data);
            console.log(data)
            const response = JSON.stringify(res.data.data)
            localStorage.setItem("download", response)
            return res.data.data
          });
      } catch (error: any) {
        console.log(error);
      }
    };

    ;


  return { fetched,data };
};

export default useDownloadContact;
