import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";



interface searchBrand{
    brandItem:any
}
const useFetchwhatsappSearch = () => {

  const navigate =useNavigate()
  const user = useSelector((state: RootState) => state.user);

  const fetchSingleWhatsAppLink = async (value:searchBrand) => {

    const {brandItem}= value
    return await axiosBaseInstance
      .get(`/WassapviewLinks/CheckBrand?brandItem=${brandItem}`, {
       
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        console.log(res.data)
        if(res.data.status=== true){
          showNotification({
            message: res.data.data,
            color: "green",
          });
    navigate("/dashboard/whatsapp-link/generate-brand-link")

        }else{
          showNotification({
            message: res.data.data,
            color: "red",
          });
        }
      
        return res.data
      }).catch((err:any)=>{

      });
  };
 
  return { fetchSingleWhatsAppLink };
};

export default useFetchwhatsappSearch;
