import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";


interface BulkEmailForm {
email:any
subject:string
amount:any
message:string

   
  }
  

const useSendBulkEmail = () => {
  const [loading, setLoading] = useState(false)
  
  const user = useSelector((state: RootState) => state.user);

  const sendBulkEmail = async (values: BulkEmailForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .post("/BulkEmail/send-bulk-email", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        if(res.data.Status === false){
          showNotification({
         message:res.data.Message,
         color:"red"
          })
        }else{
            showNotification({
                message:res.data.message,
                color:"green"
                 })
        }
        setLoading(false)
        

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { sendBulkEmail , loading};
};

export default useSendBulkEmail;
