import { useDispatch, useSelector } from "react-redux";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { WalletFund } from "../../types/auth/authInterfaces";
// import { FormikValues } from "formik";
import { useState } from "react";
import { RootState } from "../../redux/store";

import { addUser } from "../../redux/reducers/userReducer";
import { updateWallet } from "../../redux/reducers/walletReducer";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import fundaccountReducer, {
  funds,
} from "../../redux/reducers/fundaccountReducer";

export default function useFundWallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  const [results, setResults] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState("");

  const fundWallet = async (data: WalletFund) => {
    setLoadings(true);

    return await axiosBaseInstance
      .post("/Wallet/Initiate-Wallet-Fund", data, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        setLoadings(false);
        setResults(res.data);
        console.log("passed", res.data);
        if (res.data.status === true) {
          dispatch(funds(res.data.data));
          // dispatch(updateWallet(response.data.data.accountDetails.walletData));
        } else {
          console.log(res);
          showNotification({
            // title: "Error",
            message: res.data.Message || "an error occurred. Try again",
            color: "yellow",
          });
        }

        return res.data.data;
      })
      .catch((error: any) => {
        setLoadings(false);
        setErrors(error.message);
        console.log(error);

        showNotification({
          // title: "Error",
          message:
            error?.response?.data?.message ||
            error?.mesage ||
            "an error occurred. Try again",
          color: "yellow",
        });
      });
  };

  return { loadings, results, errors, fundWallet };
}
