import React, { useState } from 'react'
import { Button, MiddleModal, OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';

import useGetCreatedCard from '../../../hooks/creator-hooks/useGetCreatedCards';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import ListModal from '../Home/components/ListModal';
import { LuRotateCw } from 'react-icons/lu';
import { TiDeleteOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import moment from 'moment';


const PendingWhatsappMessage = () => {
  const [toDelete ,setToDelete] = useState(false)
    const navigate = useNavigate()

    const resendMessage = ()=>{
        navigate("/dashboard/bulk/bulk-whatsapp-messages/sent/resend/:id")
    }

    const deleteItem = ()=>{
      setToDelete((prevOption)=>!prevOption)
    }
    const [isModal, setIsModal] = useState(false);
    

  
    const hideSubmitContactModal = () => {
        setIsModal(false);
      };
      const openSubmitContactModal = () => {
    
        setIsModal(true);
      };

const {data} = useFetchBulkItems("/WhatsAppMessages/get-pending-messages")
  return (
 <Box w="100%" height="100%">
  <Flex flexDirection="column">
  {
    isModal && 

 <ListModal hideModal={hideSubmitContactModal} onclose={hideSubmitContactModal}>
     <Box onClick={resendMessage} mb={2} fontWeight={600}>
  <Flex alignItems="center" height="100%">
  <LuRotateCw />
<Box ml={3}>
Resend
</Box>
  </Flex>

 </Box>
     <Box mb={2} fontWeight={600}>
     <Flex alignItems="center" height="100%">
     <TiDeleteOutline />
<Box ml={3}>Delete
</Box>
  </Flex>
     </Box>

    </ListModal>
 }
     
 <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-whatsapp-message" text="Pending Messages" />

</Flex> 
    </Box>


    {
  data?.data?.map((props:any, index:number)=>{
    const limitText = props.message.substring(0,35)
    return (
      <Box width="100%" mb={4}>
<Link href={`/dashboard/bulk/bulk-whatsapp-messages/sent/resend/${props.id}`}>
<Flex justifyContent="space-between" alignItems="center">
        <Box w=" 70px" h="70px" bg="#d6f2d7" textAlign="center" borderRadius="100% " fontWeight={600} fontSize={20} color="green"> 
            <Flex justifyContent="center" alignItems="center" h="100%">
{props.name.charAt(0)}
            </Flex> 
            </Box>
        <Box width="70%">
            <Box w="100%">
                <Flex justifyContent="space-between">
                <Box>{limitText}...</Box>
<Box ml={4}>

<HiOutlineDotsVertical
              onClick={(e) => {
                e.preventDefault(); 
                openSubmitContactModal();
              }}
              fontSize={20}
            />

</Box>
                </Flex>

            </Box>
            <Box w="100%" mt={4}>
                <Flex justifyContent="space-between">
                <Box fontSize={14} color="grey">{moment(props.createdDate).format("MM/D/YYYY")}</Box>
<Box bg="green" color="white" w="80px" textAlign="center" borderRadius="20px" p="2px">pending</Box>
                </Flex>

            </Box>
        </Box>
    </Flex> 
</Link>
{
    isModal && 

 <ListModal hideModal={hideSubmitContactModal} onclose={hideSubmitContactModal}>
     <Box onClick={(()=>{
      navigate(`/dashboard/bulk/bulk-whatsapp-messages/sent/resend/${props.id}`)
     })} mb={2} fontWeight={600}>
  <Flex alignItems="center" height="100%">
  <LuRotateCw />
<Box ml={3}>
Resend
</Box>
  </Flex>

 </Box>
     <Box mb={2} fontWeight={600}>
     <Flex alignItems="center" height="100%">
     <TiDeleteOutline />
<Box onClick={deleteItem} ml={3}>Delete
</Box>
  </Flex>
     </Box>

    </ListModal>
 }{
  toDelete && 

   <MiddleModal onclose={deleteItem}>

<Box w={300} h={100}>

<Box mt={2} mb={4}>Are you sure you want to Delete</Box>
<Box>

  <Flex justifyContent="space-evenly">
    <Button onClick={(()=>{
       navigate(`/dashboard/bulk/bulk-whatsapp-messages/sent/delete/${props.id}`)
    })} variant="primary" size="small">Sure</Button>
    <Button onClick={deleteItem} variant="secondary" size="small">Cancel</Button>
  </Flex>
</Box>

</Box>

   </MiddleModal>  
 }
   
 </Box>
    )
  })
 }

  </Flex>

 </Box>
  )
}

export default PendingWhatsappMessage