import { Box, Flex, Image } from "@chakra-ui/react";
import {
 
    Button,
  OtherHeader,
  PageHeader,
} from "../../../components";


import { useNavigate } from "react-router-dom";

import { PiWarningCircle } from "react-icons/pi";
import { useEffect, useState } from "react";
import successful from "../../../assets/images/success.jpg"
import useCreateCard from "../../../hooks/creator-hooks/useCreateCard";
import copy from "clipboard-copy"
import { showNotification } from "@mantine/notifications";

 
const ReviewCard = () => {
  
 const {createCard, loading} = useCreateCard()


const [successed, setSuccessed] = useState(false)
const [cardlink, setCardlink] = useState<any>()
const[formReviewData, setFormReviewData] = useState<any>()
const[saveChecks, setSaveChecks] = useState<any>()

 
    const navigation = useNavigate()
 
   
    const temporarilyMoveToNext = ():void=>{
        navigation("/dashboard/creator/become-a-creator")
    }

    useEffect(() => {
      // console.log(cardlink);
    }, [cardlink]);

    const copyLink = ()=>{
      copy(cardlink);
    showNotification({
      message: "Link copied. Share with friends",
      color: "green",
    });
    }
const cardFormSetups:any = [];

for (const key in saveChecks) {
  if (saveChecks.hasOwnProperty(key) && saveChecks[key]) {
    cardFormSetups.push({ fieldName: key });
  }
}

// console.log(cardFormSetups);


const handleSubmit = async () => {

    const cardValues = {
      name: formReviewData.name,
      title: formReviewData.title,
      whatsappNumber: formReviewData.whatsappNumber,
      address: formReviewData.address,
      description: formReviewData.description,
      contactLimit: formReviewData.contactLimit,
      totalFee: formReviewData.contactLimit*10,
      cardFormSetups
    };

    try {
      await createCard(cardValues).then((res)=>{
        setCardlink(res.data.cardLink)
        // console.log(res)

        console.log(cardlink)
        setSuccessed(true)
      });
      // console.log("API response:", response);
   
     
      localStorage.removeItem("checkboxValues")
      localStorage.removeItem("")
    } catch (error) {
      console.log("API error:", error);
  
    }
  };
  
useEffect(()=>{
const getDtata:any = localStorage.getItem("contactform")
const getChecks:any = localStorage.getItem("checkboxValues")
// console.log(JSON.parse(getDtata))
// console.log(JSON.parse(getChecks))
setFormReviewData(JSON.parse(getDtata))
setSaveChecks(JSON.parse(getChecks))
},[])

  return !successed? (
    <Box>
            
      <OtherHeader />

        <PageHeader text="Review New Card" link="/dashboard/creator/card-form" />

       <Box width="100%" h="100%">

        <Flex flexDirection="column" justifyContent="center">

            <Box width="100%" fontWeight="600" fontSize="12px" >
                <Flex justifyContent="space-between ">
                    <Box>{formReviewData?.name}</Box>
                    <Box>{formReviewData?.title}</Box>
                </Flex>
                <Box mt={2}>
                <hr/>

                </Box>
            </Box>
            <Box width="100%" fontSize="12px"textAlign="center" >
                <Flex justifyContent="center">
                <Box width="80%" marginTop={12}>
               
                {formReviewData?.description}
                </Box>
              
                </Flex>
         
            </Box>

            <Box width="100%" fontWeight="600" fontSize="12px"  mt={12}>
          
                    <Box>Contact limit</Box>
                
                <Box mt={2}>
                <hr/>

                </Box>
            </Box>
            <Flex justifyContent="space-between " fontSize="12px"  mt={2}>
                    <Box>Contact Limit</Box>
                    <Box>{formReviewData?.contactLimit}</Box>
                </Flex>
            <Box width="100%" fontSize="12px"textAlign="center" h="100px" >
                
         
            </Box>
        </Flex>
        <Box width="100%" fontWeight="600" fontSize="12px"  mt={8}>
          
          <Box fontSize={12}>Contact Summary</Box>
      
      <Box mt={2}>
      <hr/>

      </Box>
      <Box mt={3}>
        <Flex justifyContent="space-between">
            <Box>
                Total Fee
            </Box>
            <Box>
            N{formReviewData?.contactLimit*10}
            </Box>
        </Flex>
      </Box>
  </Box>
       </Box>
  
    <Box mt={4} fontSize="10px">
        <Flex>
            <Box mt="3px">
            <PiWarningCircle color="#0accf7" />
            </Box>
      <Box ml={2}>The fee for the payment of card you want to <br/> create would be deducted from wallet</Box>
        </Flex>

    </Box>



<Box marginBottom="20px" marginTop="30px">
                  <Button  onClick={handleSubmit}  variant="primary" size="normal">
                  {
      loading?"Please Wait ...":"Create card"}
                  </Button>
                </Box>

    </Box>
  ):(
    <Box>
            
      <OtherHeader />

      

<Box textAlign="center" mt={16}>
    <Box width="100%"  height="200px" textAlign="center" bg="">
        <Image width="100%" height="100%" objectFit="contain" src={successful} alt="successful"/></Box>
    <Box fontWeight="bold" mb={2}>Successfully created your card</Box>
    <Box>
        
        <span onClick={copyLink} style={{
            textDecoration:"underline",
            color:"#12d1ee",
            marginRight:"3px"
        }}>    Copy your code </span> <span>and  share it out to your  social media, website, emails, direct messages or anywhere you want to share it. 
    </span>


    </Box>
</Box>

<Box marginBottom="20px" marginTop="50px">
                  <Button  onClick={temporarilyMoveToNext}  variant="primary" size="normal">
   Done
                  </Button>
                </Box>

    </Box>
  );
};

export default ReviewCard;














