import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { updateEmail } from "../../redux/reducers/emails";
import { updatePhoneNumber } from "../../redux/reducers/phoneNumbers";

const useFetchContactFromDb
 = () => {
  const [data, setData] = useState<any>([]);
  const [loadContact, setLooadContact] =useState(false)
  const user = useSelector((state: RootState) => state.user);
  const dispatch =useDispatch()
//   console.log(user)
 const navigate = useNavigate()
    const fetched = async ({businessType, Quantity}:any) => {
      try {
        setLooadContact(true)
        await axiosBaseInstance
          .get(`/BulkSms/get-phone-numbers-fromDb?businessType=${businessType} &Quantity=${Quantity}`, {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res:any) => {
            if(res.data.status === true){
                setLooadContact(false)
                showNotification({
                    message:"Uploaded contacts fetched",
                    color:"green"
                      })
                navigate("/dashboard/bulk/bulk-messages/send-sms")
                console.log(res)
                
                dispatch(updatePhoneNumber(res.data.data));
                
            }else{
                showNotification({
                    message:res.data.message,
                    color:"red"
                      })
                      setLooadContact(false)

            }
            console.log(res.data);
   
            setLooadContact(false)

          });
      } catch (error: any) {
        console.log(error);
        setLooadContact(false)

      }
    };

   


  return { data, fetched, loadContact };
};

export default useFetchContactFromDb
;
