import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import walletReducer from "./walletReducer";
import transactionsReducer from "./transactionsReducer";
import fundaccountReducer from "./fundaccountReducer";
import emails from "./emails";
import phoneNumbers from "./phoneNumbers";
import whatsAppNumber from "./whatsAppNumber";

const rootReducer = combineReducers({
  user: userReducer,
  wallet: walletReducer,
  transactions: transactionsReducer,
  fundaccount:fundaccountReducer,
  emails:emails,
  whatsAppNumber: whatsAppNumber,
  phoneNumbers:phoneNumbers
});
 
export default rootReducer;
