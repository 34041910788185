import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Image, Link, Text, Textarea } from '@chakra-ui/react'
import { MdOutlineCancel } from "react-icons/md";
import { Checkbox } from '@mantine/core';
import OptionNav from '../Home/components/OptionNav';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { TbRuler2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById';
import { useParams } from 'react-router-dom';
import copy from "clipboard-copy"
import { useToast } from '@chakra-ui/react'
import trendUp from "../../../assets/images/sent.png"
import upload from "../../../assets/images/upload.png"
import pend from "../../../assets/images/pend.png"
;
import { FiFilePlus } from "react-icons/fi";
import BulkMessageModal from '../Home/components/BulkMessageModal';
import CustomInput from '../../../components/core/input/CustomInput';
import BulkSmsNav from '../Home/components/BulkSmsNav';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import useSaveEmails from '../../../hooks/bulkHooks/useSaveEmails';
// import { extendTheme } from "@chakra-ui/react";
import * as yup from "yup"
// import XLSX from 'xlsx'
import { Formik, FormikValues } from 'formik';


interface iCard {
    icon:any
    title:string
    number:string
    link:string
}

const BulkEmail = () => {
    const {data} = useFetchBulkItems("/BulkEmail/get-email-dashboard")
    console.log("iam here", data.data)

    const items :iCard []=[
        
        {
        icon:trendUp,
        title:"Emails Sent",
        number:data?.data?.sentEmails,
        link:"/dashboard/bulk/bulk-email/sent"
    },
    {
        icon:upload,
        title:"Emails uploaded",
        number:data?.data?.uploadedEmails,
        link:"/dashboard/bulk/bulk-emails/uploaded-emails"
    },
    {
        icon:upload,
        title:"Delivered Emails",
        number:data?.data?.deliveredEmails,
        link:"/dashboard/bulk/bulk-emails/delivered"
    },
    {
        icon:pend,
        title:"Pending Emails",
        number:data?.data?.pendingEmails,
        link:"/dashboard/bulk/bulk-email/pending"
    },

]


    const {id} = useParams()
    const [isModal, setIsModal] = useState(false);
    const [optionNav, setOptionNav] = useState(false);

    const {SubmitEmails, loading} = useSaveEmails()


    const emailsSchema = yup.object().shape({
        emails:yup.string().required("enter emails seperated with commas")
    })
  
    const handleSaveEmails = async(values:any)=>{
        try {
            await emailsSchema.validate(values)
            await SubmitEmails(values).then((res)=>{
                console.log(res)
            })
        } catch (error) {
            console.log(error)
        }
    }
 
    const navigate= useNavigate()
    const sendMessage = ()=>{
        navigate("/dashboard/bulk/bulk-email/send-bulk-email")
    }
 
    const viewPending = ()=>{
        navigate("/dashboard/bulk/bulk-emails/pending")
    }
    const viewSent = ()=>{
        navigate("/dashboard/bulk/bulk-emails/delivered")
    }
    const viewPhoneContacts = ()=>{
        navigate("/dashboard/bulk/bulk-messages/my-phone-contacts")
    }
 
    const hideSubmitBulkModal = () => {
        setIsModal(false);
      }; 
      const openSubmitBulkModal = () => {
        setIsModal(true);
      };

      const closeNav=()=>{ 
        setOptionNav((prevClick)=>!prevClick)
      }
      const [uploadStatus, setUploadStatus] = useState({ status: '', message: '' });
      const [emails, setEmails] = useState([]);
      const [contacts, setContacts] = useState<string[]>([]);
    
      const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
      
        setUploadStatus({ status: 'uploading', message: 'Uploading...' });
      
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target?.result as string;
          const emailsArray = parseEmails(fileContent);
          setContacts(emailsArray);
          setUploadStatus({ status: 'success', message: 'Emails extracted successfully.' });
        };
        reader.onerror = () => {
          setUploadStatus({ status: 'error', message: 'Error uploading file.' });
        };
        reader.readAsText(file); // Read the file as text
      };
      
      const parseEmails = (fileContent: string): string[] => {
        const contacts: string[] = [];
        const lines = fileContent.split('\n');
      
        lines.forEach((line) => {
          const contact = line.split(',').map(cell => cell.trim());
         
          if (validateEmail(contact[0])) {
            contacts.push(contact[0]);
          }
        });
      
        return contacts;
      };
      
      // Simple email validation function
      const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      
  return (
    <Box width="100%" h="100%">
        {
            optionNav && <BulkSmsNav onclick={openSubmitBulkModal} onclose={closeNav}>
           <Box onClick={sendMessage} mb={2} fontWeight={600}>
    Send Email
 </Box>
     <Box mb={2} fontWeight={600} onClick={viewPending}>
        View pending Emails
     </Box>
     <Box onClick={viewSent} mb={2} fontWeight={600}>
        View sent Emails 
     </Box>
     <Box onClick={openSubmitBulkModal} mb={2} fontWeight={600}>
        Upload Emails
     </Box>
   
                </BulkSmsNav>
        }

 
{
    isModal && 

 <BulkMessageModal onclick={ 
   handleSaveEmails 

 }  variant="primary"  buttonText="Upload Email list" hideModal={hideSubmitBulkModal}>
<Box mt={12} w="100%"  h="100%" >
 
    <Box>
        <Text fontWeight={500} mb={2}>Name of email file</Text>
        <CustomInput
    width="auto"
    placeholder="Enter name of file"
    name="file"
  
    required
  />
    </Box>
<Box fontWeight={500}>
    Upload email file
</Box>
<Box w="100%" mt={4} mb={2} bg="#f1f1f1" height="150px" borderRadius="10px">
        <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
          <Text fontSize={12} color="grey">Select file (CSV, Vcard file )</Text>
          <Box mt={2}>
          <input type="file" 
            id="fileUpload"
          accept=".vcf,.csv,.xlsx,.txt" onChange={handleFileUpload} style={{
            display:"none"
          }} />
            <Box>
            <label htmlFor="fileUpload">
              <Box as="span" cursor="pointer">

            <FiFilePlus color="grey" />

              </Box>
            </label>
            </Box>
          
         
          </Box>
          {uploadStatus.status === 'uploading' && (
            <Text fontSize={14} color="blue.500">{uploadStatus.message}</Text>
          )}
          {uploadStatus.status === 'success' && (
            <Text fontSize={14} color="green.500">{uploadStatus.message}</Text>
          )}
          {uploadStatus.status === 'error' && (
            <Text fontSize={14} color="red.500">{uploadStatus.message}</Text>
          )}
        </Flex>
      </Box>


</Box>

    </BulkMessageModal>

 }
       <OtherHeader />
   
       <Box>

       <Flex alignItems="center">
        <PageHeader text="Send bulk Email" link={`/dashboard`} />
        <Box w="30px" h="30px" borderRadius="100%" cursor="pointer">

<Box height="100%">
    <Flex justifyContent="center" alignItems="center">
    <HiOutlineDotsVertical onClick={closeNav}  fontSize={20} />

    </Flex>


</Box>



 

</Box>
        </Flex>
       </Box>
 

 <Box width="100%"  height="100%">
 <Flex flexDirection="column" height="100%">
    

 <Flex w="100%" justifyContent="space-between" flexWrap="wrap" flex="column">
 {
    items.map((props:iCard)=>(
        
        <Box w="45%" h="150px"  >
    <Box mt={3}mb={2} >{props.title}</Box>
    <Link href={props.link}>
    <Box width="100%" bg="#eefaef" height="100px" mb={3}   p={3} borderRadius={3}>
        <Box  width="90%" height="100%" mb={3}>
            {/* <Flex alignItems="center" flex="column" height="100%"> */}
            <Box>
                <Flex justifyContent="flex-end">

              <Image src={props.icon} alt=""/>
                </Flex>
            </Box>
         <Flex justifyContent="center">
         <Box color="#4cb051" fontWeight="bold" fontSize="18px" >
            {props.number}
            </Box>
         </Flex>
            {/* </Flex> */}
        
        </Box> 
    </Box>
    </Link>

 
    </Box>
    ))
}
 </Flex>



    
 

          
  
           
       
 </Flex>
 
 

 <Box mt={14}>

<Box fontWeight={500}>
   Upload Emails
</Box>
<Box mt={12} w="100%" p={6} h="300px" shadow="sm" borderRadius="20px">
<Box fontWeight={500}>
  Email file
</Box>
<Box mt={4} mb={4} bg="#f1f1f1" height="150px" borderRadius="10px">
    <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
    <Text fontSize={12} color="grey">Select email file </Text>
    <Box mt={2}>
    <FiFilePlus color="grey" />


    </Box>


    </Flex>
</Box>

<Button onClick={openSubmitBulkModal}   variant="primary" size="normal">
               Upload File
                  </Button>

</Box>


<Box mt={4} width="100%">
    <Flex justifyContent="space-between" alignItems="center">
    <Box bg="gray" h="2px" width="30%"></Box>
    <Box textAlign="center" width="40%" color="gray" fontWeight={600}>OR</Box>
    <Box bg="gray" width="30%" h="2px"></Box>
    </Flex>


</Box>

<Box mt={12} w="100%" p={6} h="300px" shadow="sm" borderRadius="20px">
<Box fontWeight={400} fontSize={14} w="100%">
Type/copy paste emails seperated with commas
</Box>
<Formik

initialValues={{
    emails:""
}}

onSubmit={((values, actions)=>{
    handleSaveEmails(values)
    actions.setSubmitting(false)
    actions.resetForm()

})}


>
    {(({handleChange, handleSubmit, errors, values})=>(
        <form 
onSubmit={((e:any)=>{
    e.preventDefault()
    handleSubmit(e)
  

})}

        >
        
        
        <Box mt={4} mb={4} bg="#f1f1f1" height="150px" borderRadius="10px" p={3}>
        <Text fontSize={12} color="grey">For example: dickseneh222@gmail.com</Text>
        <Textarea onChange={handleChange} name='emails' value={values.emails} w="100%" height="90%" bg="transparent" outline="none" border={0} />
        
        </Box>
        
        <Button type='submit'   variant="primary" size="normal">
                              {
            
                                loading? "Please Wait ...":"Save emails" 
                              }                          </Button>
        </form>

    ))}

</Formik>


</Box>
</Box>
 </Box>

    </Box>
  )
}

export default BulkEmail