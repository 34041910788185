import { Box, Flex, VStack } from "@chakra-ui/react";
import {
 
    Button,
  OtherHeader,
  PageHeader,
} from "../../../components";

import { Checkbox } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { useState } from "react";




 
const CardForm = () => {
  
 
    const [checkboxValues, setCheckboxValues] = useState<any>({
        name: true,
        whatsappNumber: true,
        age: false,
        phoneNumber: false,
        email: false,
        profession: false,
        state: false,
        occupation: false,
        gender: false,
        business: false,
      });
    const navigation = useNavigate();
  
    const handleCheckboxChange = (event:any) => {
      const { name, checked } = event.target;
      setCheckboxValues((prevValues:any) => ({ ...prevValues, [name]: checked }));
    };
  
    const temporarilyMoveToNext = () => {
      
      localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));
  
      navigation("/dashboard/creator/review-card");
    };

 
  //  console.log(checkboxValues)




  return (
    <Box>
            
      <OtherHeader />

        <PageHeader text="Card Form" link="/dashboard/creator/create-card" />

       
  
    


<Box width="100%"h="100%">
<Flex flexDirection="column">
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
            Name
        </Box>
        <Checkbox
                 checked={checkboxValues.name}
                 onChange={handleCheckboxChange}
                name="name"
                color="green"
                defaultChecked
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
            WhatsApp Number
        </Box>
        <Checkbox
              checked={checkboxValues.whatsappNumber}
              onChange={handleCheckboxChange}
                name="whatsappNumber"
                color="green"
                defaultChecked
              />
    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
            Age
        </Box>
        <Checkbox
                checked={checkboxValues.age || false}
                onChange={handleCheckboxChange}
                name="age"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
           Phone Number
        </Box>
        <Checkbox
                checked={checkboxValues.phoneNumber || false}
                onChange={handleCheckboxChange}
                name="phoneNumber"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
        Email
        </Box>
        <Checkbox
                checked={checkboxValues.email || false}
                onChange={handleCheckboxChange}
                name="email"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
           Profession
        </Box>
        <Checkbox
                checked={checkboxValues.profession || false}
                onChange={handleCheckboxChange}
                name="profession"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
            State
        </Box>
        <Checkbox
                checked={checkboxValues.state || false}
                onChange={handleCheckboxChange}
                name="state"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
          Occupation
        </Box>
        <Checkbox
                checked={checkboxValues.occupation || false}
                onChange={handleCheckboxChange}
                name="occupation"
                color="green"
              />

    </Flex>

</Box>
<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
            Gender
        </Box>
        <Checkbox
                checked={checkboxValues.gender || false}
                onChange={handleCheckboxChange}
                name="gender"
                color="green"
              />

    </Flex>

</Box>

<Box fontWeight="600" mb={4}>
    <Flex justifyContent="space-between" alignItems="center" width="100%" >
        <Box>
         Business
        </Box>
        <Checkbox
                checked={checkboxValues.business || false}
                onChange={handleCheckboxChange}
                name="business"
                color="green"
              />

    </Flex>

</Box>

</Flex>
</Box>
<Box marginBottom="20px" marginTop="30px">
                  <Button  onClick={temporarilyMoveToNext}  variant="primary" size="normal">
                   Next
                  </Button>
                </Box>

    </Box>
  );
};

export default CardForm;
