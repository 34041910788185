import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { OtherHeader, PageHeader, SelectInput } from "../../../components";
import CustomInput from "../../../components/core/input/CustomInput";
import Typography from "../../../components/core/typo/Typography";
import useFetchStates from "../../../hooks/data-hooks/useFetchStates";
import useFetchBusinessTypes from "../../../hooks/data-hooks/useFetchBusinessTypes";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { dataInterface } from "../../../types/components/componentInterfaces";
import { useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import useUpdateProfile from "../../../hooks/user-hooks/useUpdateProfile";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as yup from "yup";
import UploadImage from "../../../components/UploadImage/UploadImage";
import { countryCodes } from "../../../config/data";

const EditProfile = () => {
  const { fetchStates } = useFetchStates();
  const { updateProfile, isLoading } = useUpdateProfile();
  const navigate = useNavigate();
  const { fetchBusinessTypes } = useFetchBusinessTypes();
  const user = useSelector((state: RootState) => state.user);
  const [localStates, setLocalStates] = useState<Array<dataInterface>>([]);
  const [businessTypes, setBusinessTypes] = useState<Array<dataInterface>>([]);

  const genders = [
    { name: "Male", id: "Male" },
    { name: "Female", id: "Female" },
  ];

  const validation = yup.object().shape({
    profileImage: yup.lazy((value) =>
      /^data/.test(value)
        ? yup
            .string()
            .trim()
            .matches(
              /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
              "Must be a valid data URI"
            )
        : yup.string().trim().url("Must be a valid URL")
    ),
    fullName: yup.string(),
    address: yup.string(),
    phoneNumber: yup.string(),
    email: yup.string().email("Enter a valid email"),
    dob: yup
      .string()
      .required("Please add your date of birth")
      .test("DOB", "You must be at least 18 years old", (value) => {
        return dayjs().diff(dayjs(value), "year") >= 18;
      }),
    stateId: yup.number(),
    businessId: yup.number(),
    gender: yup.string(),
    facebookLink: yup.string(),
    instagramLink: yup.string(),
  });

  const handleProfileUpdate = (values: FormikValues) => {
    updateProfile(values).then((res) => {
      if (res.data.status === true) {
        navigate("/dashboard/user-profile");
      }
    });
  };

  useEffect(() => {
    fetchStates().then((res) => {
      setLocalStates(res.data.data);
    });

    fetchBusinessTypes().then((res) => {
      setBusinessTypes(res.data.data);
    });
  }, []);
  return (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard/user-profile" text="Edit Profile" />
      <Formik
        initialValues={user.data}
        validationSchema={validation}
        onSubmit={(values) => {
          handleProfileUpdate(values);
        }}
      >
        {({ handleSubmit, handleChange, isValid, errors, values }) => (
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Flex justifyContent="end" margin="10px 0">
              <Button
                border="1px solid black"
                bgColor="transparent"
                borderRadius="20px"
                height="fit-content"
                padding="5px 20px"
                width="fit-content"
                marginLeft="auto"
                type="submit"
                disabled={isValid ? false : true}
              >
                {isLoading ? "Loading..." : "Save"}
              </Button>
            </Flex>

            <UploadImage value={user.data.profileImage} />
            <Box w="100%">
              <CustomInput
                width="auto"
                placeholder="Enter your full name here"
                label="Your Name"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                error={errors.fullName ? errors.fullName : null}
              />

              <Box w="100%">
                <Box marginBottom={2}>
                  <Typography>Phone number</Typography>
                </Box>
                <InputGroup
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <div>
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "55px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "-5px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                  </div>

                  <CustomInput
                    width="auto"
                    placeholder="Enter your phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={errors.phoneNumber ? errors.phoneNumber : null}
                    disabled
                  />
                </InputGroup>
              </Box>

              <CustomInput
                width="auto"
                placeholder="Enter your address"
                label="Your Address"
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                error={errors.address ? errors.address : null}
              />

              <CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="Date of birth"
                name="dob"
                type="date"
                value={`${dayjs(values.dob).format("YYYY-MM-DD")}`}
                onChange={handleChange}
                error={errors.dob ? errors.dob : null}
              />

              <CustomInput
                width="auto"
                placeholder="Enter your email address"
                label="Email Address"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email ? errors.email : null}
                disabled
              />

              <SelectInput
                values={localStates}
                label="State"
                id="stateId"
                name="stateId"
                placeholder="Select state"
                value={values.stateId}
                onChange={handleChange}
                error={errors.stateId ? errors.stateId : null}
              />

              <SelectInput
                values={businessTypes}
                label="Type of Business"
                id="businessId"
                name="businessId"
                placeholder="Select business"
                value={values.businessId}
                onChange={handleChange}
                error={errors.businessId ? errors.businessId : null}
              />

              <SelectInput
                values={genders}
                label="Gender"
                id="gender"
                name="gender"
                placeholder="Select your gender"
                value={values.gender}
                onChange={handleChange}
                error={errors.gender ? errors.gender : null}
              />

              <CustomInput
                width="auto"
                placeholder="Enter your instagram link"
                label="Instagram Link"
                type="text"
                name="instagramLink"
                value={values.instagramLink}
                onChange={handleChange}
                error={errors.instagramLink ? errors.instagramLink : null}
              />

              <CustomInput
                width="auto"
                placeholder="Enter your Facebook Link"
                label="Facebook Link"
                type="text"
                name="facebookLink"
                value={values.facebookLink}
                onChange={handleChange}
                error={errors.facebookLink ? errors.facebookLink : null}
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditProfile;
