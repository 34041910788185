import React, { useEffect, useState } from 'react'
import { Button, MiddleModal, OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';

import useGetCreatedCard from '../../../hooks/creator-hooks/useGetCreatedCards';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import ListModal from '../Home/components/ListModal';
import { LuRotateCw } from 'react-icons/lu';
import { TiDeleteOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import moment from 'moment';


const DeliveredEmails = () => {
  
    const navigate = useNavigate()
    const resendMessage = ()=>{
        navigate("/dashboard/bulk/bulk-email/sent/resend/:id")
    }
    const {data} = useFetchBulkItems("/BulkEmail/get-delivered-email")
    console.log(data)
    // console.log(created)
   
    const [isModal, setIsModal] = useState(false);
    
    const [toDelete ,setToDelete] = useState(false)
    const statusMessages:any = {
        1: "Sent",
        2: "Uploaded",
        3: "Pending",
        4: "Delivered",
        5: "Approved",
        6: "Rejected",
        7: "Failed",
      };
      const deleteItem = ()=>{
        setToDelete((prevOption)=>!prevOption)
      }
  
    const hideSubmitContactModal = () => {
        setIsModal(false);
      };
      const openSubmitContactModal = () => {
        setIsModal(true);
      };

   


  return (
 <Box w="100%" height="100%">
  <Flex flexDirection="column">
    <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-email" text="Sent Emails" />
<Box w="30px" h="30px" borderRadius="100%" cursor="pointer" onClick={openSubmitContactModal}>

 
</Box>
</Flex> 
    </Box>


 <Box height="100%" w="100%">
    <Flex  height="100%" flexDirection="column" width="100%" >
        <Box w="100%" p="2px">
     
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between" flexWrap="wrap">
      
 {/* <Box width="100%">
<Link href='/dashboard/bulk/bulk-email/sent/resend/:id'>
<Flex justifyContent="space-between" alignItems="center">
        <Box w=" 70px" h="70px" bg="#d6f2d7" textAlign="center" borderRadius="100% " fontWeight={600} fontSize={20} color="green"> 
            <Flex justifyContent="center" alignItems="center" h="100%">
A
            </Flex>
            </Box>
        <Box width="70%">
            <Box w="100%">
                <Flex justifyContent="space-between">
                <Box>Hi  i sell hot milky donuts oo</Box>
<Box ml={4}>

<HiOutlineDotsVertical  fontSize={20} />

</Box>
                </Flex>

            </Box>
            <Box w="100%" mt={4}>
                <Flex justifyContent="space-between">
                <Box fontSize={14} color="grey">20/mar/23</Box>
<Box bg="green" color="white" w="80px" textAlign="center" borderRadius="20px" p="2px">sent</Box>
                </Flex>

            </Box>
        </Box>
    </Flex> 
</Link>
   
 </Box> */}
   {
  data?.data?.map((props:any, index:number)=>{
    const limitText = props.message.substring(0,35)
    const statusMessage = statusMessages[props.status] || "Unknown";
    return (
      <Box width="100%" mb={4}>
<Link href={`/dashboard/bulk/bulk-email/sent/resend/${props.id}`}>
<Flex justifyContent="space-between" alignItems="center">
        <Box w=" 70px" h="70px" bg="#d6f2d7" textAlign="center" borderRadius="100% " fontWeight={600} fontSize={20} color="green"> 
            <Flex justifyContent="center" alignItems="center" h="100%">
{props.name.charAt(0)}
            </Flex> 
            </Box>
        <Box width="70%">
            <Box w="100%">
                <Flex justifyContent="space-between">
                <Box>{limitText}...</Box>
<Box ml={4}>

<HiOutlineDotsVertical
              onClick={(e) => {
                e.preventDefault(); 
                openSubmitContactModal();
              }}
              fontSize={20}
            />

</Box>
                </Flex>

            </Box>
            <Box w="100%" mt={4}>
                <Flex justifyContent="space-between">
                <Box fontSize={14} color="grey">{moment(props.createdDate).format("MM/D/YYYY")}</Box>
                <Box bg={statusMessage === "Pending"? "red" : "green"} color="white" w="80px" textAlign="center" borderRadius="20px" p="2px"> {statusMessage}</Box>
                </Flex>

            </Box>
        </Box>
    </Flex> 
</Link>
{
    isModal && 

 <ListModal hideModal={hideSubmitContactModal} onclose={hideSubmitContactModal}>
     <Box onClick={(()=>{
      navigate(`/dashboard/bulk/bulk-email/sent/resend/${props.id}`)
     })} mb={2} fontWeight={600}>
  <Flex alignItems="center" height="100%">
  <LuRotateCw />
<Box ml={3}>
Resend
</Box>
  </Flex>

 </Box>
     <Box mb={2} fontWeight={600}>
     <Flex alignItems="center" height="100%">
     <TiDeleteOutline />
<Box onClick={deleteItem} ml={3}>Delete
</Box>
  </Flex>
     </Box>

    </ListModal>
 }{
  toDelete && 

   <MiddleModal onclose={deleteItem}>

<Box w={300} h={100}>

<Box mt={2} mb={4}>Are you sure you want to Delete</Box>
<Box>

  <Flex justifyContent="space-evenly">
    <Button onClick={(()=>{
       navigate(`/dashboard/bulk/bulk-email/sent/delete/props.id}`)
    })} variant="primary" size="small">Sure</Button>
    <Button onClick={deleteItem} variant="secondary" size="small">Cancel</Button>
  </Flex>
</Box>

</Box>

   </MiddleModal>  
 }
   
 </Box>
    )
  })
 }
     
       </Flex>
        </Box>
     
        </Box>
       
        
       
    </Flex>
 </Box>
  </Flex>

 </Box>
  )
}

export default DeliveredEmails