import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { verifyIdentityInterface } from "../../types/auth/authInterfaces";
import { showNotification } from "@mantine/notifications";

// import { FormikValues } from "formik";

import { useNavigate } from "react-router";

export default function useVerifyIdentity() {
  const [results, setResults] = useState(null);
  const [loadings, setLoadings] = useState(false);

  const navigate = useNavigate();

  const verifyUser = async (data: verifyIdentityInterface) => {
    setLoadings(true);

    return await axiosBaseInstance
      .post("/Onboarding/VerifyIdentity", data)
      .then((res) => {
        setLoadings(false);

        if (res?.data?.Status === false) {
          showNotification({
            //@ts-ignore
            message: res?.data?.Message || "an error occurred",
            color: "yellow",
          });
          console.log(res)
          return res;
        } else if (res?.data?.data?.isVerified) {
          setResults(res.data);
          localStorage.removeItem("idToken");
          localStorage.removeItem("email");
          showNotification({
            title: "Success",
            message: res.data.message || "email verification complete",
            color: "green",
          });
          navigate("/signin");
        } else {
          showNotification({
            //@ts-ignore
            message: "an error occurred",
            color: "yellow",
          });

          return res;
        }
        return res.data.data;
      })
      .catch((err) => {
        showNotification({
          //@ts-ignore
          message: res?.response?.data?.Message || "an error occurred",
          color: "yellow",
        });
console.log(err)
        return err;
      });
  };

  return { loadings, results, verifyUser };
}
