import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";


interface CardForm {
    name: string;
    title: string;
    whatsappNumber: string;
    address: string;
    description: string;
    contactLimit: number;
    totalFee: number;
    cardFormSetups: CardFormSetup[];
  }
  
  interface CardFormSetup {
    fieldName: string;
  }
const useCreateCard = () => {
  const [loading, setLoading] = useState(false)
  
  const user = useSelector((state: RootState) => state.user);

  const createCard = async (values: CardForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .post("/Creator/Create-Card", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        if(res.data.Status === false){
          showNotification({
         message:res.data.Message,
         color:"red"
          })
        }
        setLoading(false)
        

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { createCard , loading};
};

export default useCreateCard;
