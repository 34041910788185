import { useState } from "react";
import { Box, Image, Flex, Text } from "@chakra-ui/react";
import CustomInput from "../../../components/core/input/CustomInput";
import forget from "../../../assets/images/fo.png";
import Button from "../../../components/core/Button/Button";
import { Formik, FormikValues } from "formik";
import { emailSchema } from "../utils/validationSchema";
import useForgetPassword from "../../../hooks/auth-hooks/useForgetPassword";
import { useNavigate } from "react-router-dom";
import EmailSentModal from "../../../components/Modals/EmailSentModal/EmailSentModal";

const ForgetPassword = () => {
  const [showModal, setShowModal] = useState<Boolean>(false);
  const { forgetPassword, isLoading, isSuccess } = useForgetPassword();
  const navigate = useNavigate();
  const handleForgetPassword = (values: FormikValues) => {
    localStorage.setItem("email", values.email);
    forgetPassword(values);
  };

  return (
    <>
      <Formik
        validationSchema={emailSchema}
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          handleForgetPassword(values);
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="space-around"
              height="100vh"
              sx={{
                "@media screen and (min-width: 450px)": {
                  height: "100%",
                },
              }}
            >
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Image
                  objectFit="contain"
                  w={300}
                  src={forget}
                  sx={{
                    "@media screen and (min-width: 450px)": {
                      width: "250px",
                      marginRight: "auto",
                    },
                  }}
                />
              </Flex>

              <Box>
                <Text fontSize="34px" fontWeight="700" marginBottom="20px">
                  Forget Password
                </Text>

                <CustomInput
                  width="auto"
                  placeholder="Enter your email address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email ? errors.email : null}
                />

                <Text fontSize="12px" fontWeight="400">
                  We would send you a link to your email to reset your password.
                </Text>
              </Box>

              <Box w="100%" marginTop="30px">
                <Button
                  variant="primary"
                  type="submit"
                  size="normal"
                  style={{ marginBottom: "12px" }}
                >
                  {isLoading ? "Loading..." : "Send"}
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Formik>

      {isSuccess && <EmailSentModal />}
    </>
  );
};

export default ForgetPassword;
