import { Box, Flex, Text } from "@chakra-ui/react";
import { vcfHistoryFileInterface } from "../../types/components/componentInterfaces";
import { TfiDownload } from "react-icons/tfi";
import dayjs from "dayjs";

const DownloadHistoryFile = ({
  fileName,
  compiledDate,
  isDownloadAvailable,
  downloadFunc,
  isDownloading,
}: vcfHistoryFileInterface) => {
  return (
    <Flex
      borderBottomWidth="0.3px"
      borderColor="grey.light"
      paddingBottom="10px"
      margin="20px 0"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        borderRadius="100%"
        width="50px"
        height="50px"
        cursor="pointer"
        marginRight="20px"
        backgroundColor="green.150"
        color="green.100"
      >
        <TfiDownload fontSize="15px" />
      </Flex>
      <Box>
        <Text fontSize="15px" fontWeight="500" marginBottom="7px">
          {fileName}
        </Text>
        <Text fontSize="12px" fontWeight="400">
          {dayjs(compiledDate).format("d MMM YYYY")} at{" "}
          {dayjs(compiledDate).format("h:mm A")}
        </Text>
      </Box>
      <Box marginLeft="auto" fontSize="11px" fontWeight="500">
        {isDownloadAvailable ? (
          <>
            <Text color="green.100" cursor="pointer" onClick={downloadFunc}>
              {isDownloading ? "...." : "Download"}
            </Text>
          </>
        ) : (
          <Text color="red">Not Available</Text>
        )}
      </Box>
    </Flex>
  );
};

export default DownloadHistoryFile;
