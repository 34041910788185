import { Box,  Flex,  Text } from "@chakra-ui/react";
import { BottomModal as ModalLayout, Button } from "../../../../components";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Radio } from '@mantine/core'


interface props {
  variant: "vcf" | "tv" | string;
  hideModal: () => void;
  submitContactOnclick: () => void;
}

const HomeModal = ({ variant, hideModal, submitContactOnclick }: props) => {
  const [device , setDevice ] =useState(false)
  const [selectedValue, setSelectedValue] = useState<string>(" ");
const [free, setFree] = useState(false)
  const navigate = useNavigate()

  const handleRadioChange = (value:any) => {
    setSelectedValue(value);
  };

  const handleSelect = () => {
 
    localStorage.setItem('downloadOption', selectedValue);
    navigate("/dashboard/download-free-contact")
    
  };
  const handleSelectPremium = () => {
 
    localStorage.setItem('downloadOption', selectedValue);
    navigate("/dashboard/premium-contact")
    
  };
const openFreeDeviceModal = ()=>{
  setDevice((prev)=>!prev)
}

const openPaidDeviceModal = ()=>{
  setDevice((prev)=>!prev)
  setFree((prev)=>!prev)
}
 
 
  return device? (
   
    <ModalLayout hideModal={hideModal}>
      {
        free? (
<>

<Box mt={10}>
    <Flex justifyContent="space-between" height="100%" alignItems="center">

    <Text fontWeight={600}>Download on iPhone(ios)</Text>
  
  <Radio  
  
  value="1"
  checked={selectedValue === "1"}
  onChange={() => handleRadioChange("1")}
  label=""/>
    </Flex>
  

  </Box>
  <Box mt={3} mb={3}>
    <Flex justifyContent="space-between" height="100%" alignItems="center">

    <Text fontWeight={600}>Download on Android</Text>
  <Radio 
    
    checked={selectedValue === "0"}
    onChange={() => handleRadioChange("0")}
    label=""
  value="0"   />
    </Flex>
  

  </Box>

  <Button onClick={handleSelectPremium}  variant="primary" size="normal">
       Select
      </Button>

</>
        ):(

          <> <Box mt={10}>
          <Flex justifyContent="space-between" height="100%" alignItems="center">
         
      
          <Text fontWeight={600}>Download on iPhone(ios)</Text>
        <Radio  
        
        value="1"
        checked={selectedValue === "1"}
        onChange={() => handleRadioChange("1")}
        label=""/>
          </Flex>
        
      
        </Box>
        <Box mt={3} mb={3}>
          <Flex justifyContent="space-between" height="100%" alignItems="center">
      
          <Text fontWeight={600}>Download on Android</Text>
        <Radio 
          
          checked={selectedValue === "0"}
          onChange={() => handleRadioChange("0")}
          label=""
        value="0"   />
          </Flex>
        
      
        </Box>
      
        <Button onClick={handleSelect}  variant="primary" size="normal">
             Select
            </Button></>
        )
      }
 

  </ModalLayout>
    
 
  ):(
  





<ModalLayout hideModal={hideModal}>
{variant === "vcf" ? (
  <>
    <Button
      variant="primary"
      size="normal"
      onClick={submitContactOnclick}
    >
      Submit Contact
    </Button>

    {/* <Link to=> */}
      <Button onClick={openFreeDeviceModal} variant="secondary" size="normal">
        Download Free Contact
      </Button>
    {/* </Link> */}

    {/* <Link to=> */}
      <Button onClick={openPaidDeviceModal} variant="clear" size="normal">
        Download Premium Contact
      </Button>
    {/* </Link> */}
  </>
) : variant === "tv" ? (
  <>
    <Link to="/dashboard/whatsapp-tvs">
      <Button variant="primary" size="normal">
        Available WhatsApp TV’s for post
      </Button>
    </Link>
    <Link to="/dashboard/my-whatsapp-tv">
      <Button variant="secondary" size="normal">
        My Whatsapp Tv
      </Button>
    </Link>
  </>
) : variant === "bulkmsgs" ? (
  <>
    <Link to="/dashboard/bulk/bulk-message">
      <Button variant="primary" size="normal">
      Send Bulk sms
      </Button>
    </Link>
    <Link to="/dashboard/bulk/bulk-email">
      <Button  variant="clear" size="normal">
      Send bulk Email messages
      </Button>
    </Link>
    <Link to="/dashboard/bulk/bulk-whatsapp-message">
      <Text fontWeight={500} textAlign="center" color="#4cb051">
      Send bulk whatsapp message

      </Text>
    </Link>
  </>):null}
</ModalLayout>
  );
};

export default HomeModal;
