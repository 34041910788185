import React, { ChangeEvent, useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Text } from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom';


import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import useFetchContactFromDb from '../../../hooks/bulkHooks/useFetchContactFromDb';
import CustomInput from '../../../components/core/input/CustomInput';
const FetchContacts = () => {

    const navigate = useNavigate()

   
  const {data} = useFetchBulkItems("MyProfile/BusinessTypes")

  const [isItemSelected, setIsItemSelected] = useState<boolean>(false);
  const [isAmountValid, setIsAmountValid] = useState<boolean>(false);

  const {fetched, loadContact} = useFetchContactFromDb()
  const [selectedBusinessType, setSelectedBusinessType] = useState("");
const [quantity, setQuantity] = useState<any>()

  const handleFetchData = async() => {
    
   await fetched({ businessType:selectedBusinessType, Quantity: quantity }).then((res)=>{
    console.log(res)
   });
  };
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedBusinessType(event.target.value);
    setIsItemSelected(event.target.value !== "");
  };

  const handleQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const inputQuantity = Number(event.target.value);
    setQuantity(inputQuantity);
    setIsAmountValid(true);
  };


  return (
  <>
   <OtherHeader />
  
   <Box>

<Flex alignItems="center" h="100%">
 <PageHeader text="Send Bulk Sms" link={`/dashboard/bulk/bulk-message`} />

 </Flex>
</Box>

<Box mt={3} fontWeight={600} overflow="scroll" css={{
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: "transparent",
      borderRadius: 0,
    },
  }}>
Fetch contact from our database
</Box>

<Box w="100%" height="80vh">
    <Flex flexDirection="column" justifyContent="space-between" height="100%">

<Box>
<Box w="100%" mt={6}>
 
  
 <Text mt={4} fontWeight={600}>Type of business phone number</Text>
 
 <Box bg="#f1f1f1" w="100%" h="60px" mt={3} borderRadius="8px" mb={2}>
   <Flex w="100%" justifyContent="center" alignItems="center" height="100%">
   <select  placeholder='select option' style={{
     width:"90%",
 background:"transparent",
 border:"none",
 outline:"none"
   }}
   value={selectedBusinessType}
   onChange={handleChange}
 
   >
  {
 data?.data?.map((props:any)=>{
  return (
    <option key={props.id} value={props.id}>{props.name}</option>

  )
 })
  }
 </select>
   </Flex>
 
 </Box>
 
 
 
 
     
 
  
 
 
 </Box>
 
 
 
 
 
 <Box w="100%"  mt={6}>
    
     
 <Text mt={4} fontWeight={600}>Amount of quantity</Text>
 
 <Box bg="#f1f1f1" w="100%" h="60px" mt={3} borderRadius="8px" mb={2}>
   <Flex justifyContent="center" alignItems="center" height="100%">
   <Box w="100%">
<CustomInput value={quantity} placeholder="Enrer anount of quantity" onChange ={handleQuantity}/>
</Box>
   </Flex>
 
 </Box>
 
 
 
 
 
 
  
 
 
 </Box>
</Box>
{isAmountValid && isItemSelected ? (
    <Button onClick={handleFetchData} variant="primary" size="normal">
              {loadContact ? "Fetching, please wait..." : "Fetch Contacts"}
            </Button>
          
          ) : (
          <Box>
              <Button disabled variant="primary" size="normal">
                {loadContact ? "Fetching, please wait..." : "Fetch Contacts"}
              </Button>
              <Text textAlign="center" color="red" fontSize={12}>Enter a quantity and select a business category</Text>
            </Box>
          )}
    </Flex>
</Box>











  </>
  )
}

export default FetchContacts