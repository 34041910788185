import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Image, Link, Text, Textarea } from '@chakra-ui/react'
import { MdOutlineCancel } from "react-icons/md";
import { Checkbox } from '@mantine/core';
import OptionNav from '../Home/components/OptionNav';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { TbRuler2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById';
import { useParams } from 'react-router-dom';
import copy from "clipboard-copy"
import { useToast } from '@chakra-ui/react'
import trendUp from "../../../assets/images/sent.png"
import upload from "../../../assets/images/upload.png"
import pend from "../../../assets/images/pend.png"
;
import { FiFilePlus } from "react-icons/fi";
import BulkMessageModal from '../Home/components/BulkMessageModal';
import CustomInput from '../../../components/core/input/CustomInput';
import BulkSmsNav from '../Home/components/BulkSmsNav';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import useSaveContacts from '../../../hooks/bulkHooks/useSaveContacts';
// import { extendTheme } from "@chakra-ui/react";
import * as yup from "yup"
import { Formik, FormikValues } from 'formik';

interface iCard {
    icon:any
    title:string
    number:string
    link:string
}

const BulkMessage = () => {
    const [contacts, setContacts] = useState<string[]>([]);
    console.log("contacts", contacts)
    const [uploadStatus, setUploadStatus] = useState<{ status: 'idle' | 'uploading' | 'success' | 'error'; message: string }>({
        status: 'idle',
        message: '',
     });

    const {data} = useFetchBulkItems("/BulkSms/get-sms-dashboard")
    console.log(data)
    const items :iCard []=[
        
        {
        icon:trendUp,
        title:"Messages Sent",
        number:data?.data?.sentSms,
        link:"/dashboard/bulk/bulk-message/sent"
    },
    {
        icon:upload,
        title:"Numbers",
        number:data?.data?.uploadedNumbers,
        link:"/dashboard/bulk/bulk-messages/uploaded-numbers"
    },
    {
        icon:upload,
        title:"Delivered Messages",
        number:data?.data?.deliveredSms,
        link:"/dashboard/bulk/bulk-message/delivered"
    },
    {
        icon:pend,
        title:"Pending Messages",
        number:data?.data?.pendingSms,
        link:"/dashboard/bulk/bulk-message/pending"
    },

]

const phoneNumbers:any = contacts.toString()
const phoneNumbersString = contacts.join(',');
console.log(phoneNumbersString)
console.log("sorted", phoneNumbers)
const {SubmitContacts, loading} = useSaveContacts()
const contactSchema = yup.object().shape({
    phoneNumbers:yup.string().required("enter phone numbers seperated with commas")
})
const handleSaveContacts = async(values:any)=>{
    try {
        await contactSchema.validate(values)
        await SubmitContacts(values).then((res)=>{
            console.log(res)
        })
    } catch (error) {
        console.log(error)
    }
}
const handleUploadContacts = async(values:any)=>{
    try {
       
        await SubmitContacts({phoneNumbers:phoneNumbersString}).then((res)=>{
            console.log(res)
            setContacts([])
        })
    } catch (error) {
        console.log(error)
    }
}


    const {id} = useParams()
    const [isModal, setIsModal] = useState(false);
    const [optionNav, setOptionNav] = useState(false);
 
    const navigate= useNavigate()
    const sendMessage = ()=>{
        navigate("/dashboard/bulk/bulk-messages/send-bulk-sms")
    }
 
    const viewPending = ()=>{
        navigate("/dashboard/bulk/bulk-message/pending")
    }
    const viewSent = ()=>{
        navigate("/dashboard/bulk/bulk-message/delivered")
    }
    const viewPhoneContacts = ()=>{
        navigate("/dashboard/bulk/bulk-messages/my-phone-contacts")
    }
 
    const hideSubmitBulkModal = () => {
        setIsModal(false);
      }; 
      const openSubmitBulkModal = () => {
        setIsModal(true);
      };

      const closeNav=()=>{ 
        setOptionNav((prevClick)=>!prevClick)
      }
      const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
    
        setUploadStatus({ status: 'uploading', message: 'Uploading...' });
    
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target?.result as string;
          const contactsArray = parseVCF(fileContent);
          setContacts(contactsArray);
          setUploadStatus({ status: 'success', message: 'selection successful! click on button below to upload' });
        };
        reader.onerror = () => {
          setUploadStatus({ status: 'error', message: 'Error uploading file.' });
        };
        reader.readAsText(file);
     };
      const parseVCF = (fileContent: string): string[] => {
        const contacts: string[] = [];
        const lines = fileContent.split('\n');
    
        lines.forEach((line) => {
          if (line.startsWith('TEL;')) {
            const contact = line.split(':')[1];
            if (contact) {
              contacts.push(contact.trim());
            }
          }
        });
    
        return contacts;
     };
 
  return (
    <Box width="100%" h="100%">
        {
            optionNav && <BulkSmsNav onclick={openSubmitBulkModal} onclose={closeNav}>
            <Box onClick={sendMessage} mb={2} fontWeight={600}>
     Send messages
  </Box>
      <Box mb={2} fontWeight={600} onClick={viewPending}>
         View pending messages
      </Box>
      <Box onClick={viewSent} mb={2} fontWeight={600}>
         View sent messages 
      </Box>
      <Box onClick={openSubmitBulkModal} mb={2} fontWeight={600}>
         Upload phone numbers
      </Box>
      {/* <Box mb={2} fontWeight={600}  onClick={viewPhoneContacts} >My contact</Box> */}
                 </BulkSmsNav>
        }

 
{
    isModal && 

 <BulkMessageModal onclick={handleUploadContacts} variant="primary" link="/dashboard/bulk/bulk-message" buttonText="Upload contact list" hideModal={hideSubmitBulkModal}>
<Box mt={12} w="100%"  h="100%" >
    <Box textAlign="center" mb={4} fontWeight={600}fontSize={18}>
        Uploaded Contact List
    </Box>
  
<Box fontWeight={500}ml={2} mt={3}>
      Upload contact file
      <Box w="100%" mt={4} mb={2} bg="#f1f1f1" height="150px" borderRadius="10px">
        <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
          <Text fontSize={12} color="grey">Select contact file (CSV, Vcard file )</Text>
          <Box mt={2}>
            <input
              type="file"
              accept=".vcf,.csv"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              id="fileUpload"
            />
            <Box>
            <label htmlFor="fileUpload">
              <Box as="span" cursor="pointer">

            <FiFilePlus color="grey" />

              </Box>
            </label>
            </Box>
          
         
          </Box>
          {uploadStatus.status === 'uploading' && (
            <Text fontSize={14} color="blue.500">{uploadStatus.message}</Text>
          )}
          {uploadStatus.status === 'success' && (
            <Text fontSize={14} color="green.500">{uploadStatus.message}</Text>
          )}
          {uploadStatus.status === 'error' && (
            <Text fontSize={14} color="red.500">{uploadStatus.message}</Text>
          )}
        </Flex>
      </Box>
    </Box>


</Box>

    </BulkMessageModal>

 }
       <OtherHeader />
   
       <Box>

       <Flex alignItems="center">
        <PageHeader text="Send bulk sms" link={`/dashboard`} />
        <Box w="30px" h="30px" borderRadius="100%" cursor="pointer">

<Box height="100%">
    <Flex justifyContent="center" alignItems="center">
    <HiOutlineDotsVertical onClick={closeNav}  fontSize={20} />

    </Flex>


</Box>



 

</Box>
        </Flex>
       </Box>
 

 <Box width="100%"  height="100%">
 <Flex flexDirection="column" height="100%">
    

 <Flex w="100%" justifyContent="space-between" flexWrap="wrap" flex="column">
 {
    items.map((props:iCard)=>(
        
        <Box w="45%" h="150px"  >
    <Box mt={3}mb={2} >{props.title}</Box>
    <Link href={props.link}>
    <Box width="100%" bg="#eefaef" height="100px" mb={3}   p={3} borderRadius={3}>
        <Box  width="90%" height="100%" mb={3}>
            {/* <Flex alignItems="center" flex="column" height="100%"> */}
            <Box>
                <Flex justifyContent="flex-end">

              <Image src={props.icon} alt=""/>
                </Flex>
            </Box>
         <Flex justifyContent="center">
         <Box color="#4cb051" fontWeight="bold" fontSize="18px" >
            {props.number}
            </Box>
         </Flex>
            {/* </Flex> */}
        
        </Box> 
    </Box>
    </Link>

 
    </Box>
    ))
}
 </Flex>



    
 

          
  
           
       
 </Flex>
 
 

 <Box mt={14}>

<Box fontWeight={500}>
   Upload contacts
</Box>
<Box mt={12} w="100%" p={6} h="300px" shadow="sm" borderRadius="20px">
<Box fontWeight={500}>
    VCF contact file
</Box>
<Box mt={4} mb={4} bg="#f1f1f1" height="150px" borderRadius="10px">
    <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
    <Text fontSize={12} color="grey">Select contact file (CSV, Vcard file )</Text>
    <Box mt={2}>
    <FiFilePlus color="grey" />


    </Box>


    </Flex>
</Box>

<Button onClick={openSubmitBulkModal}   variant="primary" size="normal">
               Upload File
                  </Button>

</Box>


<Box mt={4} width="100%">
    <Flex justifyContent="space-between" alignItems="center">
    <Box bg="gray" h="2px" width="30%"></Box>
    <Box textAlign="center" width="40%" color="gray" fontWeight={600}>OR</Box>
    <Box bg="gray" width="30%" h="2px"></Box>
    </Flex>


</Box>

<Box mt={12} w="100%" p={6} h="300px" shadow="sm" borderRadius="20px">
<Box fontWeight={400} fontSize={14} w="100%">
Type/copy paste number seperated with commas
</Box>
<Formik

initialValues={{
    phoneNumbers:""
}}

onSubmit={((values, actions)=>{
    handleSaveContacts(values)
    actions.setSubmitting(false)
    actions.resetForm()

})}


>
    {(({handleChange, handleSubmit, errors, values})=>(
        <form 
onSubmit={((e:any)=>{
    e.preventDefault()
    handleSubmit(e)
  

})}

        >
        
        
        <Box mt={4} mb={4} bg="#f1f1f1" height="150px" borderRadius="10px" p={3}>
        <Text fontSize={12} color="grey">For example: 09129298434, 08065456788</Text>
        <Textarea onChange={handleChange} name='phoneNumbers' value={values.phoneNumbers} w="100%" height="90%" bg="transparent" outline="none" border={0} />
        
        </Box>
        
        <Button type='submit'   variant="primary" size="normal">
                              {
            
                                loading? "Please Wait ...":"Save Contacts" 
                              }                          </Button>
        </form>

    ))}

</Formik>


</Box>
</Box>
 </Box>

    </Box>
  )
}

export default BulkMessage