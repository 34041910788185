import { Box, Flex, Text } from "@chakra-ui/react";
import { transactionInterface } from "../../types/components/componentInterfaces"
import { AiOutlinePlus } from "react-icons/ai";
import receive from "../../assets/svgs/receive.svg";
import withdraw from "../../assets/svgs/withdraw.svg";
import { Link } from "react-router-dom";
import moment from "moment";
const TransactionCard = ({
  amount,
  id,
  transactionType,
  createdDate,
  narration,

}: transactionInterface) => {
  return (
    <Link to={`/dashboard/wallet/transaction/${id}`}>
      <Flex
        borderBottomWidth="0.3px"
        borderColor="grey.light"
        paddingBottom="10px"
        margin="20px 0"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="100%"
          width="50px"
          height="50px"
          cursor="pointer"
          marginRight="10px"
          className={`transaction--${transactionType}`}
        >
          {transactionType === "deposit" ? (
            <AiOutlinePlus fontSize="25px" />
          ) : transactionType === "receive" ? (
            <img src={receive} alt="" />
          ) : transactionType === "withdraw" ? (
            <img src={withdraw} alt="" />
          ) : null}
        </Flex>
        <Box>
          <Text fontSize="15px" fontWeight="500" marginBottom="7px">
            {narration}
          </Text>
          <Text fontSize="12px" fontWeight="400">
         { moment(createdDate).format("MMM Do YYYY")} 
          </Text>
        </Box>
        <Text marginLeft="auto" fontSize="15px" fontWeight="500">
          {transactionType === "deposit" ? (
            <>{`+N${amount.toLocaleString()}.00`}</>
          ) : (
            <>{`-N${amount.toLocaleString()}.00`}</>
          )}
        </Text>
      </Flex>
    </Link>
  );
};

export default TransactionCard;
