import { Box, Flex, Image, InputGroup, Text, Textarea } from "@chakra-ui/react";
import {
    ComingSoonModal,
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import dayjs from "dayjs"
import { Button, BottomModal as ModalLayout } from "../../../components";
import { useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import idea from "../../../assets/images/idea.png"
// import link from "../../../assets/images/limk.png"

// import ranking from "../../../assets/images/ranking.png"
// import message from "../../../assets/images/message.png"
import CustomInput from "../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { countryCodes } from "../../../config/data";
import Wassaplinksuccess from "../../../components/Modals/ComingSoonModal/wassaplinksuccess";
import useCreateBrandWhatsappLink from "../../../hooks/whatsapplink-hooks/useCreatePaidBrandlink";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
// import useSubmitContact from "../../../../../hooks/vcf-hooks/useSubmitContact";
// import { Formik } from "formik";
import copy from "clipboard-copy";
import { showNotification } from "@mantine/notifications";
 
const GenerateBrandLink = () => {
    const [isComingSoon, setIsComingSoon] = useState(false);
    const [isValidatingSchema, setIsValidatingSchema] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [isSuccess, setIsSuccess] = useState(false);
    // const [isShareModal, setIsShareModal] = useState(false);
    // const { submitContact } = useSubmitContact();
    const [userLink, setUserLink] = useState<any>()

//   const controlLogout = () => {
//     setIsConfirmLogout(!isConfirmLogout);
//   };

const wallet = useSelector((state: RootState) => state.wallet);
// console.log(wallet)
const Balance = wallet.balance




const {createBrandWHatsappLink} = useCreateBrandWhatsappLink()
const handleCreateWhatsappLink = (values: FormikValues,{resetForm}:any) => {
  setIsLoading(true)
  createBrandWHatsappLink(values).then((res) => {
    setUserLink(res.data.data)
    // console.log("this is it", userLink)
    console.log(res.data)
    if(res.data.Status === false){
      showNotification({
        message: "Failed to createdbrand link, please try again!",
        color: "red",
      });
    }else{
      setIsComingSoon(true)

    }
    resetForm({
      values: {
        countryCode: "+234",
        phoneNumber: "",
        message: "",
      },
  })
    setIsLoading(false)
  
  }).catch((err:any)=>{
    console.log("na here the error dey", err)
    setIsLoading(false)
  });
};

const contactSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select a country code"),
    phoneNumber: Yup.string()
      .min(10)
      .max(11, "cannot add more than 11 digits")
      .required("Please enter a 11-digit number"),
    message: Yup.string().required("Please provide your name"),
    brandLinkCost: Yup.number(),
    
    brandName: Yup.string().required("Please provide your brand name"),
    monthSartDate: Yup
    .string()
    .required("Please add start date for this subscription")
    .test("monthStartDate", "You cannot select from past dates", (value, testContext) => {
      const startDate = dayjs(testContext.parent.monthStartDate).startOf('day');
      const endDate = dayjs(value).startOf('day');
      
      return endDate.isSame(startDate) || endDate.isAfter(startDate);
    }),
    monthEndDate: Yup
    .string()
    .required("Please select end date for the subscription, you should select future dates")
    .test("monthEndDate", "Invalid end month date, pick a future date", (value, testContext) => {
      const startDate = dayjs(testContext.parent.monthStartDate).startOf('day'); 

      const endDate = dayjs(value).startOf('day');
      
      return  endDate.isAfter(startDate);
    })
    })


    // const startDate = dayjs(monthStartDate);
    // const endDate = dayjs(monthEndDate);
    // const duration = endDate.diff(startDate, 'day');
    
    // console.log(`Number of days selected: ${duration}`);
    const controlCopyLink = () => {
      copy(userLink?.shortLink)
      showNotification({
       message: "Link copied",
       color: "green",
     });
     };
  
     const closeModal = (()=>{
      setIsComingSoon(!isComingSoon)
    })
  return (
    <Box>
              {isComingSoon && <Wassaplinksuccess onclose={closeModal} link={userLink?.shortLink}  onclick={controlCopyLink} />}
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Whatsapp link for your brand" link="/dashboard/whatsapp-link/create-brand-link" />

       
      </Flex>
      <Box>
    <Box>
<Image src={idea} alt="idea"/>
    </Box>
</Box>

<Box padding={6} bg="#F1FFF3" fontSize={12} color="#4CB051" mt={4}>
To open a WhatsApp chat, typically, you'd require the user's phone number and go through a saving and searching process. However, WhatsApp links, like "click to chat," simplify this with a single click. wassapview offers free links with a wassapview domain and QR code, with no registration needed.  
</Box>



<Box fontSize={12} mt={6} fontWeight={600}>
Find whatsapp link for your brand
</Box>


<Box w="100%" >
<Formik 

            initialValues={{ phoneNumber: "", countryCode: "+234", brandName:"", message:"", monthSartDate:"", monthEndDate:"", brandLinkCost:0 }}
            validationSchema={contactSchema}
            onSubmit={(values, { resetForm }) => {
              const startDate = dayjs(values.monthSartDate);
              const endDate = dayjs(values.monthEndDate);
              const durationInDays = endDate.diff(startDate, 'day');
              const amount = durationInDays * 50;
              
              values.brandLinkCost = amount
              handleCreateWhatsappLink(values, { resetForm });
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form
              style={{
                width:"100%",
           
              }}
                onSubmit={(e) => {
                  e.preventDefault();

                  if (Object.keys(errors).length > 0) {
                    setIsValidatingSchema(true);
                  } else {
                    setIsValidatingSchema(false);
                    handleSubmit();
                  }
                }}
              >
                {/* <InputGroup display="flex"   width="100%"> */}
                <CustomInput
                    width="auto"
                    placeholder="Your brand name"
                    name="brandName"
                    value={values.brandName}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.brandName
                        ? errors.brandName
                        : null
                    }
                  />
                   {/* <CustomInput
                    width="auto"
                    placeholder="Your brand name"
                    name="brandLinkCost"
                    value={values.brandLinkCost}
                    onChange={handleChange}
                   
                  /> */}
                  <Box mt={4} fontSize={12}>
Enter your whatsapp phone number
</Box>
                  <div  style={{
                    display:"flex",
                  
                  }}>
                 
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "55px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "5px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                     <CustomInput
                    width="auto"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.phoneNumber
                        ? errors.phoneNumber
                        : null
                    }
                  />
                  </div>
                 
                  <div>

                
                  <div>
               
                  </div>
                  </div>

             
                {/* </InputGroup> */}
<Box fontSize={12} mb={2}>
    A custom message
</Box>
                <Textarea
                    width="100%"
                    padding={3}
                    height={40}
                    background="#F1F1F1"
                    borderRadius={5}
                    focusBorderColor="#F1F1F1"
                    border="none"
                    outline={0}
                    placeholder="Enter your custom messages that users will send you"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    // error={
                    //   isValidatingSchema && errors.message
                    //     ? errors.message
                    //     : null
                    // }
                  />
 <Box mt={4} fontSize={12}>
Select month
</Box>
<CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="Start Date"
                name="monthSartDate"
                type="date"
                value={`${dayjs(values.monthSartDate).format("YYYY-MM-DD")}`}
                onChange={handleChange}
                error={errors.monthSartDate ? errors.monthSartDate : null}
              />
              <CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="End Date"
                name="monthEndDate"
                type="date"
                value={`${dayjs(values.monthEndDate).format("YYYY-MM-DD")}`}
                onChange={handleChange}
                error={errors.monthEndDate ? errors.monthEndDate : null}
              />
<Box marginBottom="20px" marginTop="30px">
  <p>Amount: {values.monthSartDate && values.monthEndDate ? dayjs(values.monthEndDate).diff(values.monthSartDate, 'day') * 50 : 0}</p>
  {dayjs(values.monthEndDate).diff(values.monthSartDate, 'day') * 50 > Balance ? (
    <div>
      <Text color="red">Insufficient balance</Text>
      <Button type="submit" variant="primary" size="normal" disabled>
        Generate your brand link
      </Button>
    </div>
  ) : (
    <Button type="submit" variant="primary" size="normal">
      {isLoading ? "Please wait..." : "Generate your brand link"}
    </Button>
  )}
</Box>
              </form>
            )}
          </Formik>
      
</Box>

    </Box>
  );
};

export default GenerateBrandLink;
