import React, { useEffect, useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Text, Textarea } from '@chakra-ui/react'
import BulkMessageModal from '../Home/components/BulkMessageModal';
import { Checkbox } from '@mantine/core';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import CustomInput from '../../../components/core/input/CustomInput';
import useSendBulkEmail from '../../../hooks/bulkHooks/useSendBulkEmails';

const UploadedEmails = () => {

  const [email, setEmail] = useState<string[]>([]);
  
  const [send, setSend] = useState(false)
 const price =Math.ceil( 2.01*email?.length)


    console.log(email)
    console.log(email.toString())
    const initialFormValues = {
      email: email,
      message: "",
      subject: "",
      amount: price,
    }; 
    
 console.log(price)

    const [formValues, setFormValues] = useState<any>({
      email:email,
  message:"",
  subject:"",
  amount:price
  
    });
    

  
 

    const handleChange = (event:any) => {
      const { name, value } = event.target;
      setFormValues((prevValues:any) => ({
        ...prevValues,
        [name]: value
      }));
    };
  
    const convertEmail = email.toString()
    const {sendBulkEmail, loading} = useSendBulkEmail()
 
 
      const  {data} = useFetchBulkItems("/BulkEmail/get-uploaded-emails")
 
  
      console.log(data)
      const [selectAll, setSelectAll] = useState(false);
      const handleSelect = (email: string) => {
        setEmail(prev => {
           if (prev.includes(email)) {
             return prev.filter(num => num !== email);
           } else {
             return [...prev, email];
           }
        });
        
       };
       console.log(formValues)
       const handleSendSms = async()=>{
        try {
    
      await sendBulkEmail({email:email, message:formValues.message, subject:formValues.subject, amount:price}).then((res)=>{
      setFormValues(initialFormValues)
      })
          
        } catch (error) {
          console.log(error)
        }
      }
     
       const handleSelectAll = () => {
        if (selectAll) {
           setEmail([]);
           setSelectAll(false);
        } else {
           const allEmails = data?.data?.map((item:any) => item.email); 
           setEmail(allEmails || []);
           setSelectAll(true);
        }
      
       };
        
  const handleRecipient =()=>{
    setSend((prevSend)=>!prevSend)
    
      }
       
      useEffect(() => {
        setSelectAll(false);
     }, [data]);
  return send?  (

    <>
<OtherHeader />
<Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-email" text="Send Message" />

</Flex>
</Box>
<Box mt={3}>
<Box fontWeight="bold">
  Send your SMS message
</Box>
<Box mt={4} fontWeight={600}>
  Contacts
</Box>
<Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2" p={2} color="gray" fontSize={12}>
{/* {
phoneNumber?.map((props: phoneNumberInterface, index: number) => (

))
} */


<React.Fragment>
  {convertEmail}
</React.Fragment>
}
<Box bg="#00000070" textAlign="center" p="3px" w="80px" borderRadius={10} color="#fff">{email?.length} {" "} {"contacts"}</Box>
</Box>

<Box  mt={4} fontWeight={600}>Title</Box>
<CustomInput onChange={handleChange}
name='subject'
value={formValues.subject}
/>
<Box mt={4} fontWeight={600}>
Text Message
</Box>
<Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea name='message' value={formValues.message} onChange={handleChange} h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
{formValues.message?.length}
</Box>
</Box>
<Box mt={36}>
  <Flex>
      <Box>Total Fee :</Box>
      <Box fontWeight="bold">₦{price}{".00"}</Box>
  </Flex>
</Box>
<Box>
<Button onClick={handleSendSms} variant="primary" size="normal">
{
loading?"Sending please wait...":"Send Message"
}
</Button>
</Box>

</Box>
</>
  ):(

    <>
    <OtherHeader />
   

      <Box>

      <Flex alignItems="center" h="100%">
       <PageHeader text="Uploaded Emails" link={`/dashboard/bulk/bulk-email`} />
       <Box w="50px" h="30px" borderRadius="100%" cursor="pointer">

<Box onClick={() => handleSelectAll()} height="100%" w="70px"  fontSize={12} color="green">
   <Flex justifyContent="center" alignItems="center" h="100%">
 
   Select All


   </Flex>


</Box>



</Box>
       </Flex>
      </Box>
     {
       data?.data?.map((props:any)=>(
         <Box mt={3} w="100%"  >
         <Flex justifyContent="space-between" alignItems="center">
           <Box>
            <Flex alignItems="center" height="100%" >
        
             <Box ml={3}>
             <Box fontSize={14}>{props.email}</Box>
             </Box>
            </Flex>
           </Box>
           <Box>
           <Checkbox  checked={email.includes(props.email)} onChange={() => handleSelect(props.email)} />
           </Box>
         </Flex>
 
         <Box w="100%" bg="#f1f1f1" h="1px" mt={3}>
 
         </Box>
        </Box>
       ))
     }

<Box position="fixed" width="90%" bottom={0}>
 
 <Button variant='primary' size='normal' onClick={handleRecipient}>
         Add Recipients
       </Button>
 </Box>
   </>
  )
}

export default UploadedEmails