import React, { useEffect, useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { Progress } from '@mantine/core'
import { PiWarningCircle } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import successful from "../../../assets/images/success.jpg"
import usePromoteCard from '../../../hooks/creator-hooks/usePromoteCard'
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
// import { useParams } from 'react-router-dom'


const ReviewPromotion = () => {
    const [success, setSuccess] = useState(true)
    const navigation  = useNavigate()
    const [datas, setDatas]= useState<any>()
    const {promoteCard, loading} = usePromoteCard()
    const user:any = useSelector((state: RootState) => state.user.data);
    // console.log(user.walletData.balance)

const {id} = useParams()
const {data} = useGetCardById(id)
    const handleSubmit = async()=>{
      const values ={
        id:parseInt(datas?.id),
        totalSpend:datas.totalSpend,
        duration:datas.duration,
        budget:datas.budget
      }
      try {
        const response = await promoteCard(values);
        // console.log("API response:", response);
        setSuccess(true)
       
        localStorage.removeItem("promotionCard")
    
      } catch (error) {
        console.log("API error:", error);
       
      }
      
    }

useEffect(()=>{
  const promotionData:any = localStorage.getItem("promoteCard")
  // console.log(JSON.parse(promotionData))
  setDatas(JSON.parse(promotionData))
}, [])

  return success? (
    <>
    
    
    <OtherHeader />
     
    

    
        <PageHeader text="Promote card" link="/dashboard/creator/single-card-detail/:id" />
        <Box>
            <Flex flexDirection="column">
            <Box width="100%" fontWeight="600" fontSize="12px" >
                 <Flex justifyContent="space-between ">
                     <Box>{data?.name}</Box>
                     <Box>{data?.title}</Box>
                 </Flex>
                 <Box mt={2}>
                 <hr/>
 
                 </Box>
             </Box>

            </Flex>
            <Box width="100%" fontSize="12px"textAlign="left" >
                 <Flex justifyContent="center">
                 <Box width="80%" marginTop={12} textAlign="center">
               {data?.description}
                 </Box>
               
                 </Flex>
          
             </Box>
             <Box mb={4} mt={12}  width="100%" fontWeight="600" fontSize="12px" >
             <Box>Duration  &  Budget</Box>
             <hr/>

 
                    
             </Box>
          
             <Box mb={4} mt={8}  width="100%" fontSize="12px" >
                <Flex justifyContent="space-between">
                <Box>Duration</Box>
           <Box>{datas?.duration} </Box>
                </Flex>
       

 
                    
             </Box>
           
             <Box mt={8}>
                
                <Box mt={4} mb={2} fontSize={12} fontWeight="600">
                   Cost summary
                </Box>
                <hr/>
             </Box>
             <Box fontSize={12}>
                <Flex justifyContent="space-between" mt={4}>
                <Box>Total Spend</Box>
                <Box>N{datas?.totalSpend}</Box>
                </Flex>
             </Box>
             <Flex mt={8} fontSize={12}>
            <Box mt="3px">
            <PiWarningCircle color="#0accf7" />
            </Box>
      <Box ml={2}>Promotions are reviewed within 24 hours, once <br/> reviewed and approved would start to <br/> run automatically</Box>
        </Flex>
        {

datas?.totalSpend >  user.walletData.balance?        <Box mt={8} >
  <Text color="red">Insufficient Funds</Text>
<Button    variant="primary" size="normal" >

Promote
               </Button>

</Box>:        <Box mt={8} >
<Button    variant="primary" size="normal" onClick={handleSubmit}>
 {
  loading?"Please wait ..." :"Promote"
 }
                  </Button>

</Box>
        }



        </Box>
    </>
  ):(
    <Box>
            
    <OtherHeader />

    

<Box textAlign="center" mt={16}>
  <Box width="100%"  height="200px" textAlign="center" bg="">
      <Image width="100%" height="100%" objectFit="contain" src={successful} alt="successful"/></Box>
  <Box fontWeight="bold" mb={2}>Successfully promoted your card</Box>
  <Box>
      
     <span>Your card has been successfully promoted, it will be reviewed in the next 24 hours
  </span>


  </Box>
</Box>

<Box marginBottom="20px" marginTop="50px">
                <Button  onClick={handleSubmit}  variant="primary" size="normal">
      Done
                </Button>
              </Box>

  </Box>
  )
}

export default ReviewPromotion