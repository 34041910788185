import React, { useState } from "react";
import { Box, Flex, Table, Text } from "@chakra-ui/react";
import { Button, BottomModal as Modal } from "../../components";
import { modalComponentInterface } from "../../types/components/componentInterfaces";
import "./styles.module.scss";

import useFreeFiles from "../../hooks/vcf-hooks/useFreeFiles";
import moment from "moment";
import useDownload from "../../hooks/vcf-hooks/useDownload";
import { useNavigate } from "react-router-dom";
const DownloadContactTemp = ({ onclick }: modalComponentInterface) => {
  const { data } = useFreeFiles();
  const navigate = useNavigate();
  const { download, result } = useDownload();
  const [loadingMap, setLoadingMap] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState(false);

  const [results, setResults] = useState<any>([]);

  const downloadOption = localStorage.getItem("downloadOption")
  // console.log(downloadOption)

  const handleDownload = async (fileId: string) => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [fileId]: true,
    }));

    const response = await download({ fileId, deviceType:downloadOption });
    if (response) {
      setSuccess(true);
      console.log(response, result);
      setResults(response.data.data);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    } else {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    }
  };

  const Save = () => {
    const downloadUrl = result?.downloadUrl;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "whatsappVCF");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const close = () => {
    setSuccess(false);
    navigate("/dashboard/download-free-contact");
  };

  return (
    <>
      <Box
        borderRadius="18px"
        borderWidth="0.4px"
        borderColor="#D3D3D3"
        padding="20px 13px"
      >
        <Table width="100%" textAlign="center" fontSize="14px" gap="20">
          <thead style={{ marginBottom: "20px" }}>
            <tr>
              <th>Name</th>
              <th>Compiled Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((props: any) => (
                <React.Fragment key={props.fileId}>
                  <tr>
                    <td>{props.fileName}</td>
                    <td>{moment(props.compiledDate).format("DD-MM-YY")}</td>
                    <td style={{ color: "#6DBAF3" }}>
                      {props.status === "A" ? (
                        <Box>Available</Box>
                      ) : (
                        <Box>Unavailable</Box>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      <Button
                        size="normal"
                        type="button"
                        onClick={() => handleDownload(props.fileId)}
                        variant="primary"
                        disabled={loadingMap[props.fileId]}
                      >
                        {loadingMap[props.fileId]
                          ? "Please wait..."
                          : "Download"}
                      </Button>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  <Box>
                    Sorry you have no free contact kindly submit contact and check back later or use
                    premium to get more contact.
                  </Box>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
      {success && (
        <Modal hideModal={onclick}>
          <div>
            <Text
              fontSize="18px"
              fontWeight={700}
              marginBottom={4}
              textAlign="center"
            >
              Download
            </Text>
            <Text
              fontSize={16}
              textColor="black.100"
              marginBottom="24px"
              textAlign="center"
            >
              Download processed, click to save VCF file
            </Text>
          </div>

          <Flex
            justifyContent="space-around"
            alignItems="center"
            gap="5"
            marginBottom="10px"
          >
            <Button
              variant="primary"
              size="normal"
              type="button"
              onClick={Save}
            >
              Save
            </Button>
            <Button
              variant="secondary"
              size="normal"
              type="button"
              onClick={close}
            >
              Cancel
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export default DownloadContactTemp;
