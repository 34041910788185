import { onbaordingSlideInterface } from "../../../types/components/componentInterfaces";

import files from "../../../assets/svgs/files.svg";
import sms from "../../../assets/svgs/sms.svg";
import views from "../../../assets/svgs/views.svg";

export const slides: Array<onbaordingSlideInterface> = [
  {
    icon: files,
    title: "Download Free VCF Files",
    description:
      "Download free vcf files with particular contact related to field",
  },
  {
    icon: sms,
    title: "Send Bulk SMS",
    description: "Enjoy this great feature which helps you to send bulk SMS.",
  },
  {
    icon: views,
    title: "Get More Status Views",
    description:
      "You can grow your audience as you and other participants gets to save each other contact.",
  },
];
