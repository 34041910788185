import { Box, Flex, Table, Text } from "@chakra-ui/react";

import { axiosBaseInstance } from "../../../services/axiosBaseInstance";
import { modalComponentInterface } from "../../../types/components/componentInterfaces";

import moment from "moment";

import "./premium.module.scss";
import CustomInput from "../../../components/core/input/CustomInput";
import {
  Button,
  OtherHeader,
  PageHeader,
  BottomModal as Modal,
  SelectInput,
} from "../../../components";
import { genders, initialValues, validationSchema } from "./utils/values";
import useSavePremium from "../../../hooks/vcf-hooks/useSavePremium";
import { useEffect, useState } from "react";
import useFetchBusinessTypes from "../../../hooks/data-hooks/useFetchBusinessTypes";
import { dataInterface } from "../../../types/components/componentInterfaces";
import { Formik, FormikValues } from "formik";
import { showNotification } from "@mantine/notifications";
import Price from "./components/Price";
import useSubmitPremium from "../../../hooks/vcf-hooks/useSubmitPremium";

import useDownloadPremium from "../../../hooks/vcf-hooks/useDownloadPremium";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

const PremiumContact = ({ onclick }: modalComponentInterface) => {
  const user = useSelector((state: RootState) => state.user);
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);

  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [businessTypes, setBusinessTypes] = useState<Array<dataInterface>>([]);
  const { fetchBusinessTypes } = useFetchBusinessTypes();
  const { savePremiumRequest } = useSavePremium();
  const { submitPremiumRequest } = useSubmitPremium();
  const [passed, setPassed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [premiumResponse, setPremiumResponse] = useState<any>({
    premiumCode: "",
    totalFee: 0,
  });
  const downloadOption = localStorage.getItem("downloadOption")
  // console.log(downloadOption)

  const [loadingMap, setLoadingMap] = useState<{ [key: string]: boolean }>({});

  const handleSaveRequest = (values: FormikValues) => {
    setIsLoading(true);
    savePremiumRequest(values)
      .then((res) => {
        setIsLoading(false);
        setPremiumResponse(res?.data?.data);
        setIsSaved(true);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification({
          title: "error",
          message:
            err?.response?.data?.message ||
            err?.message ||
            "An error occurred. Try again later",
          color: "red",
        });
      });
  };

  const { download, result } = useDownloadPremium();
  const handleDownload = async (fileId: string) => {
    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [fileId]: true,
    }));

    const response = await download({ fileId, deviceType:downloadOption  });
    if (response) {
      setSuccess(true);
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    } else {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [fileId]: false,
      }));
    }
  };
  const Save = () => {
    const downloadUrl = result?.downloadUrl;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "whatsappVCF");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const closed = () => {
    // setPassed(false)
    setSuccess(false);
  };
  const fetched = async () => {
    try {
      const response = await axiosBaseInstance.get(
        "/WhatsAppVCF/Get-Premium-DownloadFiles",
        {
          headers: {
            Authorization: `Bearer ${user.authToken}`,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      console.log(error);
    }
  };

  const { data } = useQuery({
    queryKey: ["contacts"],
    queryFn: fetched,
    refetchInterval: 2000,
  });
  // console.log(data)
  const handleSubmitRequest = () => {
    setIsLoading(true);

    const values = {
      ...premiumResponse,
      PremiumRequestCode: premiumResponse.premiumCode,
    };
    submitPremiumRequest(values)
      .then((res) => {
        if (res.data.StatusCode === 205 || res.data.Status === false) {
          setPassed(true);
          setIsSaved(false);
          showNotification({
            title: "Failed",
            message: res?.data?.Message,
            color: "red",
          });
        } else {
          setPassed(false);
          // Set the success state to true
          showNotification({
            title: "success",
            message: res?.data?.message || "Request submitted",
            color: "green",
          });
        }
      })
      .catch((err) => {
        console.log(err);

        showNotification({
          title: "error",
          message:
            err?.response?.data?.message ||
            err?.message ||
            "An error occurred. Try again later",
          color: "red",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchBusinessTypes().then((res) => {
      setBusinessTypes(res.data.data);
    });
  }, []);
  return passed === false ? (
    <>
      <>
        <>
        <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
          <PageHeader link="/dashboard" text="Download Premium Contact" />    <Link to="/dashboard/premium-download-history">
          {/* <Box borderRadius="100%" backgroundColor="green.80" padding="5px"> */}
          {/* <RiHistoryFill
              fontSize="20px"
             
            /> */}
          <Text
            fontSize="11px"
            wordBreak="initial"
            width="max-content"
            color="green.100"
          >
            View history
          </Text>
          {/* </Box> */}
        </Link>
      </Flex>

          <Box>
            <Flex justifyContent="center">
              <Text m={2}>Click </Text>
              <Box
                onClick={(e: any) => {
                  e.preventDefault();
                  setPassed(true);
                }}
                cursor="pointer"
              >
                <Text m={2} color="green">
                  Here
                </Text>
              </Box>
              <Text m={2}>to request more contact</Text>
            </Flex>
          </Box>
          <Box
            borderRadius="18px"
            borderWidth="0.4px"
            borderColor="#fdd5d5"
            padding="20px 13px"
          >
            <Table width="100%" textAlign="center" fontSize="14px" gap="20">
              <thead style={{ marginBottom: "20px" }}>
                <tr>
                  <th>Name</th>
                  <th>Compiled Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              {data?.length > 0 ? (
                data?.map((props: any) => (
                  <tbody>
                    {/* <React.Fragment key={props.fileId}> */}
                    <tr>
                      <td>{props.fileName}</td>
                      <td>{moment(props.compiledDate).format("DD-MM-YY")}</td>
                      <td style={{ color: "#6DBAF3" }}>
                        {props.status === "A" ? (
                          <Box>Available</Box>
                        ) : (
                          <Box>Unavailable</Box>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        <Button
                          size="normal"
                          type="button"
                          onClick={() => handleDownload(props.fileId)}
                          variant="primary"
                          disabled={loadingMap[props.fileId]}
                        >
                          {loadingMap[props.fileId]
                            ? "Please wait..."
                            : "Download"}
                        </Button>
                      </td>
                    </tr>
                    {/* </React.Fragment> */}
                    {/* ))} */}
                  </tbody>
                ))
              ) : (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    <Box>
                      Your request for premium contact is being processed
                      pleased check back later!
                    </Box>
                  </td>
                </tr>
              )}
            </Table>
          </Box>
          {success && (
            <Modal hideModal={onclick}>
              <div>
                <Text
                  fontSize="18px"
                  fontWeight={700}
                  marginBottom={4}
                  textAlign="center"
                >
                  Download
                </Text>
                <Text
                  fontSize={16}
                  textColor="black.100"
                  marginBottom="24px"
                  textAlign="center"
                >
                  Download processed, click to save VCF file
                </Text>
              </div>

              <Flex
                justifyContent="space-around"
                alignItems="center"
                gap="5"
                marginBottom="10px"
              >
                <Button
                  variant="primary"
                  size="normal"
                  type="button"
                  onClick={Save}
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  size="normal"
                  type="button"
                  onClick={closed}
                >
                  Cancel
                </Button>
              </Flex>
            </Modal>
          )}
        </>
      </>
    </>
  ) : (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard" text="Download Premium Contact" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSaveRequest(values);
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <Box marginBottom="30px">
              <CustomInput
                type="date"
                placeholder="Choose Date"
                name="fromDate"
                id="fromDate"
                width="full"
                label="From"
                value={values.fromDate}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.fromDate ? errors.fromDate : null
                }
              />
            </Box>
            <Box marginBottom="30px">
              <CustomInput
                type="date"
                placeholder="Choose Date"
                name="toDate"
                id="toDate"
                width="full"
                label="To"
                value={values.toDate}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.toDate ? errors.toDate : null
                }
              />
            </Box>

            <Box marginBottom="30px">
              <CustomInput
                type="text"
                placeholder="Enter number of contact"
                name="numberOfContact"
                id="numberOfContact"
                width="full"
                label="Number of contact needed"
                onChange={handleChange}
                value={`${values.numberOfContact}`}
                error={
                  isValidatingSchema && errors.numberOfContact
                    ? errors.numberOfContact
                    : null
                }
              />
              <Text color="grey.dark" fontStyle="italic" fontSize="13px">
                Max - 5000 contacts
              </Text>
            </Box>

            <Box marginBottom="30px">
              <SelectInput
                placeholder="Select type of contact to download"
                values={businessTypes}
                id="contactType"
                label="Select Type of Contacts"
                name="contactType"
                onChange={handleChange}
                value={values.contactType}
                error={
                  isValidatingSchema && errors.contactType
                    ? errors.contactType
                    : null
                }
              />
            </Box>

            <Box marginBottom="10px">
              <SelectInput
                id="gender"
                placeholder="Choose gender"
                values={genders}
                label="Select Gender"
                name="gender"
                onChange={handleChange}
                value={values.gender}
                error={
                  isValidatingSchema && errors.gender ? errors.gender : null
                }
              />
            </Box>

            <Box marginTop="50px" marginBottom="20px">
              {premiumResponse.totalFee === 0 ? null : (
                <Price price={premiumResponse.totalFee} />
              )}

              {isSaved ? (
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  onClick={handleSubmitRequest}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </Button>
              ) : (
                <Button size="normal" type="submit" variant="primary">
                  {isLoading ? "Loading..." : "Save"}
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default PremiumContact;

{
  /* <OtherHeader />

      <PageHeader link="/dashboard" text="Download Premium Contact" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSaveRequest(values);
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <Box marginBottom="30px">
              <CustomInput
                type="date"
                placeholder="Choose Date"
                name="fromDate"
                id="fromDate"
                width="full"
                label="From"
                value={values.fromDate}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.fromDate ? errors.fromDate : null
                }
              />
            </Box>
            <Box marginBottom="30px">
              <CustomInput
                type="date"
                placeholder="Choose Date"
                name="toDate"
                id="toDate"
                width="full"
                label="To"
                value={values.toDate}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.toDate ? errors.toDate : null
                }
              />
            </Box>

            <Box marginBottom="30px">
              <CustomInput
                type="text"
                placeholder="Enter number of contact"
                name="numberOfContact"
                id="numberOfContact"
                width="full"
                label="Number of contact needed"
                onChange={handleChange}
                value={`${values.numberOfContact}`}
                error={
                  isValidatingSchema && errors.numberOfContact
                    ? errors.numberOfContact
                    : null
                }
              />
              <Text color="grey.dark" fontStyle="italic" fontSize="13px">
                Max - 5000 contacts
              </Text>
            </Box>

            <Box marginBottom="30px">
              <SelectInput
                placeholder="Select type of contact to download"
                values={businessTypes}
                id="contactType"
                label="Select Type of Contacts"
                name="contactType"
                onChange={handleChange}
                value={values.contactType}
                error={
                  isValidatingSchema && errors.contactType
                    ? errors.contactType
                    : null
                }
              />
            </Box>

            <Box marginBottom="10px">
              <SelectInput
                id="gender"
                placeholder="Choose gender"
                values={genders}
                label="Select Gender"
                name="gender"
                onChange={handleChange}
                value={values.gender}
                error={
                  isValidatingSchema && errors.gender ? errors.gender : null
                }
              />
            </Box>

            <Box marginTop="50px" marginBottom="20px">
              {premiumResponse.totalFee === 0 ? null : (
                <Price price={premiumResponse.totalFee} />
              )}

              {isSaved ? (
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  onClick={handleSubmitRequest}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </Button>
              ) : (
                <Button size="normal" type="submit" variant="primary">
                  {isLoading ? "Loading..." : "Save"}
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik> */
}
//     <>
//     <>
//    <PageHeader link="/dashboard" text="Download Premium Contact" />
// <Box
//       borderRadius="18px"
//       borderWidth="0.4px"
//       borderColor="#D3D3D3"
//       padding="20px 13px"
//     >

//       <Table width="100%" textAlign="center" fontSize="14px" gap="20">
//       <thead style={{ marginBottom: "20px" }}>
//           <tr>
//             <th>Name</th>
//             <th>Compiled Date</th>
//             <th>Status</th>
//           </tr>
//         </thead>
//         {
//           data?.length > 0? (
// data?.map((props:any)=>(
//   <tbody>

//   {/* <React.Fragment key={props.fileId}> */}
//   <tr>
//                 <td>{props.fileName}</td>
//                 <td>{moment(props.compiledDate).format("DD-MM-YY")}</td>
//                 <td style={{ color: "#6DBAF3" }}>
//                   {props.status === "A" ? <Box>Available</Box> : <Box>Unavailable</Box>}
//                 </td>
//               </tr>
//               <tr>
//                 <td colSpan={3} style={{ textAlign: "center" }}>
//                   <Button
//                     size="normal"
//                     type="button"
//                     onClick={() => handleDownload(props.fileId)}
//                     variant="primary"
//                     disabled={loadingMap[props.fileId]}
//                   >
//                     {loadingMap[props.fileId] ? "Please wait..." : "Download"}
//                   </Button>
//                 </td>
//               </tr>
//   {/* </React.Fragment> */}
// {/* ))} */}
// </tbody>
// ))
//           ):(
//             <tr>
//             <td colSpan={3} style={{ textAlign: "center" }}>
//             <Box>
//           Your request for premium contact is being processed pleased check back later!
//           </Box>
//             </td>
//           </tr>
//           )
//         }

//       </Table>

//     </Box>
//        {
//         success &&  <Modal hideModal={onclick}>
//         <div>
//           <Text
//             fontSize="18px"
//             fontWeight={700}
//             marginBottom={4}
//             textAlign="center"
//           >
//             Download
//           </Text>
//           <Text
//             fontSize={16}
//             textColor="black.100"
//             marginBottom="24px"
//             textAlign="center"
//           >
//        Download processed, click to save VCF file
//           </Text>
//         </div>

//         <Flex
//           justifyContent="space-around"
//           alignItems="center"
//           gap="5"
//           marginBottom="10px"
//         >
//           <Button variant="primary" size="normal" type="button" onClick={Save}>
//             Save
//           </Button>
//           <Button
//             variant="secondary"
//             size="normal"
//             type="button"
//             onClick={closed}
//           >
//             Cancel
//           </Button>
//         </Flex>
//       </Modal>
//       }
//     </>

//     </>
