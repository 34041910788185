import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex } from '@chakra-ui/react'
import { Progress } from '@mantine/core'
import { PiWarningCircle } from 'react-icons/pi'
import { useNavigate , useParams} from 'react-router-dom'
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById'

const PromoteCard = () => {
const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetCardById(id);
  // console.log(data);
  const [percentages, setPercentages] = useState<any>(0);

  const [budget, setBudget] = useState<number>();
  const [valid, setValid] = useState<boolean>(false); // Validation status

  const [duration, setDuration] = useState<number>();
  const datas = {
    
    duration: percentages ,
    totalSpend: percentages ? percentages * 100: '',
    id: id,
  };

  const savePromote = () => {
    const convert = JSON.stringify(datas);
    localStorage.setItem('promoteCard', convert);
    navigate(`/dashboard/creator/review-promotion/${id}`);
  };

  // console.log(datas);

  const handlePercentageChange = (e: any) => {
    const value = parseInt(e.target.value);
    setPercentages(value);
    setDuration(value);
    validateInputs(); 
  };



  const validateInputs = () => {
    if (percentages ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };
  
  return (
  <>
      <OtherHeader />

      <PageHeader text="Promote card" link={`/dashboard/creator/single-card-detail/${id}`} />
      <Box>
        <Flex flexDirection="column">
          <Box width="100%" fontWeight="600" fontSize="12px">
            <Flex justifyContent="space-between ">
              <Box>{data?.name}</Box>
              <Box>{data?.title}</Box>
            </Flex>
            <Box mt={2}>
              <hr />
            </Box>
          </Box>
        </Flex>
        <Box width="100%" fontSize="12px" textAlign="left">
          <Flex justifyContent="center">
            <Box width="80%" marginTop={12} textAlign="center">
              {data?.description}
            </Box>
          </Flex>
        </Box>
        <Box mb={4} mt={12} width="100%" fontWeight="600" fontSize="12px">
          <Box>Duration</Box>
          <hr />
        </Box>
        <Box mt={8}>
          <input
            type="range"
            min="1"
            max="30"
            value={percentages}
            onChange={handlePercentageChange}
            style={{ width: '100%', cursor: 'pointer', color: '#f0c608 !important', background: '#f0c608' }}
          />
          <Box mt={4} fontSize={12} fontWeight="600">
            {percentages} days
          </Box>
        </Box>
        <Box mb={4} mt={12} width="100%" fontWeight="600" fontSize="12px">
       
          <hr />
        </Box>
      
        <Flex mt={8} fontSize={12} marginTop="180px">
          <Box mt="3px">
            <PiWarningCircle color="#0accf7" />
          </Box>
          <Box ml={2}>Your daily budget limit can't exceed N10,000 to promote your card</Box>
        </Flex>
        <Box mt={8}>
          <Box mt={8}>
          <Button onClick={savePromote} variant="primary" size="normal" disabled={!valid}>
            Next
          </Button>
          {!valid && (
            <Box mt={2} fontSize={12} color="red">
              Please select ranges for duration.
            </Box>
          )}
        </Box>
      </Box>
      </Box>
    </>
  )
}

export default PromoteCard