import axios from "axios";
const { REACT_APP_BASE_URL, REACT_APP_X_KEY } = process.env;

const axiosBaseInstance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    "X-KEY": REACT_APP_X_KEY,
  },
});

export { axiosBaseInstance };
