import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";


interface CardForm {
    id: any;

    totalSpend: any;
    duration: any;

  }
  

const usePromoteCard = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);
  const navigation =useNavigate()
  const promoteCard = async (values: CardForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .put("/Creator/Promote-Card", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        setLoading(false)
        if(res.data.status === true){
          setLoading(false)
          showNotification({
              title: "Success",
              message: res.data.message,
              color: "green",
            });
            navigation("/dashboard/creator/become-a-creator")
      }else{
        showNotification({
          title: "Failed",
          message: res.data.message,
          color: "red",
        });
      }
        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { promoteCard , loading};
};

export default usePromoteCard;
