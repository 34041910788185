import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useSubmitContact = () => {
  const user = useSelector((state: RootState) => state.user);

  const submitContact = async (values: FormikValues) => {
    return await axiosBaseInstance
      .post("/WhatsAppVCF/Submit-WhatsApp-Contact", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        return res;
      })
      .then((err) => {
        return err;
      });
  };

  return { submitContact };
};

export default useSubmitContact;
