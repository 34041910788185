import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { whatsappTvParamsInterface } from "../../types/components/componentInterfaces";

const useFetchAvailableTvs = ({ name }: whatsappTvParamsInterface) => {
  const user = useSelector((state: RootState) => state.user);

  const fetchAvailableTvs = async () => {
    return await axiosBaseInstance
      .get("/WhatsappTv/registeredwhatsapptvs", {
        params: {
          filters: name,
        },
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return { fetchAvailableTvs };
};

export default useFetchAvailableTvs;
