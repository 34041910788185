import { buttonInterface } from "../../../types/components/componentInterfaces";

const ButtonComp = ({
  children,
  type,
  variant,
  size,
  borderColor,
  ...rest
}: buttonInterface) => {
  return (
    <button
      {...rest}
      type={type}
      
      className={`btn btn--${variant} btn--${size}`}
      style={{ margin: "8px 0" }}
    >
      {children}
    </button>
  );
};

export default ButtonComp;
