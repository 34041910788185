import * as yup from "yup";

export const validationSchema = yup.object().shape({
  email: yup.string().email().required("Enter a valid address"),
  phoneNumber: yup
    .string()
    .min(11, "Enter a valid phone number")
    .required("Please Enter your phone number"),
  passWord: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#.,\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Lowercase, One Number and One Special Case Character"
    )
    .required("password is required"),
});
