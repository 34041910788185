import { Box, Text } from "@chakra-ui/react";
import MiddleModal from "../MiddleModal/MiddleModal";
import { Loader, Rating } from "@mantine/core";
import { useState } from "react";
import CustomInput from "../../core/input/CustomInput";
import useCreateReview from "../../../hooks/whatsapptv-hooks/useCreateReview";
import { FormikValues } from "formik";
import { BsCheck2Circle } from "react-icons/bs";
interface props {
  closeModal: () => void;
  whatsAppTvId?: number;
}

const RatingModal = ({ closeModal, whatsAppTvId }: props) => {
  const [isDescriptionBox, setIsDescriptionBox] = useState(false);
  const [reviewData, setReviewData] = useState({
    whatsAppReviews: "",
    whatsAppTvId: whatsAppTvId,
  });
  const { addReview, isLoading, isSuccess } = useCreateReview();

  const handleNewReview = () => {
    addReview(reviewData).then((res) => {
      console.log(res);
    });
  };

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleNewReview();
    }
  }

  const handleReviewChange = (value: string) => {
    setReviewData({ ...reviewData, whatsAppReviews: value });
  };

  return (
    <MiddleModal onclose={closeModal}>
      {isSuccess ? (
        <Box padding="0px 50px">
          <BsCheck2Circle
            fontSize="80px"
            color="#4cb051"
            style={{ margin: "auto", marginBottom: "10px" }}
          />
          <Text fontSize="18px" fontWeight="500" color="#4cb051">
            Review added
          </Text>
        </Box>
      ) : (
        <>
          <Box padding="0px 50px">
            <Text fontSize="16px" fontWeight={600} marginBottom="5px">
              Rate this TV
            </Text>
            <Text fontSize={12} textColor="#717171" marginBottom="10px">
              Tell others what you think about this TV
            </Text>
          </Box>

          {isLoading ? (
            <Loader style={{ margin: "50px auto" }} size="lg" color="#4cb051" />
          ) : (
            <Box textAlign="left">
              <Rating
                defaultValue={0}
                fractions={2}
                size="30px"
                color="yellow"
                style={{ margin: "auto", marginBottom: "25px" }}
              />
              {isDescriptionBox ? (
                <Box>
                  <textarea
                    placeholder="Describe your experience"
                    onChange={(e) => {
                      handleReviewChange(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    style={{
                      outline: "0px",
                      padding: "10px",
                      height: "120px",
                      width: "100%",
                      border: "none",
                      backgroundColor: "#f1f1f1",
                      resize: "none",
                      borderRadius: "12px",
                    }}
                  ></textarea>
                </Box>
              ) : (
                <Text
                  fontSize={12}
                  textColor="#4CB051"
                  fontWeight="600"
                  marginBottom="16px"
                  cursor="pointer"
                  onClick={() => {
                    setIsDescriptionBox(true);
                  }}
                >
                  Write a review
                </Text>
              )}
            </Box>
          )}
        </>
      )}
    </MiddleModal>
  );
};

export default RatingModal;
