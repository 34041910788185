import { Text } from "@chakra-ui/react";
import { Button, MiddleModal as Modal } from "../..";
import { modalComponentInterface } from "../../../types/components/componentInterfaces";

const Wassaplinksuccess = ({ onclick, link , onclose}: modalComponentInterface) => {
  return (
    <Modal onclose={onclose}>
      <Text fontSize="18px" fontWeight={600} marginBottom={4}>
      Here is your whatsApp short link
      </Text>

      <Text fontSize={12} textColor="#717171" marginBottom="24px" width={300}>
      Copy your link and share it out to your  social media, website,
emails, direct messages or anywhere you want to be 
contacted instantly by your friends, customers ETC 
      </Text> 
<Text fontSize="18px" fontWeight={700} marginBottom={4}>
{link}
</Text>
      <Button variant="primary" size="normal" type="button" onClick={onclick}>
        Copy link
      </Button>
    </Modal>
  );
};

export default Wassaplinksuccess;
