import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";


interface CardForm {
    cardId?: any,
    cardLink?: string,
    name?: string,
    whatsAppNumber?: string,
    age?: any,
    email?: string,
    phoneNumber?: string,
    address?: string,
    city?: string,
    profession?: string,
    state?: string,
    occupation?: string,
    skill?: string,
    gender?: string,
    businessType?: string,
    others?: string
  }
  

const useSubmitCardContactForm = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const SubmitCard = async (values: CardForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .post("/Creator/SubmitCardContact-Form", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        setLoading(false)

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { SubmitCard , loading};
};

export default useSubmitCardContactForm;
