import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { updateEmail } from "../../redux/reducers/emails";

const useFetchEmails
 = () => {
  const [data, setData] = useState<any>([]);
  const [loadEmail, setLoadEmail] =useState(false)
  const user = useSelector((state: RootState) => state.user);
  const dispatch =useDispatch()
//   console.log(user)
 const navigate = useNavigate()
    const fetched = async () => {
      try {
        setLoadEmail(true)
        await axiosBaseInstance
          .get("/BulkEmail/get-uploaded-emails", {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res:any) => {
            if(res.data.status === true){
                setLoadEmail(false)
                showNotification({
                    message:"Uploaded emails fetched",
                    color:"green"
                      })
                navigate("/dashboard/bulk/bulk-emails/send-email")
                
                dispatch(updateEmail(res.data.data));
                
            }else{
                showNotification({
                    message:res.data.message,
                    color:"red"
                      })

            }
            console.log(res.data);
   
          });
      } catch (error: any) {
        console.log(error);
      }
    };

   


  return { data, fetched, loadEmail };
};

export default useFetchEmails
;
