import { CiCircleMinus } from "react-icons/ci";
import { RxLink1 } from "react-icons/rx";
import { BiEditAlt } from "react-icons/bi";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface props {
  controlModal: () => void;
  controlDisable: () => void;
  controlEdit: () => void;
  controlShare: () => void;
}
const Menu = ({
  controlModal,
  controlDisable,
  controlEdit,
  controlShare,
}: props) => {
  const menuItems = [
    {
      text: "Verification status",
      icon: <HiOutlineDotsCircleHorizontal />,
      link: "",
    },
    { text: "Get my link", icon: <RxLink1 />, onclick: controlShare },
    {
      text: "Disable my TV",
      icon: <CiCircleMinus />,

      onclick: controlDisable,
    },
    {
      text: "Edit my TV",
      icon: <BiEditAlt size={14} />,
      link: "/dashboard/edit-whatsapp-tv",
      onclick: controlEdit,
    },
  ];
  return (
    <Box
      position="fixed"
      bottom="0px"
      left="0px"
      width="full"
      height="full"
      zIndex={100}
      backgroundColor="rgba(11, 11, 11, 0.5)"
      onClick={controlModal}
    >
      <Box
        backgroundColor="#FDFFFD"
        borderRadius="16px"
        rowGap={25}
        position="absolute"
        top="50px"
        right="20px"
        padding="20px"
      >
        {menuItems?.map((item) => (
          <>
            {item.link && item.link !== "" ? (
              <Link to={item.link} key={item.text}>
                <Flex
                  alignItems="center"
                  marginBottom="10px"
                  onClick={item?.onclick}
                  cursor="pointer"
                >
                  <Box marginRight="10px">{item.icon}</Box>
                  <Text>{item.text}</Text>
                </Flex>
              </Link>
            ) : (
              <Flex
                alignItems="center"
                marginBottom="10px"
                onClick={item.onclick}
                cursor="pointer"
              >
                <Box marginRight="10px">{item.icon}</Box>
                <Text>{item.text}</Text>
              </Flex>
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

export default Menu;
