import React, { useState } from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea, Button } from '@chakra-ui/react'
import useResendbulksms from '../../../hooks/bulkHooks/useResendbulkSms';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { phoneNumberInterface } from '../../../types/auth/authInterfaces';
import CustomInput from '../../../components/core/input/CustomInput';
import useGetSingleMessage from '../../../hooks/bulkHooks/useGetSingleMessage';
import { useParams } from 'react-router-dom';
import useDeleteBulkSms from '../../../hooks/bulkHooks/useDeleteBulkSms';

const DeleteMessage = () => {
  const phoneNumber = useSelector((state: RootState) => state.phoneNumbers);
  console.log(phoneNumber)

  const {id} =useParams()

//   const {SendSingleSms, loading} = useResendbulksms()
// const allContacts = phoneNumber?.phoneNumber?.map((props:any)=>{
//   return props.phoneNumber
// })
// console.log(allContacts)
 

const {data} = useGetSingleMessage(id)
const sorted = data?.data?.recipients
const newSort = sorted?.split(",")
console.log(newSort)
const price =Math.ceil( 2.01*newSort?.length)

console.log(data?.data)
const initialFormValues = {

};
 


const {DeleteContact, loading} = useDeleteBulkSms()

const Delete = async(id:any)=>{
  try {
    await DeleteContact(id).then((res)=>{
console.log(res)
window.location.reload()
    })
  } catch (error) {
    console.log(error)
  }
}


 
  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-message" text="Send Message" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your SMS message
    </Box>
    <Box mt={4} fontWeight={600}>
        Contacts
    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2" p={2} color="gray" fontSize={12}>
    
 
      <React.Fragment >
      {data?.data?.recipients}
      </React.Fragment>
   
  
    </Box>

    <Box  mt={4} fontWeight={600}>Title</Box>

    <CustomInput
    name='title'
    value={data?.data?.title}
    />
    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">

{data?.data?.message?  
 
<Box h="85%" w="100%" > 
{data?.data?.message}
</Box>:

<Textarea name='message' value={data?.data?.message} h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>

}
<Box textAlign="right">
{data?.data?.message?.length}
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee :</Box>
            <Box fontWeight="bold">₦{price}{".00"}</Box>
        </Flex>
    </Box>
    <Box>
    <Button w='100%' mt="10px" color="white" h="60px" borderRadius="20px" p="10px" bg="red" onClick={Delete} >
   {
    loading?" please wait...":"Delete Message"
   }
    </Button>
    </Box>

</Box>
</>
  )
}

export default DeleteMessage 