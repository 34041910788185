import { Box, Flex, Image, InputGroup, Link, Spinner, Text, Textarea } from "@chakra-ui/react";
import {
    ComingSoonModal,
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { Button, BottomModal as ModalLayout } from "../../../components";
import { useState } from "react";

import Wassaplinksuccess from "../../../components/Modals/ComingSoonModal/wassaplinksuccess";
import sort from "../../../assets/images/sort.png"
import {BsLink45Deg} from "react-icons/bs"
import useFetchwhatsappLink from "../../../hooks/whatsapplink-hooks/useFectchAllLinks";
import { showNotification } from "@mantine/notifications";

import copy from "clipboard-copy";

const CreatedLink = () => {
    const [isComingSoon, setIsComingSoon] = useState(false);

    const { fetching, data}=useFetchwhatsappLink()

  const controlComingSoonModal = () => {
    setIsComingSoon(!isComingSoon);
  };
  return (
    <Box>
              {isComingSoon && <Wassaplinksuccess onclick={controlComingSoonModal} />}
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Created whatsapp links" link="/dashboard" />
        

       <Image width={5} src={sort} alt="sort"/>
      </Flex>
    

<Box fontSize={12} fontWeight="bold" ml={2}>
  No of Links: {data?.length}
</Box>

{


fetching?(
  <Box width="100%" height={400}>
  <Flex justifyContent="center" alignItems="center" height="100%">
    <Spinner size="xl"/>
  </Flex>
</Box>
):(
  data && data.length>0?(
   <>
    { data?.map((props:any, index:any)=>{
    return(
<Flex justifyContent="space-between" fontSize={12} borderBottom="1px solid #D9D9D9" p={2}>
  <Box>
    <span >
    {index+1}

    </span>
    <span style={{
      marginLeft:"10px"
    }}>
    {props.shortLink}

    </span>
  </Box>
  <Flex width={100} flexDirection="row" alignItems="center" justifyContent="space-between">
    <Link href={`/dashboard/whatsapp-link/open-whatsapp-link/${props.id}`}   color="#4CB051"  >

    View analytics
    </Link >
    <Box borderRight="1px solid #D9D9D9" h={3} >

    </Box>
    <Box cursor="pointer">
    <BsLink45Deg  onClick={((e:any)=>{
      e.preventDefault()
      copy(props.shortLink)
      showNotification({
        message:"Link copied!",
        color:"green"
      })
    })} color="#4CB051"/>


    </Box>
  </Flex>
</Flex>
    )
  })
}
   </> 
  ):(<>
  
  <Box width="100%" height="350px">
        <Flex justifyContent="center" alignItems="center" height="100px">
      
      <Text fontSize="24px">
        Sorry no data available
      </Text>
 
        </Flex>
      </Box>
  </>)
)
}















    </Box>
  );
};

export default CreatedLink;
