import { Box, Flex, Image, Input } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { fileUploadInterface } from "../../types/components/componentInterfaces";
import { BsImage } from "react-icons/bs";
import { useFormikContext } from "formik";
import { useState } from "react";

const FileUpload = ({ name, error }: fileUploadInterface) => {
  const [displayMedia, setDisplayMedia] = useState("");
  const { setFieldValue } = useFormikContext<{}>();

  const handleChange = (e: any) => {
    const file = e.target.files[0];
    // setFieldValue(name, file);
    const save = URL.createObjectURL(file);
    setDisplayMedia(save);

    var reader = new FileReader();
    reader.onloadend = function (event: any) {
      const dataUrl = event.target.result;
      const base64 = dataUrl.split(",")[1];

      setFieldValue(name, dataUrl);
      return base64;
    };

    reader.readAsDataURL(file);
  };
  return (
    <Box>
      <Flex
        borderWidth="1px"
        borderColor="grey.main"
        borderStyle="dashed"
        borderRadius="10px"
        padding="12px"
        width="full"
        alignItems="center"
        justifyContent="center"
        marginTop="16px"
      >
        <Box position="relative">
          <label
            htmlFor={name}
            style={{ position: "absolute", top: "-5px", right: "-10px" }}
          >
            <AiFillPlusCircle color="#4CB051" fontSize={24} />
          </label>

          {displayMedia === "" ? (
            <BsImage color="#D3D3D3" fontSize={50} />
          ) : (
            <Image
              src={displayMedia}
              alt={name}
              width={50}
              height={50}
              borderRadius={8}
              objectFit="cover"
            />
          )}
        </Box>
        <Input
          type="file"
          id={name}
          name={name}
          display="none"
          onChange={(e: any) => {
            handleChange(e);
          }}
        />
      </Flex>
      {error && <div className="error">{error}</div>}
    </Box>
  );
};

export default FileUpload;
