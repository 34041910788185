import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  userInitialStateInterface,
  userInterface,
} from "../../types/auth/authInterfaces";

interface actionInterface {
  authToken: string;
  accountDetails: userInterface;
}

export const initialState: userInitialStateInterface | null = {
  authToken: "",
  data: {
    fullName: "",
    email: "",
    phoneNumber: "",
    profileImage: "",
    createdDate: "",
    dob: "",
    countryCode: "",
    state: "",
    businessId: "",
    gender: "",
    whatsAppNumber: "",
    facebookLink: "",
    instagramLink: "",
    virtualAccounts: [],
  },
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<actionInterface>) => {
      state.authToken = action.payload.authToken;
      state.data = action.payload.accountDetails;
    },
    removeUser: (state) => {
      return (state = initialState);
    },
    updateUser: (state, action: PayloadAction<userInterface>) => {
      return { ...state, data: action.payload };
    },
  },
});

export const { addUser, removeUser, updateUser } = userReducer.actions;
export default userReducer.reducer;
