import { Formik, FormikValues } from "formik";
import { Button, FileUpload, OtherHeader, PriceBox } from "../../../components";
import { PageHeader } from "../../../components";
import CustomInput from "../../../components/core/input/CustomInput";
import { tvIntitialValues } from "./utils/values";

import { Box, Flex, InputGroup, Text } from "@chakra-ui/react";
import { validationSchema } from "./utils/validation";
import useCreateWhatsAppTv from "../../../hooks/whatsapptv-hooks/useCreateWhatsAppTv";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { countryCodes } from "../../../config/data";
import { IoAlertCircleOutline } from "react-icons/io5";

const CreateWhatsappTv = () => {
  const { createWhatsAppTv } = useCreateWhatsAppTv();
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleCreateTv = (values: FormikValues) => {
    setIsLoading(true);
    createWhatsAppTv(values).then((res) => {
      setIsLoading(false);
      if (res.status === true) navigate("/dashboard/my-whatsapp-tv");
    });
  };
  return (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard" text="Create WhatsApp TV" />
      <Formik
        initialValues={tvIntitialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleCreateTv(values);
        }}
      >
        {({ errors, values, handleChange, handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log(values);
              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <CustomInput
              placeholder="Enter your Tv name"
              id="whatsAppTVName"
              label="Enter your WhatsApp Tv name"
              required
              name="whatsAppTVName"
              value={values.whatsAppTVName}
              onChange={handleChange}
              error={
                isValidatingSchema && errors.whatsAppTVName
                  ? errors.whatsAppTVName
                  : null
              }
            />

            <label className="input-label">Enter your whatsapp TV number</label>

            <InputGroup display="flex" flexDirection="row" alignItems="center">
              <div>
                <select
                  name="countryCode"
                  value={values.countryCode}
                  onChange={handleChange}
                  style={{
                    // width: "30%",
                    marginRight: "8px",
                    borderRadius: "10px",
                    height: "54px",
                    backgroundColor: "#F1F1F1",
                    marginTop: "-5px",
                  }}
                >
                  {countryCodes?.map((code) => (
                    <option value={code}>{code}</option>
                  ))}
                </select>
                {errors.countryCode && (
                  <div className="error">{errors.countryCode}</div>
                )}
              </div>

              <CustomInput
                placeholder="Enter your TV phone number"
                id="whatsAppNumber"
                // label="Enter your whatsapp TV number"
                required
                name="whatsAppNumber"
                value={values.whatsAppNumber}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.whatsAppNumber
                    ? errors.whatsAppNumber
                    : null
                }
              />
            </InputGroup>

            <CustomInput
              placeholder="Enter the number"
              id="whatsAppViewRange"
              label="Enter the number of your daily view range"
              required
              name="whatsAppViewRange"
              value={values.whatsAppViewRange}
              onChange={handleChange}
              error={
                isValidatingSchema && errors.whatsAppViewRange
                  ? errors.whatsAppViewRange
                  : null
              }
            />

            <label className="input-label">Enter your price per post</label>
            <Flex
              justifyContent="space-between"
              marginTop="16px"
              marginBottom="20px"
            >
              <PriceBox
                label="Link"
                value={values.linkPrice}
                name="linkPrice"
                error={
                  isValidatingSchema && errors.linkPrice
                    ? errors.linkPrice
                    : null
                }
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <PriceBox
                label="Picture"
                value={values.picturePrice}
                name="picturePrice"
                error={
                  isValidatingSchema && errors.picturePrice
                    ? errors.picturePrice
                    : null
                }
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <PriceBox
                label="Video"
                value={values.videoPrice}
                name="videoPrice"
                error={
                  isValidatingSchema && errors.videoPrice
                    ? errors.videoPrice
                    : null
                }
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Flex>

            <Box marginBottom="20px">
              <label className="input-label" style={{ marginBottom: "0px" }}>
                Add your whatsApp TV profile picture
              </label>
              <Text className="input-sub-label">
                it can be in a form of (jpg, png, jpeg, mp4)
              </Text>
              <FileUpload
                name="whatsAppTVProfilePicture"
                error={
                  isValidatingSchema && errors.whatsAppTVProfilePicture
                    ? errors.whatsAppTVProfilePicture
                    : null
                }
              />
            </Box>

            <Box marginBottom="20px">
              <label className="input-label" style={{ marginBottom: "0px" }}>
                Send a visiable picture to verify your daily view range
              </label>
              <Text className="input-sub-label">
                it can be in a form of (jpg, png, jpeg, mp4)
              </Text>
              <FileUpload
                name="whatsAppDailyViewRangePicture"
                error={
                  isValidatingSchema && errors.whatsAppDailyViewRangePicture
                    ? errors.whatsAppDailyViewRangePicture
                    : null
                }
              />
            </Box>
            <Box margin="20px 0px">
              <Flex alignItems="center" fontSize="12px">
                <IoAlertCircleOutline
                  fontSize="14px"
                  style={{ marginRight: "5px" }}
                />
                <Text color="#393939" marginRight="5px">
                  Total Fee:
                </Text>
                <Text color="#393939" fontWeight="600">
                  N2,000
                </Text>
              </Flex>
              <Button variant="primary" size="normal" type="submit">
                {isLoading ? "Loading..." : " Create WhatsApp TV"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateWhatsappTv;
