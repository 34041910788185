import React, { useState } from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';
import { useNavigate } from 'react-router-dom';
import useGetCreatedCard from '../../../hooks/creator-hooks/useGetCreatedCards';
import useGetExpiredCards from '../../../hooks/creator-hooks/useGetExpiredCards';
import useGetPromotedCards from '../../../hooks/creator-hooks/useGetPromotedCards';

const BecomeAcreator = () => {
    const {data} = useGetCreatedCard()
    const {data:expired} = useGetExpiredCards()
    const {data:promoted} = useGetPromotedCards()
    console.log(data)
  
    const [isModal, setIsModal] = useState(false);
    const value = 35;
    const threshold = 100;
    // const percentage = (value / threshold) * 100;
    const hideSubmitContactModal = () => {
        setIsModal(false);
      }; 
      const openSubmitContactModal = () => {
        setIsModal(true);
      };

const navigate= useNavigate()
const expiredCards = ()=>{
  navigate("/dashboard/creator/all-expired-cards")
  }
const promotedCards = ()=>{
    navigate("/dashboard/creator/all-promoted-cards")
    }
    const createdCards = ()=>{
        navigate("/dashboard/creator/all-created-cards")
        }

        const download = ()=>{
          navigate("/dashboard/creator/download")
          }
      
  return (
 <>
 {
    isModal && 

 <CreatorModal hideModal={hideSubmitContactModal}/>

 }
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard" text="Become a creator" />
<Box w="30px" h="30px" borderRadius="100%" cursor="pointer" onClick={openSubmitContactModal}>

    <Flex bg="#ecffe9f9" justifyContent="center" alignItems="center"  borderRadius="100%" h="100%">
    <GoPlus color="#25cf03" />


    </Flex>
</Box>
</Flex> 
    </Box>
 {/* </PageHeader> */}

 <Box>
    <Flex flexDirection="column">
        <Box w="100%" p="2px">
            <>
            
           
        {
                promoted?.data?.length>0?   <Flex   w="100%" justifyContent="space-between" alignItems="center">
                <Box fontSize={12} fontWeight="600" >Promoted cards</Box>
                <Box fontSize={12} color="#25cf03" onClick={promotedCards}>See all</Box>
            </Flex>:null
            }
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between">
       
        {
  promoted?.data?.slice(0, 2).map((props:any) => {
    const percentage = (props.submittedContactCount / props.contactLimit) * 100;

    return (
      <Box boxShadow="md" width="45%" borderRadius="10px">
        <Link href={`/dashboard/creator/single-card-form/${props.id}`} >
        <Box width="100%" padding="5px" ml={2}>
          <Flex flexDirection="column">
            <Box cursor="pointer">
              <RiFolderOpenLine color='#25cf03' fontSize={20} />
            </Box>
            <Box fontSize="12px" fontWeight="600" mt="10px">{props.name}</Box>
            <Box fontSize="12px" color="#00000072">{props.title}</Box>
            <Box marginTop="10px"> 
              <Image objectFit="contain" width={10} mb="8px" src={people} alt="people"/>
            </Box>
            <Box width="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Box width="60%">
                  <Progress value={percentage} color='#25cf03' />
                </Box>
                <Box width="30%">
                  <Text fontSize="10px">{props.submittedContactCount}/{props.contactLimit }</Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        </Link>
       
      </Box>
    );
  })
}
        {/* </Link> */}
     
       </Flex>
        </Box>
        </>
        </Box>
       
        <Box marginTop="20px" w="100%" p="2px" >
            {
                data?.data?.length >0?  ( <Flex   w="100%" justifyContent="space-between" alignItems="center">
                <Box >
                <Flex alignItems="center " >
                <Box color="#a409d3" fontSize={12} fontWeight="600" >Created cards</Box>  <Box onClick={download} textDecoration="underline" m={2} fontSize={10} color="#25cf03">Download History</Box>
                </Flex>
                </Box>
              
               
                <Box fontSize={12} color="#25cf03" onClick={createdCards}>See all</Box>
            </Flex>):null
            }
     
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between">
        {/* where the box is */}
        {
  data?.data?.slice(0, 2).map((props:any) => {
    const percentage = (props.submittedContactCount / props.contactLimit) * 100;

    return (
      <Box boxShadow="md" width="45%" borderRadius="10px">
        <Link href={`/dashboard/creator/single-card-detail/${props.id}`} >
        <Box width="100%" padding="5px" ml={2}>
          <Flex flexDirection="column">
            <Box cursor="pointer">
              <RiFolderOpenLine color='#25cf03' fontSize={20} />
            </Box>
            <Box fontSize="12px" fontWeight="600" mt="10px">{props.name}</Box>
            <Box fontSize="12px" color="#00000072">{props.title}</Box>
            <Box marginTop="10px"> 
              <Image objectFit="contain" width={10} mb="8px" src={people} alt="people"/>
            </Box>
            <Box width="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Box width="60%">
                  <Progress value={percentage} color='#25cf03' />
                </Box>
                <Box width="30%">
                  <Text fontSize="10px">{props.submittedContactCount}/{props.contactLimit }</Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        </Link>
       
      </Box>
    );
  })
}
    
      
       </Flex>
        </Box>
     
        </Box>
        <Box marginTop="20px" w="100%" p="2px">
            {
                expired?.data?.length>0?   <Flex   w="100%" justifyContent="space-between" alignItems="center">
                <Box color="#f43500" fontSize={12} fontWeight="600" >Expired cards</Box>
                <Box fontSize={12} color="#25cf03" onClick={expiredCards}>See all</Box>
            </Flex>:null
            }
     
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between">
        {/* where the box is */}
       
    {/* where the box is */}
       {
  expired?.data?.slice(0, 2).map((props:any) => {
    const percentage = (props.submittedContactCount / props.contactLimit) * 100;
    return (
      <Box boxShadow="md" width="45%" borderRadius="10px">
        <Link href={`/dashboard/creator/renew-card/${props.id}`} >
        <Box width="100%" padding="5px" ml={2}>
          <Flex flexDirection="column">
            <Box cursor="pointer">
              <RiFolderOpenLine color='#25cf03' fontSize={20} />
            </Box>
            <Box fontSize="12px" fontWeight="600" mt="10px">{props.name}</Box>
            <Box fontSize="12px" color="#00000072">{props.title}</Box>
            <Box marginTop="10px"> 
              <Image objectFit="contain" width={10} mb="8px" src={people} alt="people"/>
            </Box>
            <Box width="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Box width="60%">
                  <Progress value={percentage} color='#25cf03' />
                </Box>
                <Box width="30%">
                  <Text fontSize="10px">{props.submittedContactCount}/{props.contactLimit }</Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        </Link>
       
      </Box>
    );
  })
} 
       </Flex>
        </Box>
     
        </Box>
    </Flex>
 </Box>
 </>
  )
}

export default BecomeAcreator