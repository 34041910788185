import { Box, Flex } from "@chakra-ui/react";

import styles from "../modal.module.scss";

interface props {
  children: React.ReactNode;
  onclose: () => void;
}
const TopRightModal = ({ children, onclose }: props) => {
  return (
    <Flex   onClick={onclose}
      className={`${styles.modal_overlay}`}
     
      justifyContent="center"
    >
      <Box
        sx={{
          "@media screen and (min-width: 768px)": {
            paddingLeft: "142px",
            // maxWidth: "400px",
          },
        }}
      >
        <Box
          padding="20px"
          backgroundColor="#fefffe"
          width="180px"
          borderRadius="20px"
          height="220px"
          mt={12}
          marginLeft={24}
          // marginX={5}
        >
       
          <Box textAlign="center">{children}</Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default TopRightModal;
