// export const walletReducer = () => {};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { walletStateInterface } from "../../types/auth/authInterfaces";

export const initialState: walletStateInterface = {
  balance: 0.0,
  totalCredit: 0.0,
  totalDebit: 0.0,
  walletTag: "",
  walletId: "",
};

const walletReducer = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    updateWallet: (
      state = initialState,
      action: PayloadAction<walletStateInterface>
    ) => {
      return (state = { ...action.payload });
    },
    updateBalance: (state = initialState, action: PayloadAction<number>) => {
      state.balance = action.payload;
    },
  },
});

export const { updateWallet, updateBalance } = walletReducer.actions;
export default walletReducer.reducer;
