import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { emailInterface } from '../../../types/auth/authInterfaces';
import CustomInput from '../../../components/core/input/CustomInput';
import useSendBulkEmail from '../../../hooks/bulkHooks/useSendBulkEmails';

const DispatchEmail = () => {
  const emails = useSelector((state: RootState) => state.emails);
  console.log(emails.email)

  const {sendBulkEmail, loading} = useSendBulkEmail()
const allEmails = emails.email.map((props:any)=>{
  return props.email
})
console.log(allEmails)
const initialFormValues = {
  email: allEmails,
  message: "",
  subject: "",
  amount: 0,
};

const price =Math.ceil( 2.01*allEmails?.length)
  const [formValues, setFormValues] = useState<any>({
email:allEmails,
message:"",
subject:"",
amount:price

  });
  console.log(formValues.amount)
  console.log(formValues)

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues:any) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSendEmail = async()=>{
    try {

  await sendBulkEmail(formValues).then((res)=>{
  setFormValues(initialFormValues)
  })
      
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-messages/fetch-emails" text="Send Email" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your Email message
    </Box>
    <Box mt={4} fontWeight={600}>
Emails    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2" p={2} color="gray" fontSize={12}>
    {
    emails.email?.map((props: emailInterface, index: number) => (
      <React.Fragment key={index}>
        {props.email}{index !== emails.email.length - 1 && ','}
      </React.Fragment>
    ))
  }
  <Box bg="#00000070" textAlign="center" p="3px" w="80px" borderRadius={10} color="#fff">{emails.email.length} {" "} {"emails"}</Box>
    </Box>
    <Box  mt={4} fontWeight={600}>Subject</Box>
    <CustomInput onChange={handleChange}
    name='subject'
    value={formValues.subject}
    />

    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea name='message' value={formValues.message} onChange={handleChange} h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
   {formValues.message?.length}
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee :</Box>
            <Box fontWeight="bold">₦{formValues?.amount}{".00"}</Box>
        </Flex>
    </Box>
    <Box>
    <Button onClick={handleSendEmail} variant="primary" size="normal">
   {
    loading?"Sending please wait...":"Send Email"
   }
    </Button>
    </Box>

</Box>
</>
  )
}

export default DispatchEmail 