import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useFetchBulkItems
 = (url:any) => {
  const [data, setData] = useState<any>([]);
  const user = useSelector((state: RootState) => state.user);
//   console.log(user)
  useEffect(() => {
    const fetched = async () => {
      try {
        await axiosBaseInstance
          .get(url, {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            setData(res.data);
          });
      } catch (error: any) {
        console.log(error);
      }
    };

    fetched();
  }, [url, user.authToken]);

  return { data };
};

export default useFetchBulkItems
;
