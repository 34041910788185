import React, { useState } from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';
import { useNavigate } from 'react-router-dom';

import useGetPromotedCards from '../../../hooks/creator-hooks/useGetPromotedCards';

const AllPromotedCards = () => {
  
    const {data:promoted} = useGetPromotedCards()
    // console.log(promoted)
   
    const [isModal, setIsModal] = useState(false);
    const value = 35;
    const threshold = 100;

    const hideSubmitContactModal = () => {
        setIsModal(false);
      };
      const openSubmitContactModal = () => {
        setIsModal(true);
      };

const navigate= useNavigate()

  return (
 <>
 {
    isModal && 

 <CreatorModal hideModal={hideSubmitContactModal}/>

 }
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/creator/become-a-creator" text="Promoted cards" />
<Box w="30px" h="30px" borderRadius="100%" cursor="pointer" onClick={openSubmitContactModal}>

    <Flex bg="#ecffe9f9" justifyContent="center" alignItems="center"  borderRadius="100%" h="100%">
    <GoPlus color="#25cf03" />


    </Flex>
</Box>
</Flex> 
    </Box>
 {/* </PageHeader> */}

 <Box>
    <Flex flexDirection="column">
        <Box w="100%" p="2px">
        
            
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between"  flexWrap="wrap">
        {/* where the box is */}
        
        {/* <Link href='/dashboard/creator/single-card-form/:id'> */}
        {
  promoted?.data?.map((props:any) => {
    const percentage = (props.submittedContactCount / props.contactLimit) * 100;
    return (
      <Box boxShadow="md" width="45%" borderRadius="10px">
        <Link href={`/dashboard/creator/renew-card/${props.id}`} >
        <Box width="100%" padding="5px" ml={2}>
          <Flex flexDirection="column">
            <Box cursor="pointer">
              <RiFolderOpenLine color='#25cf03' fontSize={20} />
            </Box>
            <Box fontSize="12px" fontWeight="600" mt="10px">{props.name}</Box>
            <Box fontSize="12px" color="#00000072">{props.title}</Box>
            <Box marginTop="10px"> 
              <Image objectFit="contain" width={10} mb="8px" src={people} alt="people"/>
            </Box>
            <Box width="100%">
              <Flex justifyContent="space-between" alignItems="center">
                <Box width="60%">
                  <Progress value={percentage} color='#25cf03' />
                </Box>
                <Box width="30%">
                  <Text fontSize="10px">{props.submittedContactCount}/{props.contactLimit }</Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        </Link>
       
      </Box>
    );
  })
} 
 
        {/* </Link> */}
     
       </Flex>
        </Box>
     
        </Box>
       
        
       
    </Flex>
 </Box>
 </>
  )
}

export default AllPromotedCards