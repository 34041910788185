import React, { useState } from "react";
import EnterAmount from "./components/EnterAmount";
import Success from "./components/Success";
import CheckOut from "./components/CheckOut";
import useFundWallet from "../../../hooks/wallet-hooks/fundwallet";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Box } from "@chakra-ui/react";
import { Header, OtherHeader } from "../../../components";
const TopupWallet = () => {
  const [amounts, setAmounts] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [isAmount, setIsAmount] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);
  // const walletId = wallet.walletId
  // console.log(wallet);
  // const [formData, setFormData] = useState({ amount: 0, walletId:wallet.walletId });
  const { fundWallet } = useFundWallet();
  const creditWallet = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await fundWallet({ amount: amounts, walletId: wallet.walletId });
      setIsSuccess(true);
      setIsAmount(true);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSuccess = () => {};

  return (
    <>
      <OtherHeader />

      {/* {isAmount ? ( */}
      <CheckOut />
      {/* ) : //  <Success amount={amounts} /> */}
      {/* isAmount ? (
        <CheckOut />
      ) : (
        <EnterAmount
          text={loading ? "Processing..." : "Continue"}
          amount={amounts}
          setAmount={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAmounts(Number(e.target.value));
          }}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            creditWallet(e);
          }}
        />
      )} */}
    </>
  );
};

export default TopupWallet;
