import { OtherHeader, PageHeader } from "../../../components";
import { useState } from "react";
import PremiumContact from "./PremiumContact";
import { Flex , Text} from "@chakra-ui/react";
import { Link } from "react-router-dom";
const DownloadPremiumContact = () => {
  const [isConfirmLogout, setIsConfirmLogout] = useState(false);
  const controlLogout = () => {
    setIsConfirmLogout(!isConfirmLogout);
  };
  return (
    <>
      <OtherHeader />
{/* 
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      > */}
       
     
      {/* </Flex> */}

      <PremiumContact onclick={controlLogout} />
    </>
  );
};

export default DownloadPremiumContact;
