import { Box, Flex, Text } from "@chakra-ui/react";
import info from "../../../../assets/svgs/info.svg";

interface props {
  price: number;
}
const Price = ({ price }: props) => {
  return (
    <Box borderTop="1px" borderColor="#D3D3D3" paddingTop="18px">
      <Flex alignItems="center">
        <Text color="#030202" fontSize="14px" marginRight="5px">
          Total Fee:
        </Text>
        <Text color="#393939" fontSize="16px" fontWeight="600">
          {price}
        </Text>
      </Flex>

      <Flex color="grey.main" marginTop="10px" alignItems="center">
        <img
          src={info}
          alt="info"
          style={{ width: "30px", marginRight: "5px" }}
        />
        <Text fontSize="13px" marginBottom="10px">
          The fee for the payment of the premium contact would be deducted from
          your wallet.
        </Text>
      </Flex>
    </Box>
  );
};

export default Price;
