import React, { useState } from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';

import useGetCreatedCard from '../../../hooks/creator-hooks/useGetCreatedCards';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import ListModal from '../Home/components/ListModal';
import { TiDeleteOutline } from 'react-icons/ti';
import { LuRotateCw } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';


const RejcetedWhatsappMessage = () => {
const navigate = useNavigate()

    const resendMessage = ()=>{
        navigate("/dashboard/bulk/bulk-whatsapp-messages/sent/resend/:id")
    }
  
    const {data:created} = useGetCreatedCard()
    // console.log(created)
   
    const [isModal, setIsModal] = useState(false);
    
    const threshold = 100;
  
    const hideSubmitContactModal = () => {
        setIsModal(false);
      };
      const openSubmitContactModal = () => {
    
        setIsModal(true);
      };


  return (
 <Box w="100%" height="100%">
  <Flex flexDirection="column">
  {
    isModal && 

 <ListModal hideModal={hideSubmitContactModal} onclose={hideSubmitContactModal}>
     <Box onClick={resendMessage} mb={2} fontWeight={600}>
  <Flex alignItems="center" height="100%">
  <LuRotateCw />
<Box ml={3}>
Resend
</Box>
  </Flex>

 </Box>
     <Box mb={2} fontWeight={600}>
     <Flex alignItems="center" height="100%">
     <TiDeleteOutline />
<Box ml={3}>Delete
</Box>
  </Flex>
     </Box>

    </ListModal>
 }
     
 <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-whatsapp-message" text="Rejected Messages" />

</Flex> 
    </Box>


 <Box height="100%" w="100%">
    <Flex  height="100%" flexDirection="column" width="100%" >
        <Box w="100%" p="2px">
     
        <Box  width="100%" height="100%" mt="20px" >
        <Flex justifyContent="space-between" flexWrap="wrap">
      
 <Box width="100%" mb={4}>
<Link href='/dashboard/bulk/bulk-whatsapp-messages/sent/resend/:id'>
<Flex justifyContent="space-between" alignItems="center">
        <Box w=" 70px" h="70px" bg="#d6f2d7" textAlign="center" borderRadius="100% " fontWeight={600} fontSize={20} color="green"> 
            <Flex justifyContent="center" alignItems="center" h="100%">
A
            </Flex> 
            </Box>
        <Box width="70%">
            <Box w="100%">
                <Flex justifyContent="space-between">
                <Box>Hi  i sell hot milky donuts oo</Box>
<Box ml={4}>

<HiOutlineDotsVertical
              onClick={(e) => {
                e.preventDefault(); 
                openSubmitContactModal();
              }}
              fontSize={20}
            />

</Box>
                </Flex>

            </Box>
            <Box w="100%" mt={4}>
                <Flex justifyContent="space-between">
                <Box fontSize={14} color="grey">20/mar/23</Box>

                </Flex>

            </Box>
        </Box>
    </Flex> 
</Link>
   
 </Box>

     
       </Flex>
        </Box>
     
        </Box>
       
        
       
    </Flex>
 </Box>
  </Flex>

 </Box>
  )
}

export default RejcetedWhatsappMessage