import { Box, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
interface props {
  title: string;
  children?: string | ReactNode;
}

const TransactionRow = ({ title, children }: props) => {
  return (
    <Flex
      justifyContent="space-between"
      padding="30px 0px"
      borderBottom="0.3px solid #D9D9D9"
    >
      <Text color="grey.dark">{title}</Text>
      <Text color="black.main" fontWeight="500">
        {children}
      </Text>
    </Flex>
  );
};

export default TransactionRow;
