import React, { useEffect }  from "react";

import { Box, Flex, Text, Toast } from "@chakra-ui/react";
import TopRightModal from "../../../../components/Modals/MiddleModal/TopRIghtModal";
import { Button } from "../../../../components";
import { TbEdit } from "react-icons/tb";
import { HiOutlineDownload } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import copy from "clipboard-copy"
import useGetCardById from "../../../../hooks/creator-hooks/useGetCardById";
import { IoCopyOutline } from "react-icons/io5";
import { FaDiagramProject } from "react-icons/fa6";
import useDownloadContact from "../../../../hooks/creator-hooks/useDownloadContact";
import TopRightBulkModal from "../../../../components/Modals/MiddleModal/TopRightBulkModal";
import TopRightListModal from "../../../../components/Modals/MiddleModal/TopRIghtListModal";
import { LuRotateCw } from "react-icons/lu";
import { TiDeleteOutline } from "react-icons/ti";


interface props {
  onclose: () => void;
hideModal:any
onclick?:()=>void
children:any
}
const ListModal = ({ onclose,hideModal, children,  onclick }: props) => {
   
   
  




    const navigate = useNavigate()


 
  return (
    <TopRightListModal onclose={onclose}>
{children}

     
    </TopRightListModal>
  );
};

export default ListModal;
