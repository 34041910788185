import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormikValues } from "formik";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";

const useCreateWhatsAppTv = () => {
  const user = useSelector((state: RootState) => state.user);

  const createWhatsAppTv = async (values: FormikValues) => {
    return await axiosBaseInstance
      .post("/WhatsappTv/register", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        showNotification({
          message:
            err?.response?.data?.message ||
            err?.message ||
            "An error occurred. Please try again",
          color: "yellow",
        });
        return err;
      });
  };

  return { createWhatsAppTv };
};

export default useCreateWhatsAppTv;
