import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../../redux/store";

const RequireAuth = () => {
  const user = useSelector((state: RootState) => state.user);

  return user?.authToken === "" ? <Navigate to="/signin" /> : <Outlet />;
};

export default RequireAuth;
