import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useFetchTvReviews from "../../../hooks/whatsapptv-hooks/useFetchTvReviews";
import { reviewInterface } from "../../../types/components/componentInterfaces";
import { Box } from "@chakra-ui/react";
import {
  NoReviewNotice,
  OtherHeader,
  PageHeader,
  Review,
} from "../../../components";
import { Rating, Loader } from "@mantine/core";

const Reviews = () => {
  const { whatsappTvId } = useParams();
  const [reviews, setReviews] = useState<Array<reviewInterface>>([]);
  const [isReview, setIsReview] = useState(false);
  const { getReviews, isLoading } = useFetchTvReviews();
  const navigate = useNavigate();

  useEffect(() => {
    if (whatsappTvId) {
      getReviews(parseInt(whatsappTvId)).then((data) => {
        if (data.data.length > 0) {
          console.log(data);
          setReviews(data.data);
          setIsReview(true);
        }
      });
    }
  }, [whatsappTvId]);
  return (
    <>
      <OtherHeader />
      <PageHeader
        text="Reviews"
        link=""
        onClick={() => {
          navigate(-1);
        }}
      />

      {isLoading ? (
        <Loader style={{ margin: "100px auto" }} size="lg" color="#4cb051" />
      ) : isReview ? (
        <Box>
          {reviews?.map((review) => (
            <Review
              picture={review.picture}
              name={review.name}
              review={review.review}
              createdDate={`${review.createdDate}`}
            />
          ))}
        </Box>
      ) : (
        <NoReviewNotice />
      )}
    </>
  );
};

export default Reviews;
