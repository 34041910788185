import { useEffect, useState } from "react";
import { Box, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import Typography from "../../../components/core/typo/Typography";
import CustomInput from "../../../components/core/input/CustomInput";
import Button from "../../../components/core/Button/Button";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../../../components";
import { Formik, FormikValues } from "formik";
import { profileSchema } from "../utils/validationSchema";
import { genders } from "./utils/values";
import useUpdateProfile from "../../../hooks/user-hooks/useUpdateProfile";
import UploadImage from "../../../components/UploadImage/UploadImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { dataInterface } from "../../../types/components/componentInterfaces";
import useFetchStates from "../../../hooks/data-hooks/useFetchStates";
import useFetchBusinessTypes from "../../../hooks/data-hooks/useFetchBusinessTypes";
import { countryCodes } from "../../../config/data";

const ProfileSetup = () => {
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);
  const navigate = useNavigate();
  const { updateProfile, isLoading } = useUpdateProfile();
  const { fetchStates } = useFetchStates();
  const { fetchBusinessTypes } = useFetchBusinessTypes();
  const user = useSelector((state: RootState) => state.user);
  const [localStates, setLocalStates] = useState<Array<dataInterface>>([]);
  const [businessTypes, setBusinessTypes] = useState<Array<dataInterface>>([]);

  const profileInitialValues = {
    profileImage: "",
    fullName: "",
    address: "",
    phoneNumber: user.data.phoneNumber,
    countryCode: "",
    email: user.data.email,
    dob: "",
    stateId: 0,
    businessId: 0,
    gender: "Male",
    facebookLink: "",
    instagramLink: "",
    createdDate: new Date(),
  };

  const handleProfileUpdate = (values: FormikValues) => {
    updateProfile(values).then((res) => {
      if (res.data.status === true) {
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    fetchStates().then((res) => {
      setLocalStates(res.data.data);
    });

    fetchBusinessTypes().then((res) => {
      setBusinessTypes(res.data.data);
    });
  }, []);
  return (
    <>
      <Formik
        initialValues={profileInitialValues}
        validationSchema={profileSchema}
        onSubmit={(values) => {
          handleProfileUpdate(values);
        }}
      >
        {({ handleSubmit, handleChange, errors, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <Typography fontSize="32px">Set up your Profile</Typography>

            <Box fontSize="14px" w="100%" marginBottom="20px">
              Complete your profile and get full experience
            </Box>
            <UploadImage />

            <Box w="100%">
              <CustomInput
                width="auto"
                placeholder="Enter your full name here"
                label="Your Name"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.fullName ? errors.fullName : null
                }
              />

              <Box w="100%">
                <Box marginBottom={2}>
                  <Typography>Phone number</Typography>
                </Box>
                <InputGroup
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <div>
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "50px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "-8px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                  </div>

                  <CustomInput
                    width="auto"
                    placeholder="Enter your phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    disabled
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.phoneNumber
                        ? errors.phoneNumber
                        : null
                    }
                  />
                </InputGroup>
              </Box>

              <CustomInput
                width="auto"
                placeholder="Enter your address"
                label="Your Address"
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.address ? errors.address : null
                }
              />

              <CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="Date of birth"
                name="dob"
                type="date"
                value={values.dob}
                onChange={handleChange}
                error={isValidatingSchema && errors.dob ? errors.dob : null}
              />

              <CustomInput
                width="auto"
                placeholder="Enter your email address"
                label="Email Address"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                disabled
                error={isValidatingSchema && errors.email ? errors.email : null}
              />

              <SelectInput
                values={localStates}
                label="State"
                id="stateId"
                name="stateId"
                placeholder="Select state"
                value={values.stateId}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.stateId ? errors.stateId : null
                }
              />
              <SelectInput
                values={businessTypes}
                label="Type of Business"
                id="businessId"
                name="businessId"
                placeholder="Select business"
                value={values.businessId}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.businessId
                    ? errors.businessId
                    : null
                }
              />

              <SelectInput
                values={genders}
                label="Gender"
                id="gender"
                name="gender"
                placeholder="Select your gender"
                value={values.gender}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.gender ? errors.gender : null
                }
              />

              <CustomInput
                width="auto"
                placeholder="Enter your instagram link"
                label="Instagram Link"
                type="text"
                name="instagramLink"
                value={values.instagramLink}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.instagramLink
                    ? errors.instagramLink
                    : null
                }
              />

              <CustomInput
                width="auto"
                placeholder="Enter your Facebook Link"
                label="Facebook Link"
                type="text"
                name="facebookLink"
                value={values.facebookLink}
                onChange={handleChange}
                error={
                  isValidatingSchema && errors.facebookLink
                    ? errors.facebookLink
                    : null
                }
              />
            </Box>

            <Box w="100%" marginTop={4} marginBottom={10}>
              <Button
                variant="primary"
                type="submit"
                size="normal"
                style={{ marginBottom: "12px" }}
              >
                {isLoading ? "Please wait..." : "Save"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ProfileSetup;
