import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import ErrorBoundary from "./components/ErrorBoundary";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ScrollToTop from "./hooks/useScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// 2. Extend the theme to include custom colors, fonts, etc
const queryClient = new QueryClient();

const colors = {
  yellow: {
    100: "rgba(251, 187, 0, 1)",
  },
  orange: {
    main: "rgba(254, 255, 223, 1)",
  },
  purple: {
    830: "rgba(231, 222, 247, 0.83)",
  },
  green: {
    80: "rgba(76, 176, 81, 0.08)",
    100: "rgba(76, 176, 81, 1)",
    150: "rgba(76, 176, 81, 0.15)",
    200: " rgba(76, 176, 81, 0.2)",
  },
  black: {
    main: "rgba(3, 2, 2, 1)",
    light: "rgba(45, 45, 45, 1)",
  },

  grey: {
    main: "rgba(136, 136, 136, 1)",
    medium: "rgba(253, 255, 253, 1)",
    dark: "rgba(103, 102, 102, 1)",
    light: "rgba(217, 217, 217, 1)",
    15: "rgba(217, 217, 217, 0.15)",
  },

  white: {
    100: "rgba(253, 255, 253, 1)",
    light: " rgba(226, 224, 224, 1)",
  },
  blue: {
    main: "rgba(221, 240, 255, 1)",
  },
  red: {
    main: "rgba(255, 235, 221, 1)",
  },
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const theme = extendTheme({ colors });

const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId="31983004061-irj6lveq8uj6f1lh2bjoi5imqu86a1vn.apps.googleusercontent.com">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider theme={theme}>
              <MantineProvider>
                <Notifications position="top-center" />

                <BrowserRouter>
                  <ScrollToTop />
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </BrowserRouter>
              </MantineProvider>
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
