import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";




const useDeleteMultipleContact = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const deleteContact = async (contactIds: number[]) => {
    setLoading(true);
    return await axiosBaseInstance
      .post(
        `/Creator/Delete-SelectedCardContacts`,
        
        contactIds
        ,
        {
          headers: {
            Authorization: `Bearer ${user.authToken}`
          }
        }
      )
      .then((res) => {
        setLoading(false);
        window.location.reload()
        return res.data;
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  return { deleteContact , loading};
};

export default useDeleteMultipleContact;
