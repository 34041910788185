import { Avatar, Box, Flex, Input } from "@chakra-ui/react";
import { useState } from "react";
import user from "../../assets/svgs/user.svg";

import { useFormikContext } from "formik";
import { FaCamera } from "react-icons/fa";

interface props {
  value?: string;
}
const UploadImage = ({ value }: props) => {
  const { setFieldValue, errors } = useFormikContext<{
    profileImage: string;
  }>();
  const [mediaFile, setMediaFile] = useState(value ? value : "");
  const [displayMedia, setDisplayMedia] = useState(value ? value : "");

  const handleImage = (e: any) => {
    const file = e.target.files[0];
    setMediaFile(file);
    const save = URL.createObjectURL(file);
    setDisplayMedia(save);
    var reader = new FileReader();
    reader.onloadend = function (event: any) {
      const dataUrl = event.target.result;
      const base64 = dataUrl.split(",")[1];

      setFieldValue("profileImage", dataUrl);
      return base64;
    };

    reader.readAsDataURL(file);
  };
  return (
    <Box
      position="relative"
      width="fit-content"
      margin="auto"
      marginBottom="20px"
    >
      {mediaFile ? (
        <img
          src={displayMedia}
          alt="user profile"
          style={{
            overflow: "hidden",
            width: "120px",
            height: "120px",
            borderRadius: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <Avatar
          size="2xl"
          bg=" rgba(76, 176, 81, 0.1)"
          width="140px"
          height="140px"
          borderRadius="full"
          paddingTop="20px"
          icon={
            <img
              src={user}
              style={{ width: "130px", height: "130px" }}
              alt="camera"
            />
          }
        />
      )}

      <Input
        type="file"
        id="photo"
        style={{ display: "none" }}
        name="profileImage"
        onChange={handleImage}
      />
      <label htmlFor="photo">
        <Flex
          justifyContent="center"
          alignItems="center"
          bg="green.100"
          width="30px"
          height="30px"
          borderRadius="100%"
          position="absolute"
          bottom={1}
          right={2}
        >
          <FaCamera fontSize="20px" color="#fff" cursor="pointer" />
        </Flex>
      </label>
      {errors.profileImage && (
        <div className="error">{errors.profileImage}</div>
      )}
    </Box>
  );
};

export default UploadImage;
