import { useDispatch } from "react-redux";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { authInterface } from "../../types/auth/authInterfaces";
// import { FormikValues } from "formik";
import { useState } from "react";

import { addUser } from "../../redux/reducers/userReducer";
import { updateWallet } from "../../redux/reducers/walletReducer";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

export default function useLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [results, setResults] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState("");

  const login = async (data: authInterface) => {
    setLoadings(true);

    return await axiosBaseInstance
      .post("/Onboarding/User-Login", data)
      .then((res) => {
        setLoadings(false);
        setResults(res.data);
        // console.log(res.data);
        if (res.data.status === true) {
          // console.log(res.data.data)
          dispatch(addUser(res.data.data));
          dispatch(updateWallet(res.data.data.accountDetails.walletData));

          const hasVisited = localStorage.getItem("hasVisited");

          const userData = res.data.data.accountDetails;
          if (userData.profileImage === "" || userData.name === "") {
            navigate("/profile-setup");
          } else {
            navigate("/dashboard");
          }
        } else {
          showNotification({
            message: res.data.Message || "an error occurred. Try again",
            color: "yellow",
          });
        }

        return res.data.data;
      })
      .catch((error: any) => {
        setLoadings(false);
        setErrors(error.message);
        console.log(error);
        showNotification({
          message: error?.message || "an error occurred. Try again",
          color: "yellow",
        });
      });
  };

  return { loadings, results, errors, login };
}
