import React from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea } from '@chakra-ui/react'
import CustomInput from '../../../components/core/input/CustomInput'
import { useParams } from 'react-router-dom'
import useResendBulkWhatsappMessage from '../../../hooks/bulkHooks/useResendBulkWhatsappMessage'
import useGetSingleWhatsappMessage from '../../../hooks/bulkHooks/useGetSingleWhatsappMessage'

const ResendWhatsappMessage = () => {
  const {id} =useParams()
  
  const {SendSingleMsg, loading} = useResendBulkWhatsappMessage()
  const {data} = useGetSingleWhatsappMessage(id)
  const sorted = data?.data?.recipients
const newSort = sorted?.split(",")
console.log(newSort)
const price =Math.ceil( 2.01*newSort?.length)

console.log(data?.data)

 





  const handleSendMsg = async()=>{
    try {

  await SendSingleMsg({  recipients:data?.data?.recipients,
    message:data?.data?.message,
    title:data?.data?.title,
    amount:price}).then((res)=>{
  
  })
      
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-whatsapp-message" text="Send Message" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your SMS message
    </Box>
    <Box mt={3}>

        <Box fontWeight={600}>Title</Box>
        <CustomInput placeholder='Enter title of message'/>
    </Box>
    <Box mt={4} fontWeight={600}>
        Contacts
    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
    {
data?.data?.recipients?<React.Fragment >
{data?.data?.recipients}
</React.Fragment>:null
 }
    </Box>
    <Box  mt={4} fontWeight={600}>Title</Box>
    <CustomInput
    name='title'
    value={data?.data?.title || null}
    />
    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
{data?.data?.message?.length || null}
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee :</Box>
            <Box fontWeight="bold">₦{price|| null}{".00"}</Box>
        </Flex>
    </Box>
    <Box>
    <Button onClick={handleSendMsg}  variant="primary" size="normal">
   {
    loading?"Sending please wait...":"Resend Message"
   }
    </Button>
    </Box>

</Box>
</>
  )
}

export default ResendWhatsappMessage 