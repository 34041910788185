import React, { useState } from 'react'
import {  OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Text } from '@chakra-ui/react'
import { MdOutlineCancel } from "react-icons/md";
import { Checkbox } from '@mantine/core';
import OptionNav from '../Home/components/OptionNav';
import { HiOutlineDotsVertical } from "react-icons/hi";
// import { TbRuler2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById';
import { useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react'
import useGetCardContacts from '../../../hooks/creator-hooks/useGetCardContacts';
import moment from 'moment';
import useDeleteMultipleContact from '../../../hooks/creator-hooks/useDeleteMultipleContact';
import { extendTheme } from "@chakra-ui/react";
import "./range.css"

const CardDetails = () => {
  const breakpoints = {
    base: "0px", 
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  };
  const theme = extendTheme({ breakpoints });
    const toast = useToast()
    const {id} = useParams()
    // console.log(id)
    const {data} = useGetCardById(id)
 
    // console.log(data)
    const [success, setSuccess] = useState(false)
    const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
    const Navigate= useNavigate()
    const openModal = ()=>{
        // navigate("/dashboard/creator/become-a-creator")
        setSuccess(true)
      }
      const closeModal = ()=>{
        // navigate("/dashboard/creator/become-a-creator")
        setSuccess(false)
      }
      const allcontacts=()=>{
        Navigate(`/dashboard/creator/all-contacts/${id}`)
      }
     
   
      const {data:contacts} =useGetCardContacts(data?.cardLink)
      // console.log(contacts)
      const { deleteContact, loading } = useDeleteMultipleContact();

      const handleContactSelection = (contactId: string) => {
        setSelectedContacts(prevSelectedContacts => {
          if (prevSelectedContacts.includes(contactId)) {
            return prevSelectedContacts.filter(id => id !== contactId);
          } else {
            return [...prevSelectedContacts, contactId];
          }
        });
      };
      // console.log(selectedContacts);

      const handleDeleteSelected =async () => {
        // console.log(selectedContacts);
        await deleteContact(selectedContacts)
        .then((res) => {
       
          // console.log(res);
        })
        .catch((err) => {
         
          console.error(err);
        });
       
    
        setSelectedContacts([]);
      };
  return (
    <>

 
    
       <OtherHeader />
       {success && <OptionNav onclick={handleDeleteSelected} cardId={id} onclose={closeModal}/>}
       <Box zIndex={100}>

       <Flex alignItems="center">
        <PageHeader text="Card Details" link="/dashboard/creator/become-a-creator" />
        <Box w="30px" h="30px" borderRadius="100%" cursor="pointer">

<Box height="100%">
    <Flex justifyContent="center" alignItems="center">
    <HiOutlineDotsVertical onClick={openModal} fontSize={20} />

    </Flex>


</Box>

 

</Box>
        </Flex>
       </Box>
 

 <Box position="relative" width="100%">
 <Flex flexDirection="column">
 
 <Box width="100%" fontWeight="600" fontSize="12px" >
                 <Flex justifyContent="space-between ">
                     <Box>{data?.name}</Box>
                     <Box>{data?.title}</Box>
                 </Flex>
                 <Box mt={2}>
                 <hr/>
 
                 </Box>
             </Box>
             <Box width="100%" fontSize="12px"textAlign="left" >
                 <Flex justifyContent="center">
                 <Box width="80%" marginTop={12} textAlign="center">
                {data.description}
                 </Box>
               
                 </Flex>
          
             </Box>
             <Box mt={12}  width="100%" fontWeight="600" fontSize="12px" >
     <Flex justifyContent="space-between">
     <Box>List of Contacts</Box>
            
            <Box color="#0df109" onClick={allcontacts}>See all</Box>
           
     </Flex>
                    
             </Box>
             <Box height={{ base: "530px", md: "400px" }} w={{ base: "100%", md: "430px" }}overflow="scroll" className="scrollable-container">
             {
                contacts?.data?.map((props:any)=>(
                    <Box onClick={((e:any)=>{
                      Navigate(`/dashboard/creator/delete-contact/${props.id}`)
                })}  width="100%" mt={4} borderBottom="1px solid #3736363f" p="10px">
                    <Flex justifyContent="space-between" alignItems="center" fontSize={12}>
                        <Box h="100%">
                            <Flex alignItems="center" h="100%">
                                <Box>
                                    <Flex alignItems="center">
                                    <Checkbox
  size="sm"
  onClick={(e) => {
    e.stopPropagation(); 
    handleContactSelection(props.id);
  }}
  color="green"
/>
     <Box textAlign="center" ml={2} width="30px" height="30px" borderRadius="100%" bg="#b2dcbbb4">
        <Flex justifyContent="center" h="100%" alignItems="center">
        <Text fontWeight="600">{props.name.charAt(0).toUpperCase()}</Text>
    
        </Flex>
     </Box>
                                        </Flex>  
        </Box>
                                <Box ml={2}>
                                    <Box fontWeight="600" mb={2}>{props.name}</Box>
                                    <Box>{props.whatsAppNumber}</Box>
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
    
                            <Box color="#000000ad" mb={2}> Joined {moment().endOf(props.createdDate).fromNow()}</Box>
                            <Box float="right" mt={2} >
                                <MdOutlineCancel color="#f41a1a" />
                            </Box>
                            
                        </Box>
                    </Flex>
                 </Box>
             
                ))
             }
           

             </Box>
           
       
 </Flex>
 

 
 </Box>
    </>
  )
}

export default CardDetails