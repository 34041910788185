import { Text } from "@chakra-ui/react";


 
type TypoProps = {
  fontSize?: string,
  weight?: number,
  children: any,
  color?: string,
  marginLeft?:string,
  marginRight?:string,
  borderBottom?:string
}


export default function Typography (props: TypoProps) {
  const { fontSize, children, weight, color, marginLeft, marginRight,borderBottom} = props;

  return (
    <Text 
      fontSize={fontSize ? fontSize : 'md'}
      sx={{ 
        fontWeight: weight ? weight : 500,
        color: color ? color : '#000',
        marginLeft:marginLeft? marginLeft:"0px",
        marginRight:marginRight? marginRight:"0px",
        borderBottom:borderBottom? borderBottom:""
      }}
    >
      { children }
    </Text>
  )
}
