import React, { useEffect, useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Text, Textarea } from '@chakra-ui/react'
import BulkMessageModal from '../Home/components/BulkMessageModal';
import { Checkbox } from '@mantine/core';
import useFetchBulkItems from '../../../hooks/bulkHooks/useFetchBulkItems';
import { useDispatch } from 'react-redux';
import { updatePhoneNumber } from '../../../redux/reducers/phoneNumbers';
import { useNavigate } from 'react-router-dom';
import useSendBulkMessages from '../../../hooks/bulkHooks/useSendBulkMessages';
import CustomInput from '../../../components/core/input/CustomInput';
import { phoneNumberInterface } from '../../../types/auth/authInterfaces';
const UploadedNumbers = () => {
 

  const [send, setSend] = useState(false)
    const Navigate=useNavigate()   
     const [phoneNumber, setPhoneNumber] = useState<string[]>([]);
     console.log(phoneNumber)
 const [selectAll, setSelectAll] = useState(false);


const handleSelect = (phoneNumber: string) => {
  setPhoneNumber(prev => {
     if (prev.includes(phoneNumber)) {
       return prev.filter(num => num !== phoneNumber);
     } else {
       return [...prev, phoneNumber];
     }
  });
  
 };
 const {sendBulkSms, loading} = useSendBulkMessages()

 console.log(phoneNumber)
 const initialFormValues = {
   recipients: phoneNumber,
   message: "",
   title: "",
   amount: 0,
 };
 
 console.log(phoneNumber.toString())
 const convertNumber = phoneNumber.toString()
 const price =Math.ceil( 2.01*phoneNumber?.length)
   const [formValues, setFormValues] = useState<any>({
     recipients:phoneNumber.toString(),
 message:"",
 title:"",
 amount:price
 
   });
   console.log(formValues.amount)
   console.log(formValues)
   const handleSendSms = async()=>{
    try {

  await sendBulkSms(formValues).then((res)=>{
  setFormValues(initialFormValues)
  })
      
    } catch (error) {
      console.log(error)
    }
  }

 
   const handleChange = (event:any) => {
     const { name, value } = event.target;
     setFormValues((prevValues:any) => ({
       ...prevValues,
       [name]: value
     }));
   };
 
 
 
 
 const handleSelectAll = () => {
  if (selectAll) {
     setPhoneNumber([]);
     setSelectAll(false);
  } else {
     const allNumbers = data?.data?.map((item:any) => item.phoneNumber); 
     setPhoneNumber(allNumbers || []);
     setSelectAll(true);
  }

 };
 
  const handleRecipient =()=>{
// dispatch(updatePhoneNumber(phoneNumber))
setSend((prevSend)=>!prevSend)

  }
 
    const [isModal, setIsModal] = useState(false);
 
    // const Navigate= useNavigate()
 
    const hideSubmitBulkModal = () => {
        setIsModal(false);
      }; 
    

      const {data} = useFetchBulkItems("/BulkSms/get-uploaded-numbers")

        
   useEffect(() => {
    setSelectAll(false);
 }, [data]);
      console.log("uploadedooooo numbers", data)
  return send? (


<>
<OtherHeader />
<Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-messages/fetch-contacts" text="Send Message" />

</Flex>
</Box>
<Box mt={3}>
<Box fontWeight="bold">
  Send your SMS message
</Box>
<Box mt={4} fontWeight={600}>
  Contacts
</Box>
<Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2" p={2} color="gray" fontSize={12}>
{/* {
phoneNumber?.map((props: phoneNumberInterface, index: number) => (

))
} */


<React.Fragment>
  {convertNumber}
</React.Fragment>
}
<Box bg="#00000070" textAlign="center" p="3px" w="80px" borderRadius={10} color="#fff">{phoneNumber?.length} {" "} {"contacts"}</Box>
</Box>

<Box  mt={4} fontWeight={600}>Title</Box>
<CustomInput onChange={handleChange}
name='title'
value={formValues.title}
/>
<Box mt={4} fontWeight={600}>
Text Message
</Box>
<Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea name='message' value={formValues.message} onChange={handleChange} h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
{formValues.message?.length}
</Box>
</Box>
<Box mt={36}>
  <Flex>
      <Box>Total Fee :</Box>
      <Box fontWeight="bold">₦{formValues?.amount}{".00"}</Box>
  </Flex>
</Box>
<Box>
<Button onClick={handleSendSms} variant="primary" size="normal">
{
loading?"Sending please wait...":"Send Message"
}
</Button>
</Box>

</Box>
</>
  ): 
  
      <>
       <OtherHeader />
      
  
       {
      isModal && 
  
   <BulkMessageModal variant="clear"  buttonText="Upload Contact"  link="/dashboard/bulk/bulk-messages/send-bulk-sms" hideModal={hideSubmitBulkModal}>
  <Box  w="100%"  h="100%" >
   3
      <Box>
    
      </Box>
  
  <Box w="100%" mt={4} mb={2} bg="#f1f1f1" height="150px" borderRadius="10px">
      <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
     
      <Box mt={2}>
  
  
  
      </Box>
  
  
      </Flex>
      <Box fontWeight={600} color="#68bc6c" textAlign="center" fontSize="18px" mt={4} >
          Upload Contacts
      </Box>
  </Box>
  
  
  </Box>
  
      </BulkMessageModal>
  
   }
         <Box>
  
         <Flex alignItems="center" h="100%">
          <PageHeader text="Uploaded Numbers" link={`/dashboard/bulk/bulk-message`} />
          <Box w="50px" h="30px" borderRadius="100%" cursor="pointer">
  
          <Box height="100%" w="70px" onClick={() => handleSelectAll()} fontSize={12} color="green">
                <Flex justifyContent="center" alignItems="center" h="100%">
                  Select All
                </Flex>
              </Box>
  
   
  
  </Box>
          </Flex>
         </Box>
     {
      data?.data?.map((props:any)=>(
        <Box mt={3} w="100%"  >
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
           <Flex alignItems="center" height="100%" >
         
            <Box ml={3}>
            <Box fontSize={14}>{props.phoneNumber}</Box>
            </Box>
           </Flex>
          </Box>
          <Box>
          <Checkbox checked={phoneNumber.includes(props.phoneNumber)} onChange={() => handleSelect(props.phoneNumber)} />
          </Box> 
        </Flex>
  
        <Box w="100%" bg="#f1f1f1" h="1px" mt={3}>
  
        </Box>
       </Box>
  
  
      ))
     }
  <Box position="fixed" width="90%" bottom={0}>
  
  <Button variant='primary' size='normal' onClick={handleRecipient}>
          Add Recipients
        </Button>
  </Box>
  
      </>
  

}

export default UploadedNumbers