import { Box, Flex } from "@chakra-ui/react";
import { MdOutlineCancel } from "react-icons/md";
import styles from "../modal.module.scss";

interface props {
  children: React.ReactNode;
  onclose: () => void;
}
const MiddleModal = ({ children, onclose }: props) => {
  return (
    <Flex
      className={`${styles.modal_overlay}`}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          "@media screen and (min-width: 768px)": {
            paddingLeft: "142px",
            // maxWidth: "400px",
          }, 
        }}
      >
        <Box
          padding="20px"
          backgroundColor="#fefffe"
          width="full"
          borderRadius="20px"
          // marginX={5}
        >
          <MdOutlineCancel
            fontSize="24px"
            cursor="pointer"
            style={{
              marginBottom: "5px",
              marginLeft: "auto",

              color: "var(--chakra-colors-grey-dark)",
            }}
            onClick={onclose}
          />
          <Box textAlign="center">{children}</Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default MiddleModal;
