import { Box, Flex, Text } from "@chakra-ui/react";
import {
  Button,
  FileUpload,
  Header,
  OtherHeader,
  PageHeader,
  PriceBox,
} from "../../../components";
import CustomInput from "../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import { useState } from "react";
import { whatsappTvInterface } from "../../../types/components/componentInterfaces";

import useEditWhatsAppTv from "../../../hooks/whatsapptv-hooks/useEditWhatsAppTv";
import { useNavigate } from "react-router-dom";

const EditWhatsAppTv = () => {
  const initials = localStorage.getItem("tvInitialValues");

  const [tvInitialValues] = useState<whatsappTvInterface>(
    initials
      ? JSON.parse(initials)
      : {
          countryCode: "",
          whatsAppTVName: "",
          whatsAppNumber: "",
          whatsAppViewRange: "",
          linkPrice: 0,
          picturePrice: 0,
          videoPrice: 0,
          whatsAppTVProfilePicture: "",
          whatsAppDailyViewRangePicture: "",
        }
  );
  const [isLoading, setIsLoading] = useState(false);

  const { editWhatsAppTv } = useEditWhatsAppTv();
  const navigate = useNavigate();

  const handleEdit = (values: FormikValues) => {
    setIsLoading(true);
    editWhatsAppTv(values).then((res) => {
      setIsLoading(false);

      console.log(res);

      if (res.status === true) {
        localStorage.removeItem("tvInitialValues");
        navigate("/dashboard/my-whatsapp-tv");
      }
    });
  };

  return (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard/my-whatsapp-tv" text="Edit TV" />
      <Formik
        initialValues={tvInitialValues}
        onSubmit={(values) => {
          console.log(values);
          handleEdit(values);
        }}
      >
        {({ errors, values, handleChange, handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <CustomInput
              placeholder="Enter your Tv name"
              id="whatsAppTVName"
              label="Enter your WhatsApp Tv name"
              required
              name="whatsAppTVName"
              value={values.whatsAppTVName}
              onChange={handleChange}
              error={errors.whatsAppTVName ? errors.whatsAppTVName : null}
            />

            <CustomInput
              placeholder="Enter the number"
              id="whatsAppViewRange"
              label="Enter the number of your daily view range"
              required
              name="whatsAppViewRange"
              value={values.whatsAppViewRange}
              onChange={handleChange}
              error={errors.whatsAppViewRange ? errors.whatsAppViewRange : null}
            />

            <label className="input-label">Enter your price per post</label>
            <Flex
              justifyContent="space-between"
              marginTop="16px"
              marginBottom="20px"
            >
              <PriceBox
                label="Link"
                value={values.linkPrice}
                name="linkPrice"
                error={errors.linkPrice ? errors.linkPrice : null}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <PriceBox
                label="Picture"
                value={values.picturePrice}
                name="picturePrice"
                error={errors.picturePrice ? errors.picturePrice : null}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <PriceBox
                label="Video"
                value={values.videoPrice}
                name="videoPrice"
                error={errors.videoPrice ? errors.videoPrice : null}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Flex>

            <Box marginBottom="20px">
              <label className="input-label" style={{ marginBottom: "0px" }}>
                Add your whatsApp TV profile picture
              </label>
              <Text className="input-sub-label">
                it can be in a form of (jpg, png, jpeg, mp4)
              </Text>
              <FileUpload
                name="whatsAppTVProfilePicture"
                error={
                  errors.whatsAppTVProfilePicture
                    ? errors.whatsAppTVProfilePicture
                    : null
                }
              />
            </Box>

            <Box marginBottom="20px">
              <label className="input-label" style={{ marginBottom: "0px" }}>
                Send a visiable picture to verify your daily view range
              </label>
              <Text className="input-sub-label">
                it can be in a form of (jpg, png, jpeg, mp4)
              </Text>
              <FileUpload
                name="whatsAppDailyViewRangePicture"
                error={
                  errors.whatsAppDailyViewRangePicture
                    ? errors.whatsAppDailyViewRangePicture
                    : null
                }
              />
            </Box>
            <Box margin="20px 0px">
              <Button variant="primary" size="normal" type="submit">
                {isLoading ? "Loading..." : "Confirm"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditWhatsAppTv;
