import { useRef } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Typography from "../../../components/core/typo/Typography";
import facebook from "../../../assets/svgs/facebook.svg";
import google from "../../../assets/svgs/google.svg";
import { useDispatch } from "react-redux";
import { authInterface } from "../../../types/auth/authInterfaces";
import { axiosBaseInstance } from "../../../services/axiosBaseInstance";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { addUser } from "../../../redux/reducers/userReducer";
import { updateWallet } from "../../../redux/reducers/walletReducer";
import { showNotification } from "@mantine/notifications";

type FacebookLoginRef = {
  state?: {
    facebook?: {
      instance?: {
        handleClick?: () => void;
      };
    };
  };
};

const SocialAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const facebookLoginRef = useRef<FacebookLoginRef>(null);

  const handleFacebookLogin = () => {
    facebookLoginRef.current?.state?.facebook?.instance?.handleClick?.();
  };

 
  const signUp = async (data: authInterface) => {
    try {
       await axiosBaseInstance.post("/Onboarding/SignUp", data).then((res)=>{
        localStorage.setItem("idtoken", res?.data.data.idToken);
      // console.log("I'm signed up", res?.data.data.idToken);
      // localStorage.setItem("email", res.data.data.email);
      navigate("/social-confirm-email");


       });
     
    } catch (error) {
      console.error(error);
      return error;
    }
  };
  

  const signIn = async (data: authInterface) => {
    try {
      const response = await axiosBaseInstance.post("/Onboarding/User-Login", data);

      console.log("I'm signed in", response.data);
      if (response.data.status === true) {
        dispatch(addUser(response.data.data));
        dispatch(updateWallet(response.data.data.accountDetails.walletData));
        navigate("/dashboard");
      } else {
        showNotification({
          title: "Error",
          message: response.data.Message || "An error occurred. Try again",
          color: "red",
        });
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      fetch("https://www.googleapis.com/oauth2/v1/userinfo", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((datas) => {
          const data: authInterface = {
            email: datas.email,
            passWord: datas.email,
          };
          axiosBaseInstance
            .get(
              `https://wassapview.azurewebsites.net/app/v1/Onboarding/CheckIds?param=${data.email}`
            )
            .then((checks) => {
            
              if (
                checks.data.data.isAccountExist &&
                checks.data.data.status === "V"
              ) {
                signIn(data);
              } else if (!checks.data.data.isAccountExist) {
                signUp(data);
              } else {
                navigate("/social-confirm-email");
              }
            })
            .catch((error) => {
              // console.error(error);
              return error;
            });
        })
        .catch((error) => {
          // console.error(error);
          return error;
        });
    },
  });

  const responseFacebook = async (response: any) => {
    try {
      const data: authInterface = {
        email: response.email,
        passWord: response.email,
      };

      const checks = await axiosBaseInstance.get(
        `https://wassapview.azurewebsites.net/app/v1/Onboarding/CheckIds?param=${data.email}`
      );
console.log(checks)
      if (checks.data.data.isAccountExist && checks.data.data.status === "V") {
        signIn(data);
      } else if (!checks.data.data.isAccountExist) {
        signUp(data);
      } else {
        navigate("/confirm-email");
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const componentClicked = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <Box marginBottom="10px" marginTop="20px">
        <Flex alignItems="center" justifyContent="center">
          <Box marginRight={2} w="90px" border="1px solid #d3d3d3" h="0px"></Box>
          <Box>
            <Typography color="#888888" fontSize="14px">
              or continue with
            </Typography>
          </Box>
          <Box marginLeft={2} w="90px" border="1px solid #d3d3d3" h="0px"></Box>
        </Flex>
      </Box>

      <Box gap="20px">
        <Flex
          alignItems="center"
          w="full"
          borderRadius="20px"
          border="0.5px solid #030202"
          padding="15px"
          marginBottom="15px"
          bg="#fff"
          onClick={handleFacebookLogin}
        >
          <Image src={facebook} />
          <Flex width="100%" justifyContent="center">
            <FacebookLogin
              appId="YOUR_FACEBOOK_APP_ID"
              autoLoad={false}
              fields="name,email,picture"
              cssClass="hidden"
              callback={responseFacebook}
              onClick={componentClicked}
              textButton="Continue with Facebook"
              buttonStyle={{
                fontWeight: "500",
              }}
            />
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          w="full"
          borderRadius="20px"
          border="0.5px solid #030202"
          padding="15px"
          bg="#fff"
          marginBottom="15px"
          cursor="pointer"
          onClick={() => googleLogin()}
        >
          <Image src={google} />
          <Flex width="100%" justifyContent="center" fontWeight="500">
            <Text>Continue with Google</Text>
          </Flex>
        </Flex>
      </Box>
      <Box w="100%" marginTop={4}></Box>
    </>
  );
};

export default SocialAuth;
