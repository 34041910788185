import React  from "react";
import { Button, MiddleModal } from "../../../../components";
import { Text } from "@chakra-ui/react";


interface props {
  onclose: () => void;

}
const SuccessCardForm = ({ onclose }: props) => {

 
  return (
    <MiddleModal onclose={onclose}>
      <Text fontSize="18px" fontWeight={700} marginBottom={4}>
     Successfully submitted your details
      </Text>
     

      <Button
        // type="button"
        variant="primary"
        size="normal"
       onClick={onclose}
      
      >
        Save card owner contact
      </Button>
    </MiddleModal>
  );
};

export default SuccessCardForm;
