import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";


interface BulkSmsForm {
    recipients:any
title:string
amount:any
message:string

   
  }
  

const useSendBulkMessages = () => {
  const [loading, setLoading] = useState(false)
  
  const user = useSelector((state: RootState) => state.user);
  console.log(user.authToken)

  const sendBulkSms = async (values: BulkSmsForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .post("/BulkSms/send-bulk-sms", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        if(res.data.Status === false){
            
          showNotification({
         message:res.data.Message,
         color:"red"
          })
        }else{
        setLoading(false)

            showNotification({
                message:res.data.message,
                color:"green"
                 })
        }
        setLoading(false)
        

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { sendBulkSms , loading};
};

export default useSendBulkMessages;
