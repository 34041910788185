import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Box
      width="90%"
      margin="auto"
      sx={{
        "@media screen and (min-width: 450px)": {
          maxWidth: "441px",
          padding: "20px 0px 40px",
        },
      }}
    >
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
