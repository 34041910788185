import { Button, OnboardingSlide } from "../../components";
import "./onboarding.module.scss";
import { slides } from "./utils/values";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Carousel } from "@mantine/carousel";
import { rem } from "@mantine/core";
import logo from "../../assets/svgs/logo.svg";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
const Onboarding = () => {
  const navigate = useNavigate();

  const Login = () => {
    navigate("/signin");
  };
  const Register = () => {
    navigate("/register");
  };
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  return (
    <Flex
      paddingX="20px"
      paddingY="10px"
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        "@media screen and (min-width: 450px)": {
          padding: "0px",
        },
      }}
    >
      <Flex
        marginBottom="20px"
        sx={{
          "@media screen and (min-width: 450px)": {
            margin: "0px",
          },
        }}
      >
        <img
          src={logo}
          alt="wassapview"
          style={{ width: "23px", marginRight: "10px" }}
        />
        <div style={{ fontSize: "20px", fontWeight: "600" }}>Wassapview</div>
      </Flex>
      <Carousel
        withIndicators
        loop={false}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        styles={{
          indicator: {
            width: rem(8),
            height: rem(8),
            backgroundColor: "#D9D9D9",
            transition: "width 250ms ease",

            "&[data-active]": {
              backgroundColor: "#4CB051",
            },
          },
        }}
      >
        {slides?.map((slide) => (
          <Carousel.Slide key={slide.title}>
            <OnboardingSlide
              icon={slide.icon}
              title={slide.title}
              description={slide.description}
            />
          </Carousel.Slide>
        ))}
      </Carousel>

      <Box gap="10px">
        <Button
          variant="primary"
          type="button"
          onClick={Login}
          size="normal"
          style={{ marginBottom: "12px" }}
        >
          Log in
        </Button>
        <Button variant="clear" type="button" size="normal" onClick={Register}>
          Sign up
        </Button>

        <Text fontSize="14px" marginTop="10px">
          By continuing, you agree with our{" "}
          <span style={{ color: "#4CB051" }}>terms and condition</span> &{" "}
          <span style={{ color: "#4CB051" }}>Privacy Policy.</span>
        </Text>
      </Box>
    </Flex>
  );
};

export default Onboarding;
