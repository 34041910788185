import React, { useEffect }  from "react";

import { Box, Flex, Text, Toast } from "@chakra-ui/react";
import TopRightModal from "../../../../components/Modals/MiddleModal/TopRIghtModal";
import { Button } from "../../../../components";
import { TbEdit } from "react-icons/tb";
import { HiOutlineDownload } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import copy from "clipboard-copy"
import useGetCardById from "../../../../hooks/creator-hooks/useGetCardById";
import { IoCopyOutline } from "react-icons/io5";
import { FaDiagramProject } from "react-icons/fa6";
import useDownloadContact from "../../../../hooks/creator-hooks/useDownloadContact";


interface props {
  onclose: () => void;
  cardId:any
onclick:()=>void
}
const OptionNav = ({ onclose, cardId, onclick }: props) => {
   
   const {data} = useGetCardById(cardId)
   const {fetched, data:downloadResponse} = useDownloadContact(cardId)
 
console.log(downloadResponse)
   const download =async  ()=>{
   const response = await  fetched()
   
   const result:any = localStorage.getItem("download")
   const convert = JSON.parse(result)
   const downloadUrl = convert?.downloadUrl;
   const link = document.createElement("a");
   link.href = downloadUrl;
   link.setAttribute("download", "creator contact");
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
   localStorage.removeItem("download")

   }



 const navigate = useNavigate()
 
 const copyLink = ()=>{
   copy(data?.cardLink)

   Toast({
       title: 'Link copied',
   description: "Link copied",
       status: 'success',
       position:"top-right",
       duration: 3000,
     
       isClosable: true,
     })
  
 }
 const promoteACard= ()=>{
   navigate(`/dashboard/creator/promote-card/${cardId}`)
 }
 const deletePage =()=>{
   navigate(`/dashboard/creator/delete-card/${cardId}`)
 }
 const EditCard =()=>{
   navigate(`/dashboard/creator/edit-card/${cardId}`)
 }

  return (
    <TopRightModal onclose={onclose}>
 <Box onClick={EditCard}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
        <TbEdit /> <Box ml={4}>Edit</Box>
    </Flex>
 </Box>
 <Box onClick={copyLink}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
    <IoCopyOutline /> <Box ml={4}>Copy link</Box>
    </Flex>
 </Box>
 <Box onClick={promoteACard}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
    <FaDiagramProject /> <Box ml={4}>Promote card</Box>
    </Flex>
 </Box>
 <Box onClick={download}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
    <HiOutlineDownload /> <Box ml={4}>Download</Box>
    </Flex>
 </Box>
     
 <Box onClick={deletePage}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
    <MdOutlineCancel /><Box ml={4}> Delete</Box>
    </Flex>
 </Box>
     
 <Box onClick={onclick}>
    <Flex                                                                                                                                                                                                                                                                                                             alignItems="center" mb={2}>
    <MdOutlineDelete />
    <Box ml={4}> Delete Many</Box>
    </Flex>
 </Box>
     
     
     
    </TopRightModal>
  );
};

export default OptionNav;
