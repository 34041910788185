import React from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Text, Textarea } from '@chakra-ui/react'
import { FaCrown } from "react-icons/fa";
import { LuCalendarDays } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';
import useFetchEmails from '../../../hooks/bulkHooks/useFetchEmails';
const SendBulkEmail = () => {
  const navigate = useNavigate()
  

  const fetchContacts = ()=>{
    navigate("/dashboard/bulk/bulk-emails/fetch-emails")

  }

  const {data, fetched, loadEmail} = useFetchEmails()


  const getEmails = async()=>{
    try {
      await fetched().then((res)=>{
        
        console.log(res)
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
  <>
   <OtherHeader />
  
   <Box>

<Flex alignItems="center" h="100%">
 <PageHeader text="Send Bulk Email" link={`/dashboard/bulk/bulk-email`} />
 <Box w="50px" h="30px" borderRadius="100%" cursor="pointer">




</Box>
 </Flex>
</Box>

<Box mt={3} fontWeight={600} overflow="scroll" css={{
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: "transparent",
      borderRadius: 0,
    },
  }}>
   
Select recipient email from our database or from uploaded emails
</Box>

<Box w="100%" h="220px"shadow="sm" mt={6}>
    <Flex justifyContent="center"alignItems="center" h="100%" flexDirection="column">
    <Box w="90%" h="90%">
<Text mt={4} fontWeight={600}>Select from our database</Text>

<Box bg="#f1f1f1" w="100%" h="60px" mt={3} borderRadius="8px" mb={2}>
  <Flex justifyContent="center" alignItems="center" height="100%">
  <Box w="90%">

  <Box color="gray">Fetch emails...</Box>
</Box>
  </Flex>

</Box>
<Button onClick={fetchContacts} variant='primary' size='normal'>
  Fetch Emails

</Button>
</Box>


    </Flex>

 


</Box>





<Box w="100%" h="220px"shadow="sm" mt={6}>
    <Flex justifyContent="center"alignItems="center" h="100%" flexDirection="column">
    <Box w="90%" h="90%">
<Text mt={4} fontWeight={600}>Use your uploaded Emails</Text>

<Box bg="#f1f1f1" w="100%" h="60px" mt={3} borderRadius="8px" mb={2}>
  <Flex justifyContent="center" alignItems="center" height="100%">
  <Box w="90%">
  <Box color="gray">Load emails...</Box>

</Box>
  </Flex>

</Box>
<Button variant='primary' size='normal' onClick={getEmails}>
{
  loadEmail? "Emails Loading...":"Load Emails"
}

</Button>
</Box>


    </Flex>

 


</Box>











  </>
  )
}

export default SendBulkEmail