import { Routes, Route } from "react-router-dom";

import {
  Onboarding,
  SignIn,
  VerifySocial,
  Socialmail,
  SplashScreen,
  Home,
  Wallet,
  Register,
  ForgetPassword,
  Transactions,
  AvailableTvs,
  WhatsappTv,
  TopupWallet,
  ProfileSetup,
  VerifyIdentity,
  EmailConfirmation,
  Profile,
  More,
  DownloadFreeContact,
  Terms,
  EditProfile,
  MyWhatsappTv,
  CreateWhatsappTv,
  EditWhatsAppTv,
  TransactionDetails,
  Reviews,
  DownloadHistory,
  WhatsAppLink,
  CreatedLink,
  FreeLink,
  // BrandLink,
  BrandedLink,
  GenerateBrandLink,
  Openchat,
  LinkDetails,
  Subscribe,
  BecomeAcreator,
  CreateCard,
  CardForm,
  ReviewCard,
  SingleCardForm,
  CardDetails,
  DeleteContact,
  ReviewPromotion,
  RenewCard,
  AllPromotedCards,
  AllCreatedCards,
  DeleteCard,
  EditCard,
  AllExpiredCards,
  AllContacts,
  DownloadContact,
  BulkMessage,
  SentMessages,
  ResendMessage,
  UploadedNumbers,
  SendBulkSms,
  PendingMessages,
  DeliveredMessages,
  FetchContacts,
  DispatchMessage,
  MyContacts,
  BulkEmail,
  SentEmails,
  ResendEmail,
  UploadedEmails,
  DeliveredEmails,
  PendingEmails,
  SendBulkEmail,
  DispatchEmail,
  ApprovedMessages,
  BulkWhatsApp,
  ResendWhatsappMessage,

  PendingWhatsappMessages,
  RejectedWhatsappMessage,
  DelieveredWhatsappMessage,
  SendBulkWhatsappMessage,
  FetchWhatsappContacts,
  UploadedWhatsappNumbers,
  DispatchWhatsappMessage,
  MyWhatsappContacts,
  DeleteMessage,
  DeleteEmail,
  DeleteWhatsappMessage,
} from "./pages";
import RequireAuth from "./pages/Auth/RequireAuth/RequireAuth";
import { AuthLayout, GeneralLayout } from "./components";
import SubAuthLayout from "./components/Layout/AuthLayout/SubAuthLayout";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import DownloadPremiumContact from "./pages/User/PremiumContact/DownloadPremiumContact";
import GetPremiumHistory from "./pages/User/DownloadHistory/GetPremiumHistory";
import Success from "./pages/User/TopupWallet/components/Success";
import PromoteCard from "./pages/User/BecomeCreator/PromoteCard";
import FetchEmails from "./pages/User/Bulks/FetchEmails";

function App() {
  return (
    <Routes>
      <Route path="" element={<AuthLayout />}>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/onboarding" element={<Onboarding />} />

        <Route path="" element={<SubAuthLayout />}>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm-email" element={<EmailConfirmation />} />
          <Route path="/social-confirm-email" element={<Socialmail />} />

          <Route path ="/socials-verify-identity" element={<VerifySocial/>}/>
          <Route path="/verify-identity" element={<VerifyIdentity />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route element={<RequireAuth />}>
            <Route path="/profile-setup" element={<ProfileSetup />} />
          </Route>
        </Route>
      </Route>

      <Route element={<GeneralLayout />}>
        <Route path="/whatsapptv/:name" element={<MyWhatsappTv />} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path="/dashboard" element={<Home />} />

        <Route path="" element={<GeneralLayout />}>
          <Route path="/dashboard/my-whatsapp-tv" element={<MyWhatsappTv />} />
          <Route
            path="/dashboard/whatsapp-tvs/:whatsappTvId"
            element={<WhatsappTv />}
          />
          <Route
            path="/dashboard/whatsapp-tvs/:whatsappTvId/reviews"
            element={<Reviews />}
          />
          <Route path="/dashboard/whatsapp-tvs" element={<AvailableTvs />} />
          <Route path="/dashboard/more" element={<More />} />
          <Route path="/dashboard/wallet" element={<Wallet />} />
          <Route path="/dashboard/wallet/top-up" element={<TopupWallet />} />
          <Route
            path="/dashboard/wallet/transaction-history"
            element={<Transactions />}
          />
          <Route
            path="/dashboard/wallet/transaction/:transactionId"
            element={<TransactionDetails />}
          />
          <Route
            path="/dashboard/download-free-contact"
            element={<DownloadFreeContact />}
          />
          <Route
            path="/dashboard/premium-contact"
            element={<DownloadPremiumContact />}
          />
          {/* <Route path="/dashboard/my-whatsapp-tv" element={<MyWhatsappTv />} /> */}
          <Route
            path="/dashboard/edit-whatsapp-tv"
            element={<EditWhatsAppTv />}
          />
          <Route
            path="/dashboard/create-whatsapp-tv"
            element={<CreateWhatsappTv />}
          />
          <Route
            path="/dashboard/whatsapp-tvs/:whatsappTvId"
            element={<WhatsappTv />}
          />
          <Route path="/dashboard/user-profile" element={<Profile />} />
          <Route path="/dashboard/edit-profile" element={<EditProfile />} />
          <Route path="/dashboard/terms-and-privacy" element={<Terms />} />
          <Route
            path="/dashboard/download-history"
            element={<DownloadHistory />}
          />

<Route
            path="/dashboard/premium-download-history"
            element={<GetPremiumHistory />}
          />
          <Route
            path="/dashboard/create-whatsapp-link"
            element={<WhatsAppLink />}
          />
            <Route
            path="/dashboard/created-links"
            element={<CreatedLink/>}
          />
          <Route
            path="/dashboard/whatsapp-link/create-free-link"
            element={<FreeLink/>}
          />
           <Route
            path="/dashboard/whatsapp-link/create-brand-link"
            element={<BrandedLink/>}
          />
              <Route
            path="/dashboard/whatsapp-link/generate-brand-link"
            element={<GenerateBrandLink/>}
          />
             <Route
            path="/dashboard/whatsapp-link/open-whatsapp-chat"
            element={<Openchat/>}
          />
           <Route
            path="/dashboard/whatsapp-link/open-whatsapp-link/:id"
            element={<LinkDetails/>}
          />
             <Route
            path="/dashboard/whatsapp-link/open-whatsapp-link/analysis/:id"
            element={<Subscribe/>}
          />
          <Route
            path="/dashboard/creator/become-a-creator"
            element={<BecomeAcreator/>}
          />
           <Route
            path="/dashboard/creator/create-card"
            element={<CreateCard/>}
          />
             <Route
            path="/dashboard/creator/card-form"
            element={<CardForm/>}
          />
               <Route
            path="/dashboard/creator/review-card"
            element={<ReviewCard/>}
          />
             <Route
            path="/dashboard/creator/single-card-form/:id"
            element={<SingleCardForm/>}
          />
           <Route
            path="/dashboard/creator/single-card-detail/:id"
            element={<CardDetails/>}
          />
            <Route
            path="/dashboard/creator/delete-contact/:id"
            element={<DeleteContact/>}
          />
            <Route
            path="/dashboard/creator/review-promotion/:id"
            element={<ReviewPromotion/>}
          />
               <Route
            path="/dashboard/creator/promote-card/:id"
            element={<PromoteCard/>}
          />
              <Route
            path="/dashboard/creator/renew-card/:id"
            element={<RenewCard/>}
          />
           <Route
            path="/dashboard/creator/all-promoted-cards"
            element={<AllPromotedCards/>}
          />
            <Route
            path="/dashboard/creator/all-expired-cards"
            element={<AllExpiredCards/>}
          />
            <Route
            path="/dashboard/creator/all-contacts/:id"
            element={<AllContacts/>}
          />
           <Route
            path="/dashboard/creator/all-created-cards"
            element={<AllCreatedCards/>}
          />
            <Route
            path="/dashboard/creator/delete-card/:id"
            element={<DeleteCard/>}
          />
             <Route
            path="/dashboard/creator/edit-card/:id"
            element={<EditCard/>}
          />
              <Route
            path="/dashboard/creator/download"
            element={<DownloadContact/>}
          />
            <Route
            path="/dashboard/bulk/bulk-message"
            element={<BulkMessage/>}
          />
            <Route
            path="/dashboard/bulk/bulk-message/sent"
            element={<SentMessages/>}
          />
            <Route
            path="/dashboard/bulk/bulk-message/pending"
            element={<PendingMessages/>}
          />
               <Route
            path="/dashboard/bulk/bulk-message/delivered"
            element={<DeliveredMessages/>}
          />
           <Route
            path="/dashboard/bulk/bulk-messages/sent/resend/:id"
            element={<ResendMessage/>}
          />
            <Route
            path="/dashboard/bulk/bulk-messages/sent/delete/:id"
            element={<DeleteMessage/>}
          />
            <Route
            path="/dashboard/bulk/bulk-messages/uploaded-numbers"
            element={<UploadedNumbers/>}
          />
            <Route
            path="/dashboard/bulk/bulk-messages/send-bulk-sms"
            element={<SendBulkSms/>}
          />
           <Route
            path="/dashboard/bulk/bulk-messages/send-sms"
            element={<DispatchMessage/>}
          />
           <Route
            path="/dashboard/bulk/bulk-messages/fetch-contacts"
            element={<FetchContacts/>}
          />
           <Route
            path="/dashboard/bulk/bulk-messages/my-phone-contacts"
            element={<MyContacts/>}
          />
              <Route
            path="/dashboard/bulk/bulk-email"
            element={<BulkEmail/>}
          />
            <Route
            path="/dashboard/bulk/bulk-email/sent"
            element={<SentEmails/>}
          />
                     <Route
            path="/dashboard/bulk/bulk-email/sent/resend/:id"
            element={<ResendEmail/>}
          />
             <Route
            path="/dashboard/bulk/bulk-email/sent/delete/:id"
            element={<DeleteEmail/>}
          />
    <Route
            path="/dashboard/bulk/bulk-emails/uploaded-emails"
            element={<UploadedEmails/>}
          />
            <Route
            path="/dashboard/bulk/bulk-emails/delivered"
            element={<DeliveredEmails/>}
          />
            <Route
            path="/dashboard/bulk/bulk-email/pending"
            element={<PendingEmails/>}
          />
            <Route
            path="/dashboard/bulk/bulk-email/send-bulk-email"
            element={<SendBulkEmail/>}
          />
           <Route
            path="/dashboard/bulk/bulk-emails/fetch-emails"
            element={<FetchEmails/>}
          />
              <Route
            path="/dashboard/bulk/bulk-emails/send-email"
            element={<DispatchEmail/>}
          />
                <Route
            path="/dashboard/bulk/bulk-whatsapp-message/approved-messages"
            element={<ApprovedMessages/>}
          />
               <Route
            path="/dashboard/bulk/bulk-whatsapp-message"
            element={<BulkWhatsApp/>}
          />
            <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/sent/resend/:id"
            element={<ResendWhatsappMessage/>}
          />
            <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/sent/delete/:id"
            element={<DeleteWhatsappMessage/>}
          />
      <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/pending-messages"
            element={<PendingWhatsappMessages/>}
          />
            <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/rejected-messages"
            element={<RejectedWhatsappMessage/>}
          />
                <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/delivered-messages"
            element={<DelieveredWhatsappMessage/>}
            
          />
             <Route
            path="/dashboard/bulk/bulk-whatsapp-messages/send-bulk-whatsapp-messages"
            element={<SendBulkWhatsappMessage/>}
            
          />
                <Route
            path="/dashboard/bulk/bulk-messages/fetch-whatsapp-contacts"
            element={<FetchWhatsappContacts/>}
            
          />
             <Route
            path="/dashboard/bulk/bulk-messages/uploaded-whatsapp-contacts"
            element={<UploadedWhatsappNumbers/>}
            
          />
            <Route
            path="/dashboard/bulk/bulk-whatsapp-message/send-bulk-whatsapp-message"
            element={<DispatchWhatsappMessage/>}
            
          />
            <Route
            path="/dashboard/bulk/bulk-whatsapp-message/my-contacts"
            element={<MyWhatsappContacts/>}
            
          />
        </Route>
      </Route> 

      {/* Protected routes */}
    </Routes>
  );
}

export default App;
