import {
  ComingSoonModal,
  FeatureCard,
  Header,
  ServiceCard,
} from "../../../components";
import { slides } from "./utils/values";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { useRef, useState } from "react";
import { rem } from "@mantine/core";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom";
import folder from "../../../assets/svgs/folder.svg";
import tv from "../../../assets/svgs/tv.svg";
import link from "../../../assets/svgs/link.svg";
import mobile from "../../../assets/svgs/mobile.svg";
import creator from "../../../assets/svgs/creator.svg";
import send from "../../../assets/svgs/send.svg";
import FeaturesModal from "./components/FeaturesModal";
import SubmitContactModal from "./components/SubmitContactModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import phone from "../../../assets/svgs/phone.svg";
import rocket from "../../../assets/svgs/rocket.svg";
import shop from "../../../assets/svgs/shop.svg";
import DashboardLayout from "../../../components/Layout/DashboardLayout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import DeviceModal from "./components/DeviceModal";

const Home = () => {
  const wallet = useSelector((state: RootState) => state.wallet);
  const [modalVariant, setModalVariant] = useState<string>("");
  const [isModal, setIsModal] = useState(false);
  const [isSubmitModal, setIsSubmitModal] = useState(false);
  const [isComingSoon, setIsComingSoon] = useState(false);
const navigation = useNavigate()
  const showVcfModal = () => {
    setModalVariant("vcf");
    setIsModal(true);
  };
  const showBulkModal = () => {
    setModalVariant("bulkmsgs");
    setIsModal(true);
  };

  const showTvModal = () => {
    setModalVariant("tv");
    setIsModal(true);
  };

  const hideModal = () => {
    setIsModal(false);
  };

  const showSubmitContactModal = () => {
    setIsModal(false);
    setIsSubmitModal(true);
  };
  const hideSubmitContactModal = () => {
    setIsModal(false);
    setIsSubmitModal(false);
  };

  const controlComingSoonModal = () => {
    setIsComingSoon(!isComingSoon);
  };
  const wassap = () => {
 navigation("/dashboard/create-whatsapp-link")
  };
  const beAcreator = () => {
    navigation("/dashboard/creator/become-a-creator")
     };
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  const services = [
    {
      title: "Social Media Logs",
      icon: phone,
      link: "http://solo.to/dartechlabs",
    },
    {
      title: "Boost Social Media",
      icon: rocket,
      link: "http://solo.to/dartechlabs",
    },
    {
      title: "Number Verification",
      icon: shop,
      link: "http://solo.to/dartechlabs",
    },
  ];

  const features = [
    {
      title: "Vcf files",
      description: "For yourself,Promote your business or get contacts ",
      Icon: folder,
      link: "",
      color: "#FEFFDF",
      onclick: showVcfModal,
    },
    {
      title: "Whatsapp TV's",
      description: "Get connected with verified whatsapp TV’s",
      Icon: tv,
      link: "",
      color: "#E7FCFF",
      onclick: showTvModal,
    },
    {
      title: "Send Bulk SMS",
      description: "Send bulk SMS for your business or personal use ",
      Icon: send,
      link: "",
      color: "#E7DEF7",
      onclick: showBulkModal,
    },

    {
      title: "Shorten whatsapp link",
      description: "Shorten your whatsapp link for easy access",
      Icon: link,
      link: "/dashboard/create-whatsapp-link",
      color: "#FBF1FF",
      onclick: wassap,
    },
    {
      title: "Become a creator",
      description: "Become a creator and join our community",
      Icon: creator,
      link: "/dashboard/creator/become-a-creator",
      color: "#E6F8EF",
      onclick: beAcreator,
    },
    {
      title: "Buy airtime/data",
      description: "Buy cheap airtime and data bundles",
      Icon: mobile,
      link: "",
      color: "#DDF0FF",
      onclick: controlComingSoonModal,
    },
  ];

  return (
    <DashboardLayout>
      {/* <ContactSuccessModal /> */}

      {isComingSoon && <ComingSoonModal onclick={controlComingSoonModal} />}
      <Header />

      <Carousel
        withIndicators
        loop={false}
        slideSize="100%"
        mx="auto"
        withControls={false}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        styles={{
          indicator: {
            width: rem(8),
            height: rem(8),
            backgroundColor: "#D9D9D9",
            transition: "width 250ms ease",

            "&[data-active]": {
              width: rem(30),
              backgroundColor: "#4CB051",
            },
          },
        }}
      >
        {slides?.map((value, index) => (
          <Carousel.Slide key={index}>
            <img
              src={value}
              alt="banner"
              style={{ width: "100%", objectFit: "cover", borderRadius: "5px" }}
            />
          </Carousel.Slide>
        ))}
      </Carousel>

      <Flex
        justifyContent="space-between"
        alignItems="center"
        filter="drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1))"
        padding="20px"
        backgroundColor="#FDFFFD"
        borderRadius="10px"
        margin="20px 0 30px"
      >
        <Box>
          <Text fontSize="14px" fontWeight="500">
            Balance
          </Text>
          <Text fontSize="28px" fontWeight="800" letterSpacing="1%">
            {`  N${wallet.balance.toFixed(2)}`}
          </Text>
        </Box>
        <Link to="wallet">
          <SlArrowRight fontSize="24px" />
        </Link>
      </Flex>

      <Flex justifyContent="space-between" gap="10px">
        {services?.map(({ title, icon, link }) => (
          <ServiceCard title={title} icon={icon} link={link} key={title} />
        ))}
      </Flex>

      <Box padding="20px 0">
        <Text fontSize="20px" fontWeight="700">
          Miscellaneous
        </Text>
      </Box>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={6}
        sx={{
          "@media screen and (min-width: 768px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "30px",
          },
        }}
      >
        {features?.map(({ Icon, title, description, color, onclick }) => (
          <FeatureCard
            icon={Icon}
            title={title}
            description={description}
            link=""
            color={color}
            onClick={onclick}
            key={title}
          />
        ))}
      </Grid>
      {isModal ? (
        <FeaturesModal
          variant={modalVariant}
          hideModal={hideModal}
          submitContactOnclick={showSubmitContactModal}
        />
      ) : isSubmitModal ? (
        <>
        <SubmitContactModal hideModal={hideSubmitContactModal} />
        {/* <DeviceModal hideModal={hideSubmitContactModal}/> */}
        </>
      ) : null}
    </DashboardLayout>
  );
};

export default Home;
