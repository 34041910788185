import { Text } from "@chakra-ui/react";
import { Button, MiddleModal as Modal } from "../../../components";
import { modalComponentInterface } from "../../../types/components/componentInterfaces";

const ComingSoonModal = ({ onclick }: modalComponentInterface) => {
  return (
    <Modal onclose={onclick}>
      <Text fontSize="18px" fontWeight={700} marginBottom={4}>
        Coming Soon
      </Text>

      <Text fontSize={14} textColor="#717171" marginBottom="24px">
        This feature is not available at the moment.
      </Text>

      <Button variant="primary" size="normal" type="button" onClick={onclick}>
        Continue
      </Button>
    </Modal>
  );
};

export default ComingSoonModal;
