// export const emailReducer = () => {};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { phoneNumberInterface, walletStateInterface } from "../../types/auth/authInterfaces";

export const initialState: phoneNumberInterface = {
phoneNumber:"",
name:""
};

const phoneNumberReducer = createSlice({
  name: "phoneNumber",
  initialState,
  reducers: {
 
    updatePhoneNumber: (state = initialState, action: PayloadAction<any>) => {
      state.phoneNumber = action.payload;
    },
  },
});

export const {  updatePhoneNumber } = phoneNumberReducer.actions;
export default phoneNumberReducer.reducer;
