import { Formik, FormikValues } from "formik";
import { Button } from "../../../components";
import { Box, Flex, Text } from "@chakra-ui/react";
import CustomInput from "../../../components/core/input/CustomInput";
import { resetPasswordSchema } from "../utils/validationSchema";
import useResetPassword from "../../../hooks/auth-hooks/useResetPassword";
import { useState } from "react";

const ResetPassword = () => {
  const { resetPassword, isLoading } = useResetPassword();
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);

  const initialValues = {
    idToken: localStorage.getItem("idToken"),
    resetOTP: "",
    newPassWord: "",
    confirmPassword: "",
  };

  const handleResetPassword = (values: FormikValues) => {
    resetPassword(values);
  };
  return (
    <>
      <Formik
        validationSchema={resetPasswordSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          handleResetPassword(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values, errors }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (Object.keys(errors).length > 0) {
                setIsValidatingSchema(true);
              } else {
                setIsValidatingSchema(false);
                handleSubmit();
              }
            }}
          >
            <Flex
              flexDirection="column"
              justifyContent="space-around"
              height="100vh"
              sx={{
                "@media screen and (min-width: 450px)": {
                  height: "100%",
                },
              }}
            >
              <Box>
                <Text fontSize="34px" fontWeight="700" marginBottom="20px">
                  Reset Password
                </Text>

                <CustomInput
                  width="auto"
                  label="OTP"
                  placeholder="Enter OTP "
                  name="resetOTP"
                  type="text"
                  value={values.resetOTP}
                  onChange={(e: any) => {
                    setFieldValue("resetOTP", e.target.value.trim());
                  }}
                  error={
                    isValidatingSchema && errors.resetOTP
                      ? errors.resetOTP
                      : null
                  }
                />
                <CustomInput
                  width="auto"
                  label="New Password"
                  placeholder="Enter your new password"
                  name="newPassWord"
                  type="password"
                  value={values.newPassWord}
                  onChange={(e: any) => {
                    setFieldValue("newPassWord", e.target.value.trim());
                  }}
                  error={
                    isValidatingSchema && errors.newPassWord
                      ? errors.newPassWord
                      : null
                  }
                />
                <CustomInput
                  width="auto"
                  label="Confirm Password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  onChange={(e: any) => {
                    setFieldValue("confirmPassword", e.target.value.trim());
                  }}
                  error={
                    isValidatingSchema && errors.confirmPassword
                      ? errors.confirmPassword
                      : null
                  }
                />
              </Box>

              <Box w="100%" marginTop={4}>
                <Button
                  variant="primary"
                  type="submit"
                  size="normal"
                  style={{ marginBottom: "12px" }}
                >
                  {isLoading ? "Loading..." : "Send"}
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
