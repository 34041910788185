import React from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useParams } from 'react-router-dom';
import useResendBulkEmail from '../../../hooks/bulkHooks/useResenBulkEmail';
import useGetSingleEmail from '../../../hooks/bulkHooks/useGetSingleEmail';
import CustomInput from '../../../components/core/input/CustomInput';

const ResendEmail = () => {
  const {id} =useParams()
  const {SendSingleEmail, loading} = useResendBulkEmail()
const {data} = useGetSingleEmail(id)
console.log(data)
const sorted = data?.data?.recipients

console.log(sorted)
const firstItem = sorted?.slice(1)
const newSort = firstItem?.split(",")

// console.log(firstItem)
console.log("sorted data here",newSort)
const price =Math.ceil( 2.01*newSort?.length)

console.log(data?.data)
const handleSendSms = async()=>{
  try {

await SendSingleEmail({  email:newSort,
  message:data?.data?.message,
  subject:data?.data?.subject,
  amount:price}).then((res:any)=>{
    console.log("confirmation", res)

})
    
  } catch (error) {
    console.log(error)
  }
}

  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-email" text="Send Email" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your email message
    </Box>
    <Box mt={4} fontWeight={600}>
    Emails
    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
    {
data?.data?.recipients?<React.Fragment >
{data?.data?.recipients?.split(1)}
</React.Fragment>:null
 }
     
    </Box>

    <Box  mt={4} fontWeight={600}>Title</Box>

<CustomInput
name='subject'
value={data?.data?.subject || null}
/>
    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">

    {data?.data?.message?  
 
 <Box h="85%" w="100%" > 
 {data?.data?.message || null}
 </Box>:
 
 <Textarea name='message' value={data?.data?.message} h="85%" w="100%" bg="transparent" border={0} outline="none">
 
 </Textarea>
 
 }
<Box textAlign="right">
{data?.data?.message?.length || null}
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee:</Box>
            <Box fontWeight="bold">₦{price|| null}{".00"}</Box>
        </Flex>
    </Box>
    <Box>
    <Button  onClick={handleSendSms} variant="primary" size="normal">
    {
    loading?"Sending please wait...":"Resend Email"
   }
    </Button>
    </Box>

</Box>
</>
  )
}

export default ResendEmail 