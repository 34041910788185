import React, { useEffect }  from "react";

import { Box, Flex, Text, Toast } from "@chakra-ui/react";
import TopRightModal from "../../../../components/Modals/MiddleModal/TopRIghtModal";
import { Button } from "../../../../components";
import { TbEdit } from "react-icons/tb";
import { HiOutlineDownload } from "react-icons/hi";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";
import copy from "clipboard-copy"
import useGetCardById from "../../../../hooks/creator-hooks/useGetCardById";
import { IoCopyOutline } from "react-icons/io5";
import { FaDiagramProject } from "react-icons/fa6";
import useDownloadContact from "../../../../hooks/creator-hooks/useDownloadContact";
import TopRightBulkModal from "../../../../components/Modals/MiddleModal/TopRightBulkModal";


interface props {
  onclose: () => void;

onclick?:()=>void
children:any
}
const BulkSmsNav = ({ onclose, children,  onclick }: props) => {
   
   
  
 



    const navigate = useNavigate()


  return (
    <TopRightBulkModal onclose={onclose}>
 {children}
     
     
    </TopRightBulkModal>
  );
};

export default BulkSmsNav;
