// export const emailReducer = () => {};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { emailInterface, walletStateInterface } from "../../types/auth/authInterfaces";

export const initialState: emailInterface = {
email:"",
name:""
};

const emailReducer = createSlice({
  name: "emails",
  initialState,
  reducers: {
 
    updateEmail: (state = initialState, action: PayloadAction<any>) => {
      state.email = action.payload;
    },
  },
});

export const {  updateEmail } = emailReducer.actions;
export default emailReducer.reducer;
