import { useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";

const useResetPassword = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const naviagte = useNavigate();

  const resetPassword = async (values: FormikValues) => {
    setIsLoading(true);
    await axiosBaseInstance
      .post("Onboarding/Reset-Password", values, {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (res?.data?.data?.isPassWordChanged) {
          setIsSuccess(true);
          showNotification({
            message: "Password reset successful. Proceed to login",
            color: "green",
          });
          naviagte("/signin");
        } else {
          showNotification({
            message: res?.data?.Message || "An error occurred",
            color: "yellow",
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        showNotification({
          message:
            err?.response?.data?.Message || err?.message || "An error occurred",
          color: "yellow",
        });
      });
  };
  return { resetPassword, isLoading, isSuccess };
};

export default useResetPassword;
