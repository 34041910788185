import { Box } from "@chakra-ui/react";
import {
 
  OtherHeader,
  PageHeader,
} from "../../../components";

// import { Link } from "react-router-dom";



import DetailsForm from "./DetailsForm";

 
const CreateCard = () => {
  
 


 
 




  return (
    <Box>
            
      <OtherHeader />

        <PageHeader text="Create New Card" link="/dashboard/creator/become-a-creator" />

       
  
    


<DetailsForm/>


    </Box>
  );
};

export default CreateCard;
