import { Box, Flex, Image, Text } from "@chakra-ui/react";
import styles from "../modal.module.scss";
import emailBox from "../../../assets/svgs/email-sent.svg";
import { Button } from "../..";
import { Link } from "react-router-dom";

const EmailSentModal = () => {
  const email = localStorage.getItem("email");
  return (
    <>
      {" "}
      <Flex
        className={`${styles.modal_overlay}`}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          padding="26px 30px"
          backgroundColor="#fefffe"
          width="full"
          borderRadius="20px"
          marginX={5}
          sx={{
            "@media screen and (min-width: 768px)": {
              maxWidth: "441px",
            },
          }}
        >
          <Image src={emailBox} alt="opened email" margin="auto" />

          <Box textAlign="center">
            <Text fontSize="34px" fontWeight="500">
              Email Sent
            </Text>
            <Text fontSize="14px">
              We have sent an{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                OTP
              </span>{" "}
              to{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {email}
              </span>{" "}
              to activate your account. Please check your inbox or spam
            </Text>
            <Link to="/reset-password">
              <Box marginTop="20px" marginBottom="12px">
                <Button variant="primary" type="button" size="normal">
                  Ok
                </Button>
              </Box>
            </Link>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default EmailSentModal;

// export default EmailSentModal
