import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";


interface CardForm {
    id: any;

  name: string
  title: string
  phoneNumber: string
  description: string
  contactLimit: number,
  totalFee: any

  }
  

const useEditCard = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const EditCard = async (values: CardForm) => {
    setLoading(true)
    return await axiosBaseInstance
      .put("/Creator/Update-Card", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        setLoading(false)

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { EditCard , loading};
};

export default useEditCard;
