import React, { useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer } from 'recharts';
import useFetchwhatsappLinkDetail from '../../../hooks/whatsapplink-hooks/useFectchLinkDetails';
import { useParams } from 'react-router-dom';
import UseFetchFreeAnalysis from '../../../hooks/whatsapplink-hooks/useFetchFreeAnalysis';
import UseFetchBrandAnalysis from '../../../hooks/whatsapplink-hooks/useFetchBrandLink';
import moment from 'moment';
import { Box, Flex, Spinner } from '@chakra-ui/react';

const colors = ['#4CB051', '#4CB051', '#4CB051', '#4CB051' ,'#4CB051', '#4CB051', '#4CB051'];




const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const RectangleBar = (props) => {
  const { fill, x, y, width, height } = props;
  const slimWidth = width * 0.2; // Adjust the value (0.8) to make the bars slimmer or wider

  const newX = x + (width - slimWidth) / 2; // Center the bars horizontally

  return <rect x={newX} y={y} width={slimWidth} height={height} fill={fill} />;
};


export default function App() {
 
  const {id}= useParams()
  console.log("params id", id)
const { fetching, data:user}=useFetchwhatsappLinkDetail(id)
const code =user?.code
const brandName =user?.brandName
console.log("na the code be this", code)
const {data:analysis,clicked} = UseFetchFreeAnalysis(code)
const {data:brandAnalysis, clicks} = UseFetchBrandAnalysis(brandName)
console.log("this one na brand", brandAnalysis)
console.log("this one na from chart", user)
console.log("this one na from chart analysis ooo", analysis)
const freeAnalysisdata = analysis?.data?.statisticData
const brandAnalysisData = brandAnalysis?.data?.statisticData


const freeData = freeAnalysisdata?.map((item) => ({
  clickdate: moment(item.clickdate).format("MMM DD, YYYY"),
  totalClicks: item.totalClicks,
}));
console.log("maindata",freeData)
const brandData = brandAnalysisData?.map((item) => ({
  clickdate: moment(item.clickdate).format("MMM DD, YYYY"),
  totalClicks: item.totalClicks,
}));

console.log("na paid data chart be this", brandData)
  return user?.type==="free"? (

  <>
  {
    fetching?(<Flex justifyContent="center" items="center">

      <Box>
        <Spinner size="xl"/>
      </Box>
    </Flex>):
    clicked ?  <BarChart
    width={500}
    height={300}
    data={freeData}
    margin={{
      top: 20,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="2 2" />
    <XAxis dataKey="clickdate" />
    <YAxis domain={[0, 100]} axisLine={false} /> {/* Set axisLine prop to false */}
    <Bar dataKey="totalClicks" fill="#8884d8" shape={<RectangleBar />} label={null}> {/* Set label prop to null */}
      {freeData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
      ))}
       <LabelList dataKey="totalClicks" position="top" />
    </Bar>
  </BarChart>:
    <Box fontSize={12} width="90%" ml={12}>
No clicks data for selected whatsapp shortlink.  Share and promote your link to have more customers reach out to you
    </Box>
  
  }

  </>
    
  ):(
    <>
    {
     
     fetching?(<Flex justifyContent="center" items="center">

     <Box>
       <Spinner size="xl"/>
     </Box>
   </Flex>):
     
     clicks?  <div style={{ width: '100%', maxWidth: '500px', height: '300px', overflowX: 'auto' }}>
   <ResponsiveContainer width="100%" height={300}>
  <BarChart
    data={brandData}
    margin={{
      top: 20,
      right: 30,
      left: 20,
      bottom: 5,
    }}
  >
    <CartesianGrid strokeDasharray="2 2" />
    <XAxis dataKey="clickdate" />
    <YAxis domain={[0, 100]} axisLine={false} />
    <Bar dataKey="totalClicks" fill="#8884d8" shape={<RectangleBar />} label={null}>
      {brandData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
      ))}
      <LabelList dataKey="totalClicks" position="top" />
    </Bar>
  </BarChart>
</ResponsiveContainer>
   </div>:<Box fontSize={12}>
    No clicks data for selected whatsapp shortlink.  Share and promote your link to have more customers reach out to you
    </Box>
    }
    </>

  )
}
App.demoUrl = 'https://codesandbox.io/s/bar-chart-with-customized-shape-dusth';
