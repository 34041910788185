import React, { useState } from 'react'
import {  OtherHeader, PageHeader } from '../../../components'
import { GoPlus } from "react-icons/go";
import { Box, Button, Flex, Image, Link, Text, } from '@chakra-ui/react';
import { RiFolderOpenLine } from "react-icons/ri";
import { Progress } from '@mantine/core'
import people from "../../../assets/images/people.png"
import CreatorModal from '../Home/components/CreatorModal';
import { useNavigate } from 'react-router-dom';

import useGetPromotedCards from '../../../hooks/creator-hooks/useGetPromotedCards';
import comingsoon from "../../../assets/images/comingsoon.png"
import useDownloadHistory from '../../../hooks/creator-hooks/useDownloadHistory';
import moment from 'moment';

const DownloadContact = () => {
const {data}= useDownloadHistory()
console.log(data)

  return (
 <>

      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/creator/become-a-creator" text="Downloaded Contacts" />

</Flex> 
    </Box>
<Box>
    <Flex flexDirection="column" justifyContent="center"  alignItems="center">
<Box width="100%" mb={6}>
    <Flex justifyContent="start">
        <Box fontWeight="bold">Total: {data?.length}</Box>
    </Flex>
</Box>
<Box width="100%">
    <Flex flexDirection="column">
        {
            data?.map((props:any)=>{
                const Save = () => {
                    const downloadUrl = props.data?.downloadUrl;
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute("download", "contact");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  };
                return (
                    <>
                    <Box mb={4} fontWeight="600">
                   {props.cardName}
                </Box>
                <hr/>
                <Box mt={4}>
                    <Flex justifyContent="space-between">
                        <Box>Contacts</Box>
                        <Box>
                            {props.contacts} {" "} contacts
                        </Box>
                    </Flex>
                </Box>
                <Box mt={4}>
                    <Flex justifyContent="space-between">
                        <Box>Date</Box>
                        <Box>
                            {moment(props.createdDate).subtract(10, 'days').calendar()}
                        </Box>
                    </Flex>
                </Box>
                <Box marginBottom="20px" marginTop="30px" width="100%">
                <Button onClick={Save} width="100%" p={2} h={16} borderRadius="30px" color="white" bg="#1bc106">
              Save Contact
                </Button>
                </Box>
                    </>
                )

                
            })
        }
       


    </Flex>
 
</Box>

    </Flex>
</Box>


 </>
  )
}

export default DownloadContact