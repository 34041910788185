import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {useState} from "react"

const useCreateBrandWhatsappLink = () => {
  const user = useSelector((state: RootState) => state.user);

// const [loading]
  const createBrandWHatsappLink = async (values: FormikValues) => {

    return await axiosBaseInstance
      .post("/WassapviewLinks/CreateBrandLink", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        console.log(res)
        return res;
      })
      .then((err) => {
        return err;
      });
  };

  return { createBrandWHatsappLink };
};

export default useCreateBrandWhatsappLink;
