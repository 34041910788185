import { Flex, Text } from "@chakra-ui/react";
import { SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom";
import { moreItemInterface } from "../../types/components/componentInterfaces";
import { FaCrown } from "react-icons/fa";

const MoreItem = ({
  name,
  Icon,
  link,
  color,
  onclick,
  target,
}: moreItemInterface) => {
  return (
    <Link to={link} onClick={onclick} target={target}>
      <Flex alignItems="center" margin="10px 0 " padding="13px 0">
        <Flex
          width="36px"
          height="36px"
          rounded="full"
          alignItems="center"
          justifyContent="center"
          backgroundColor={color}
          marginRight="30px"
        >
          <Icon
            fontSize="28px"
            fontWeight={400}
            style={{
              color: Icon === FaCrown ? "var(--chakra-colors-yellow-100)" : "",
            }}
          />
        </Flex>
        <Text color="black.main" fontWeight={400}>
          {" "}
          {name}
        </Text>
        <SlArrowRight fontSize="20px" style={{ marginLeft: "auto" }} />
      </Flex>
    </Link>
  );
};

export default MoreItem;
