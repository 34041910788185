import { BottomModal as ModalLayout, Button } from "../../../../components";
import { Link } from "react-router-dom";

interface props {
  
  hideModal: () => void;
 
}

const CreatorModal = ({ hideModal }: props) => {
  return (
    <ModalLayout hideModal={hideModal}>
   
        <>
          

          <Link to="/dashboard/creator/create-card">
            <Button variant="primary" size="normal">
             Create a New Card
            </Button>
          </Link>

        </>
     
    </ModalLayout>
  );
};

export default CreatorModal;
