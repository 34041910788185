import React, {useState} from 'react'
import { axiosBaseInstance } from '../../services/axiosBaseInstance'
import { File } from '../../types/auth/authInterfaces'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
export default function useDownload() {
const [loading, setLoading]= useState(false)
const [result, setResult] = useState<any>([])
    const user = useSelector((state:RootState)=>state.user)
  const download= async(data:File)=>{
    setLoading(true)
 return await axiosBaseInstance.post("/WhatsAppVCF/DownLoad-Free-File", data, {
headers:{
    Authorization:`Bearer ${user.authToken}`
}
 }).then((res)=>{
    setLoading(false)
setResult(res.data.data) 
console.log("result", result)
    console.log(res.data)
    return res.data
 }).catch((error:any)=>{
    console.log(error)
 })
  }
  
    return{ download, loading, result}
}
