import { Box } from "@chakra-ui/react";
import styles from "../modal.module.scss";

interface props {
  children: React.ReactNode;
  hideModal: () => void;
}
const BottomModal = ({ hideModal, children }: props) => {
  return (
    <Box className={styles.modal_overlay}>
      <Box width="full" height="full" onClick={hideModal} flexGrow="1"></Box>

      <Box
        sx={{
          "@media screen and (min-width: 768px)": {
            paddingLeft: "180px",
          },
        }}
      >
        <Box
          padding="10px 20px"
          backgroundColor="#fefffe"
          position="absolute"
          bottom="0"
          width="full"
          borderRadius="20px 20px 0 0"
          sx={{
            "@media screen and (min-width: 768px)": {
              maxWidth: "450px",
              margin: "auto",
              left: "180px",
              right: 0,
            },
          }}
        >
          <Box
            height="5px"
            width="80px"
            backgroundColor="#D3D3D3"
            borderRadius="10px"
            margin="0 auto 20px"
            draggable={true}
            onDragStart={hideModal}
          ></Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default BottomModal;
