export const countryCodes = [
  "+41",
  "+27",
  "+227",
  "+234",
  "+965",
  "+81",
  "+233",
  "+33",
  "+20",
  "+420",
  "+237",
  "+213",
  "+380",
  "+228",
  "+256",
  "+44",
  "+971",
  "+260",
  "+263",
  "+1 876",
];
