import { Text } from "@chakra-ui/react";

interface props {
  name: string;
}
const Service = ({ name }: props) => {
  return (
    <Text mb="10px" color="grey.dark" fontSize="14px" fontWeight="400">
      {name}
    </Text>
  );
};

export default Service;
