import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useGetSingleWhatsappMessage
 = (id:any) => {
  const [data, setData] = useState<any>();
  const user = useSelector((state: RootState) => state.user);
//   console.log(user)
  useEffect(() => {
    const fetched = async () => {
      try {
        await axiosBaseInstance
          .get(`WhatsappMessages/get-Message/${id}`, {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            setData(res.data);
          });
      } catch (error: any) {
        console.log(error);
      }
    };

    fetched();
  }, [id]);

  return { data };
};

export default useGetSingleWhatsappMessage
;
