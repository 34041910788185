import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex } from '@chakra-ui/react'
import { Formik } from 'formik'
import CustomInput from '../../../components/core/input/CustomInput'
import * as Yup from "yup";

import SuccessCardForm from '../Home/components/SuccessForm'
import { useNavigate,useParams } from 'react-router-dom'
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById'
import useGetCardFormFields from '../../../hooks/creator-hooks/useGetCardFormFields'
import useSubmitCardContactForm from '../../../hooks/creator-hooks/useSubmitCardContactForm'
// import { useParams } from 'react-router-dom'

const SingleCardForm = () => {
  const { SubmitCard, loading } = useSubmitCardContactForm();
  const [formData, setFormData] = useState<any>({});
const navigate=useNavigate()
const {id}= useParams()
const {data} = useGetCardById(id)
const {data:cardForm} =useGetCardFormFields(data.cardLink)

const [formErrors, setFormErrors] = useState<any>({});

const validationSchema = Yup.object().shape({
  ...cardForm?.data?.cardContactFields?.reduce((fields: any, field: any) => {
    fields[field.fieldName] = Yup.string().required(`${field.fieldName} is required`);
    return fields;
  }, {}),
});
  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prevFormData:any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [isValidatingSchema, setIsValidatingSchema] = useState(false);
  const [success, setSuccess] = useState(false)
  const closeModal = ()=>{
    navigate("/dashboard/creator/become-a-creator")
  }
  const handleFormSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const defaultValues = {
        cardLink: data.cardLink,
        cardId: id,
      };
      const valuesToSend = { ...defaultValues, ...formData };
      const res = await SubmitCard(valuesToSend).then(()=>{
        setSuccess(true)
      });
      // console.log(res);
    
    } catch (error:any) {
      if (error.inner) {
        const errors = error.inner.reduce((acc: any, validationError: any) => {
          acc[validationError.path] = validationError.message;
          return acc;
        }, {});
        setFormErrors(errors);
      }
    }
  };
  
  return (
   <>
   {success && <SuccessCardForm onclose={closeModal}/>

   }
      <OtherHeader />

<PageHeader text="Create Card Form" link="/dashboard/creator/become-a-creator" />
<Box>
<Flex flexDirection="column">

<Box width="100%" fontWeight="600" fontSize="12px" >
                <Flex justifyContent="space-between ">
                    <Box>{data.name}</Box>
                    <Box>{data.title}</Box>
                </Flex>
                <Box mt={2}>
                <hr/>

                </Box>
            </Box>
            <Box width="100%" fontSize="12px"textAlign="left" >
                <Flex justifyContent="center">
                <Box width="80%" marginTop={12} textAlign="center">
        {data.description}
                </Box>
              
                </Flex>
         
            </Box>
            <Box mt={12}  width="100%" fontWeight="600" fontSize="12px" >
    
                    <Box>Fill in your details</Box>
           
                
                <Box mt={2}>
                <hr/>

                </Box>
            </Box>
</Flex>


            

  {cardForm?.data?.cardContactFields?.map((props: any) => (
    <Box mt={4} key={props.fieldName}>
      <Box>{props.fieldName}</Box>
      <CustomInput
        width="auto"
        placeholder={`Enter ${props.fieldName}`}
        name={props.fieldName} 
        value={formData[props.fieldName] || ""} 
        onChange={handleChange}
      />
         {formErrors[props.fieldName] && (
      <Box color="red" fontSize="12px" mt={1}>
        {formErrors[props.fieldName]}
      </Box>
    )}
    </Box>
  ))}
  <Box marginBottom="20px" marginTop="30px">
    <Button onClick={handleFormSubmit}  variant="primary" size="normal">
    {
      loading?"Please wait ...":"Submit" 
    }
    </Button>
  </Box>



                    
                  
                  
                       
                    
        
                
              
               
               
               

         

              
         




              
          

</Box>
   </>
  )
}

export default SingleCardForm