import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";

const useFetchTvReviews = () => {
  const user = useSelector((state: RootState) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const getReviews = async (tvId: number) => {
    setIsLoading(true);
    return await axiosBaseInstance
      .get(`/WhatsappTv/whatsappreviews/${tvId}`, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { getReviews, isLoading };
};

export default useFetchTvReviews;
