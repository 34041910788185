import { Box, Flex, Image, InputGroup, Spinner, Text, Textarea } from "@chakra-ui/react";
import {
    ComingSoonModal,
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { PiUsers } from "react-icons/pi";
import { Button, BottomModal as ModalLayout } from "../../../components";
import { useState } from "react";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import Wassaplinksuccess from "../../../components/Modals/ComingSoonModal/wassaplinksuccess";
import sort from "../../../assets/images/sort.png"
import {BsLink45Deg} from "react-icons/bs"
import useFetchwhatsappLink from "../../../hooks/whatsapplink-hooks/useFectchAllLinks";
import { showNotification } from "@mantine/notifications";
import idea from "../../../assets/images/idea.png"

import copy from "clipboard-copy";
// import { DatePicker } from 'antd';
import moment from 'moment';
import CustomInput from "../../../components/core/input/CustomInput";
import { CiCircleInfo } from "react-icons/ci";


import dayjs from "dayjs"
import useFetchwhatsappLinkDetail from "../../../hooks/whatsapplink-hooks/useFectchLinkDetails";
import { useParams } from "react-router-dom";
import Example from "../../Auth/components/Chart";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useViewAnalytics from "../../../hooks/whatsapplink-hooks/usePayAnalytics";
const LinkDetails = () => {
  const {id}= useParams()
  console.log("params id", id)
    const [isComingSoon, setIsComingSoon] = useState(false);

    const { fetching, data}=useFetchwhatsappLinkDetail(id)

    const [isLoading, setIsLoading] = useState(false);
    const [isValidatingSchema, setIsValidatingSchema] = useState(false);
    const {viewAnalytics}= useViewAnalytics()



// console.log(" what i need",data)
const shortLink =data?.shortLink.slice(8) 
console.log("herererere",shortLink)

const wallet = useSelector((state: RootState) => state.wallet);
// console.log(wallet)
const Balance = wallet.balance
 const contactSchema = Yup.object().shape({
    
    amount: Yup.number(),
    code: Yup.string(),
   
    viewStartDate: Yup.string()
    .required("Please add a start date for this subscription")
    .test("viewStartDate", "Invalid start date", (value) => {
      const startDate = dayjs().startOf('day');
      const endDate = dayjs(value).startOf('day');
  
      // Check if the selected date is in the past or today
      return endDate.isSame(startDate) || endDate.isBefore(startDate);
    }),
    viewEndDate: Yup
    .string()
    .required("Please select end date for analytics")
    .test("viewEndDate", "Invalid end month date, pick a future date", (value, testContext) => {
      const startDate = dayjs(testContext.parent.viewStartDate).startOf('day'); 

      const endDate = dayjs(value).startOf('day');
      
      return  endDate.isAfter(startDate);
    })
    })
  const controlComingSoonModal = () => {
    setIsComingSoon(!isComingSoon);
  };
  const handleCreateWhatsappLink = (values: FormikValues,{resetForm}:any) => {
    setIsLoading(true)
    viewAnalytics(values).then((res) => {
// window.location.reload()
     setIsLoading(false)
    
    }).catch((err:any)=>{
    
      setIsLoading(false)
    });
  };
  
  return data?.isViewActive ===false? (
    <Box>
              {isComingSoon && <Wassaplinksuccess onclick={controlComingSoonModal} />}
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="View link Analytics" link="/dashboard" />
        

      </Flex>
    

      <Box>
    <Box>
<Image src={idea} alt="idea"/>
    </Box>
</Box>

<Box padding={6} bg="#F1FFF3" fontSize={12} color="#4CB051" mt={4}>
To open a WhatsApp chat, typically, you'd require the user's phone number and go through a saving and searching process. However, WhatsApp links, like "click to chat," simplify this with a single click. wassapview offers free links with a wassapview domain and QR code, with no registration needed.  
</Box>

<Formik 

            initialValues={{ code:data?.code? data?.code:data?.brandName, viewStartDate:"", viewEndDate:"", amount:0 }}
            validationSchema={contactSchema}
            onSubmit={(values, { resetForm }) => {
              const startDate = dayjs(values.viewStartDate);
              const endDate = dayjs(values.viewEndDate);
              const durationInDays = endDate.diff(startDate, 'day');
              const amount = durationInDays * 50;
              
              values.amount = amount
              handleCreateWhatsappLink(values, { resetForm });
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form
              style={{
                width:"100%",
           
              }}
                onSubmit={(e) => {
                  e.preventDefault();

                  if (Object.keys(errors).length > 0) {
                    setIsValidatingSchema(true);
                  } else {
                    setIsValidatingSchema(false);
                    handleSubmit();
                  }
                }}
              >
              


<CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="Start Date"
                name="viewStartDate"
                type="date"
                value={`${dayjs(values.viewStartDate).format("YYYY-MM-DD")}`}
                onChange={handleChange}
                error={errors.viewStartDate ? errors.viewStartDate : null}
              />
              <CustomInput
                width="auto"
                placeholder="DD/MM/YY"
                label="End Date"
                name="viewEndDate"
                type="date"
                value={`${dayjs(values.viewEndDate).format("YYYY-MM-DD")}`}
                onChange={handleChange}
                error={errors.viewEndDate ? errors.viewEndDate : null}
              />
<Box marginBottom="10px" marginTop="50px">
<Box>
  <Text fontSize={12} marginBottom={2} fontWeight="600">
    Cost Summary
  </Text>
  <Box width="100%" height="1px" bg="silver">

  </Box>
  </Box>
  <Flex mt={4} fontSize={10} fontWeight="bold" justifyContent="space-between">
    <Box>
      Amount:
    </Box>
    <Box>
    {values.viewStartDate && values.viewEndDate ? dayjs(values.viewEndDate).diff(values.viewStartDate, 'day') * 50 : 0} 
    </Box>
  </Flex>

  <Box>
 <Flex mt={4}>
 <CiCircleInfo color="#63BAFD" />
    <Box fontSize={10} ml={2} mb={6}>
    The fee for the payment of the card you want to create would be deducted from wallet.
    </Box>
 </Flex>
    </Box>

  {dayjs(values.viewEndDate).diff(values.viewStartDate, 'day') * 50 > Balance ? (
    <div>
      <Text color="red" fontSize={10}>Insufficient balance</Text>
      <Box>
 <Flex mt={4}>
 
 </Flex>
    </Box>
      <Button type="submit" variant="primary" size="normal" disabled color="red">
     Insufficient Balance
      </Button>
    </div>
  ) : (
    <Button type="submit" variant="primary" size="normal">
      {isLoading ? "Please wait..." : "Submit"}
    </Button>
  )}
</Box>
              </form>
            )}
          </Formik>

{/* <Box marginBottom="20px" marginTop="50px">
<Box>
  <Text fontSize={12} marginBottom={2} fontWeight="600">
    Cost Summary
  </Text>
  <Box width="100%" height="1px" bg="silver">

  </Box>
  <Box>
    <Flex justifyContent="space-between" mt={4} fontSize={10} fontWeight="bold">
      <Box>Total Fee</Box>
      <Box>
        N300
      </Box>
    </Flex>


  </Box>
</Box>

                  <Button  type="submit" variant="primary" size="normal">
                    {isLoading ? "Please wait..." : "Submit"}
                  </Button>
                </Box> */}
    </Box>
  ):(
    <Box>
    {isComingSoon && <Wassaplinksuccess onclick={controlComingSoonModal} />}
<OtherHeader />

<Box  position="fixed">
<Flex
justifyContent="space-between"
alignItems="center"
padding="10px 0"
marginBottom="10px"

>
  <Box w="350px"  >
    <Flex justifyContent="center" 
    
    >

    <PageHeader text="View link Analytics" link="/dashboard" />

    </Flex>


  </Box>


{/* <Image width={5} src={sort} alt="sort"/> */}
</Flex>
<Box width="100%" height="100%" textAlign="center"  >
  <Flex justifyContent="center" align="center">
  <Box w="350px"    >
   <Flex justifyContent="center">
   <PiUsers  fontSize={30}/>

   </Flex>

  </Box>
  </Flex>
  <Flex justifyContent="center" >
  <Box  w="100%" ml={4}    mt={4} fontSize={20} mb={8} color="#4cB051" textDecoration="underline" fontWeight={600}  >
{
     shortLink
    }
</Box>
  </Flex>



</Box>

</Box>




<Box position="relative">
<Box position="absolute" top="200px" left="-50px" right={0} w="400px">
<Example/>


</Box>

</Box>



</Box>
  )
};

export default LinkDetails;
