import { Box, Image, Text } from "@chakra-ui/react";
import { cardInterface } from "../../types/components/componentInterfaces";

const FeatureCard = ({
  icon,
  title,
  description,
  color,
  onClick,
}: cardInterface) => {
  return (
    <Box
      backgroundColor={color}
      borderRadius="10px"
      padding="15px"
      filter="drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05))"
      onClick={onClick}
      cursor="pointer"
      sx={{
        "@media screen and (min-width: 768px)": {
          padding: "20px",
        },
      }}
    >
      <Image src={icon} alt={title} />
      <Text
        fontSize="14px"
        fontWeight="600"
        marginBottom="7px"
        marginTop="12px"
      >
        {title}
      </Text>
      <Text fontSize="11px">{description}</Text>
    </Box>
  );
};

export default FeatureCard;
