import { BottomModal as ModalLayout, Button } from "../../../../components";
import { Link } from "react-router-dom";

interface props {
  
  hideModal: () => void;
  children?:any
  buttonText?:String
  variant?:any
  link?:any
  onclick?:any
 
}

const BulkMessageModal = ({ hideModal ,onclick, children, buttonText,link, variant}: props) => {
  return (
    <ModalLayout hideModal={hideModal}>
   
        <>
          
{children}
          <Link to={link}>
            <Button onClick={onclick} variant={variant} size="normal">
            {buttonText}
            </Button>
          </Link>

        </>
     
    </ModalLayout>
  );
};

export default BulkMessageModal;
