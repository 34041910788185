
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";



  

const useDeleteBulkEmail = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const DeleteContact = async (id:any) => {
    setLoading(true)
    return await axiosBaseInstance
      .delete(`/BulkEmail/Delete-Message/${id}`,{
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        setLoading(false)

        // console.log(res)
        if(res.data.status === true){
showNotification({
    message: res.data.message ,
    color: "green",
})
        }else{
            showNotification({
                message: res.data.message ,
                color: "red",
            })

        setLoading(false)
            
        }
        setLoading(false)


        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
      
  };

  return { DeleteContact , loading};
};

export default useDeleteBulkEmail;
