import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";



interface searchBrand{
    brandItem:any
}
const UseFetchFreeAnalysis:any = (LinkCode:any) => {
  const user = useSelector((state: RootState) => state.user);
  const [fetching, setFectching] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [data, setData] = useState<any>(null)

  useEffect(()=>{
    const fetchAllLinksAnalysis = async () => {
        setFectching(true)
            return await axiosBaseInstance
              .get(`/WassapviewLinks/FreeAccount/ClicksData/${LinkCode}`, {
               
                headers: {
                  Authorization: `Bearer ${user?.authToken}`,
                },
              })
              .then((res) => {
                if(res.data.status === true ){
                console.log("fromAPI",res.data)

                    setClicked(true)
                  
                    setData(res.data)
                    setFectching(false)
                }else{
                    setClicked(false)
                    console.log("wetin dey sup", res)
                }
              
               
                return res.data
              }).catch((err:any)=>{
        
              });
          };
        fetchAllLinksAnalysis()
        
  }, [user?.authToken, LinkCode])

  return {fetching , data, clicked};
 
};

export default UseFetchFreeAnalysis;
