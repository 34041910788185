import { useDispatch, useSelector } from "react-redux";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { RootState } from "../../redux/store";
import { updateBalance } from "../../redux/reducers/walletReducer";

const useFetchBalance = () => {
  const user = useSelector((state: RootState) => state.user);
  const wallet = useSelector((state: RootState) => state.wallet);
  const dispatch = useDispatch();
  const fetchBalance = async () => {
    return await axiosBaseInstance
      .get(`/Wallet/Balance`, {
        params: {
          walletId: wallet.walletId,
        },
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        dispatch(updateBalance(res.data.data.balance));
        // console.log("balance",res);
        return res;
      })
      .catch((err) => {
        // console.log(err);
        return err;
      });
  };

  return { fetchBalance };
};

export default useFetchBalance;
