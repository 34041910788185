import React, { useState } from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { phoneNumberInterface } from '../../../types/auth/authInterfaces';
import useSendBulkMessages from '../../../hooks/bulkHooks/useSendBulkMessages';
import CustomInput from '../../../components/core/input/CustomInput';

const DispatchMessage = () => {
  const phoneNumber = useSelector((state: RootState) => state.phoneNumbers);
  console.log(phoneNumber)

  const {sendBulkSms, loading} = useSendBulkMessages()
const allContacts = phoneNumber.phoneNumber.map((props:any)=>{
  return props.phoneNumber
})
console.log(allContacts)
const initialFormValues = {
  recipients: allContacts,
  message: "",
  title: "",
  amount: 0,
};

console.log(allContacts.toString())
const price =Math.ceil( 2.01*allContacts?.length)
  const [formValues, setFormValues] = useState<any>({
    recipients:allContacts.toString(),
message:"",
title:"",
amount:price

  });
  console.log(formValues.amount)
  console.log(formValues)

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setFormValues((prevValues:any) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSendSms = async()=>{
    try {

  await sendBulkSms(formValues).then((res)=>{
  setFormValues(initialFormValues)
  })
      
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-messages/fetch-contacts" text="Send Message" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your SMS message
    </Box>
    <Box mt={4} fontWeight={600}>
        Contacts
    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2" p={2} color="gray" fontSize={12}>
    {
    phoneNumber?.phoneNumber?.map((props: phoneNumberInterface, index: number) => (
      <React.Fragment key={index}>
        {props.phoneNumber}{index !== phoneNumber?.phoneNumber?.length - 1 && ','}
      </React.Fragment>
    ))
  }
   <Box bg="#00000070" textAlign="center" p="3px" w="80px" borderRadius={10} color="#fff">{phoneNumber?.phoneNumber?.length} {" "} {"contacts"}</Box>
    </Box>

    <Box  mt={4} fontWeight={600}>Title</Box>
    <CustomInput onChange={handleChange}
    name='title'
    value={formValues.title}
    />
    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea name='message' value={formValues.message} onChange={handleChange} h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
{formValues.message?.length}
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee :</Box>
            <Box fontWeight="bold">₦{formValues?.amount}{".00"}</Box>
        </Flex>
    </Box>
    <Box>
    <Button onClick={handleSendSms} variant="primary" size="normal">
   {
    loading?"Sending please wait...":"Send Message"
   }
    </Button>
    </Box>

</Box>
</>
  )
}

export default DispatchMessage 