import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, OtherHeader, PageHeader } from "../../../../components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import "./globals.d";
import copy from "clipboard-copy";
import { axiosBaseInstance } from "../../../../services/axiosBaseInstance";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";


const CheckOut = () => {
  const user = useSelector((state: RootState) => state.user);

  const fetchProfile = async () => {
    return await axiosBaseInstance
      .get("/Wallet/VirtualAccount-Numbers", {
        headers: {
          Authorization: `Bearer ${user?.authToken}`,
        },
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((error: any) => {
        console.log(error);

        return error;
      });
  };

  const [copyButtonText, setCopyButtonText] = useState(
    "Copy Virtual Account Number"
  );
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["virtualAccountNumbers"], // Change this to a meaningful key
    queryFn: fetchProfile,
  });

  // console.log("react query", data);
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    // console.log(user.authToken);
    setLoading(true);

    try {
      const response = await axios.post(
        "https://wassapview.azurewebsites.net/app/v1/Wallet/CreateVirtual-Account-Number",
        {},
        {
          headers: {
            "X-Key": "demo-08PM02b1a494-f2ef-4836-88",
            Authorization: `bearer ${user.authToken}`,
          },
        }
      );
      setLoading(false);
      if (response.data.data) {
        showNotification({
          title: "Success",
          message: "Virtual account created successfully",
          color: "green",
        });
      } else {
        // console.log(response);
        showNotification({
          title: "Error",
          message: response.data.Message || "an error occurred. Try again",
          color: "red",
        });
      }

      // console.log(response.data);
    } catch (error: any) {
      setLoading(false);
      // console.error(error.message);

      showNotification({
        title: "Error",
        message: error.message || "an error occurred. Try again",
        color: "red",
      });
    }
  };

  const handleCopyClick = (textToCopy: string) => {
    copy(textToCopy);
    setCopyButtonText("Copied!");
  };


  const fund = useSelector((state: RootState) => state.fundaccount);
  const textToCopy = data?.map((props: any) => {
    return props?.accountNumber;
  });


  // console.log(" thiz is the holder", fund);
  // console.log(fund.transactionref);
  // console.log(fund)
  const handleCheckout = () => {
    const options = { 
      amount: fund.amount,
      currency: "NGN",
      domain: "live",
      key: "80bbabcd-48cc-4d5b-a2f7-32fc4dd45da9",

      email: fund.email,
      transactionref: fund.transactionref,
      customer_logo:
        "https://www.vpay.africa/static/media/vpayLogo.91e11322.svg",
      customer_service_channel: "+2348030007000, support@org.com",
      txn_charge: 6,
      txn_charge_type: "flat",
      onSuccess: function (response: any) {
        // console.log("Hello World!", response.message);
      },
      onExit: function (response: any) {
        // console.log("Hello World!", response.message);
      },
    };

    if (window.VPayDropin) {
      const { open } = window.VPayDropin.create(options); // use window.VPayDropin
      open();
    }
  };

  const handleCheckoutButtonClick = () => {
    handleCheckout();
  };
  return (
    <>
      <OtherHeader />

      <PageHeader text="Top up wallet" link="/dashboard/wallet" />
      <Flex flexDirection="column" justifyContent="space-between" height="80vh">
        <Box>
          <Text color="black.main" textAlign="center" margin="10px 0 15px">
            Select the top up method you want to use
          </Text>

          <Box
            borderRadius="10px"
            padding="10px"
            marginTop="20px"
            marginBottom="20px"
            border="grey.light"
            borderWidth="0.3px"
            minHeight="97px"
          >
            <Text marginRight="10px">Virtual Account Number</Text>

            <Text fontSize="38px" color="black.main" fontWeight="800">
              {data?.length > 0 ? (
                <>
                  <Box>{textToCopy}</Box>
                  {data?.map((props: any) => (
                    <>
                      <Text fontSize="14px" fontWeight="400">
                        {props.accountName}
                      </Text>
                      <Text fontSize="14px" fontWeight="400">
                        {props.bankName}
                      </Text>
                    </>
                  ))}
                </>
              ) : (
                <Text fontSize="16px" fontWeight="500" marginTop="10px">
                  You don't have a virtual account number. Create one now or
                  proceed to checkout.
                </Text>
              )}
            </Text>
          </Box>

          <Text fontSize="12px" color="grey.main">
            Transfer to this account number through Bank, POS, USSD or mobile
            app to credit your wallet
          </Text>
        </Box>

        <Box>
          {data?.length >0 ?  (
            <Button
              variant="primary"
              size="normal"
              onClick={() => handleCopyClick(textToCopy)}
            >
              {copyButtonText}
            </Button>
          ) : (
            <Button variant="primary" size="normal" onClick={handleSubmit}>
              {loading ? (
                <Text>Please wait... </Text>
              ) : (
                <Text>Create Virtual Account Number</Text>
              )}
            </Button>
          )}

          {/* <Button variant="secondary" size="normal" onClick={handleCheckoutButtonClick}>
            Proceed to checkout
          </Button> */}
        </Box>
      </Flex>
    </>
  );
};

export default CheckOut;
