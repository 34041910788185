import { Box, Flex,Input,Textarea } from "@chakra-ui/react";

import { Button } from "../../../components";
import { useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";

import CustomInput from "../../../components/core/input/CustomInput";


import { useNavigate } from "react-router-dom";
import "./range.css"
 
import { Progress } from '@mantine/core'
// import Progress from "man"

 
const DetailsForm = () => {
 const [percentages, setPercentages] = useState<any>(10)


  
    const [totalFee] = useState(50);
    const [formValues, setFormValues] = useState<any>({
      name: '',
      title: '',
      whatsappNumber: '',
      address: '',
      description: '',
      contactLimit: percentages,
      totalFee: totalFee
    });
    const handlePercentageChange = (e:any) => {
      const value = parseInt(e.target.value);
      setPercentages(value);
      setFormValues((prevFormValues:any) => ({
        ...prevFormValues,
        contactLimit: value
      }));
    };
    const handleChange = (event:any) => {
      const { name, value } = event.target;
      setFormValues((prevValues:any) => ({
        ...prevValues,
        [name]: value
      }));
    };
    const handleSubmit = (event:any) => {
      event.preventDefault();
      if (
        formValues.name.trim() === '' ||
        formValues.title.trim() === '' ||
        formValues.whatsappNumber.trim() === '' ||
        formValues.address.trim() === '' ||
        formValues.description.trim() === ''
      ) {

        // console.log('Please fill in all required fields');
        return;
      }
    
      
      // console.log(formValues);
      const stringifiedData = JSON.stringify(formValues);
      localStorage.setItem("contactform", stringifiedData)
      navigation("/dashboard/creator/card-form")
    };
 
    
 
  const navigation = useNavigate()
  
 
   
   

  return (
    <Box>
              

       
  
    





<Box w="100%" >

<form onSubmit={handleSubmit} style={{ width: "100%" }}>
  <Box mt={4} fontSize={12}>
    Name
  </Box>
  <CustomInput
    width="auto"
    placeholder="Enter Card Name"
    name="name"
    value={formValues.name}
    onChange={handleChange}
    required
  />
  {formValues.name.trim() === "" && (
    <Box color="red" fontSize={10} mt={1}>
      Please enter a name.
    </Box>
  )}

  <Box mt={4} fontSize={12}>
    Title
  </Box>
  <CustomInput
    width="auto"
    placeholder="Enter Card Title"
    name="title"
    value={formValues.title}
    onChange={handleChange}
    required
  />
  {formValues.title.trim() === "" && (
    <Box color="red" fontSize={10} mt={1}>
      Please enter a title.
    </Box>
  )}

  <Box mt={4} fontSize={12}>
    WhatsApp Number
  </Box>
  <CustomInput
    width="auto"
    placeholder="Enter WhatsApp Number"
    name="whatsappNumber"
    value={formValues.whatsappNumber}
    onChange={handleChange}
    type="number"
    required
  />
  {formValues.whatsappNumber.trim() === "" && (
    <Box color="red" fontSize={10} mt={1}>
      Please enter a WhatsApp number.
    </Box>
  )}

  <Box mt={4} fontSize={12}>
    Address
  </Box>
  <CustomInput
    width="auto"
    placeholder="Enter Your Address"
    name="address"
    value={formValues.address}
    onChange={handleChange}
    required
  />
  {formValues.address.trim() === "" && (
    <Box color="red" fontSize={10} mt={1}>
      Please enter an address.
    </Box>
  )}

  <Box mt={4} fontSize={12}>
    Description
  </Box>
  <Textarea
    width="100%"
    padding={3}
    height={40}
    background="#F1F1F1"
    borderRadius={5}
    focusBorderColor="#F1F1F1"
    border="none"
    outline={0}
    placeholder="Enter a Description"
    name="description"
    value={formValues.description}
    onChange={handleChange}
    required
  />
  {formValues.description.trim() === "" && (
    <Box color="red" fontSize={10} mt={1}>
      Please enter a description.
    </Box>
  )}
  <Box fontSize={12} mb={8} mt={4} fontWeight="600">
  Contact limit
</Box>
<Box mt={2} textAlign="center" fontWeight="600">
  {percentages}
</Box>
<input

  type="range"
  min="1"
  max="1000"
  value={percentages}
    onChange={handlePercentageChange}

  className="custom-range-input"
/>



  {/* <Progress value={percentage} color="#f0c608" /> */}
  
    <Box marginTop={4}>
      <Flex alignItems="center">
        <RiErrorWarningLine color="#09bfe8" />
        <Box fontSize={10} fontWeight="600" marginLeft={2}>
          Your contact limit can't exceed 1000 contact.
        </Box>
      </Flex>
    </Box>

  <Box marginTop={4} fontSize="10px">
    <Flex alignItems="center">
      <Box>Total Fee:</Box>
      <Box fontWeight="bold" marginLeft={2}>
      {totalFee*percentages}
      </Box>
    </Flex>
  </Box>

  <Box marginBottom="20px" marginTop="30px">
    <Button type="submit" variant="primary" size="normal">
      Next
    </Button>
  </Box>
</form>
      
         
        
            
      
</Box>

    </Box>
  );
};

export default DetailsForm;
