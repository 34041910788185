// export const emailReducer = () => {};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { whatsappNumberInterface, walletStateInterface } from "../../types/auth/authInterfaces";

export const initialState: whatsappNumberInterface = {
phoneNumber:"",
name:""
};

const whatsappNumberReducer = createSlice({
  name: "whatsappNumber",
  initialState,
  reducers: {
 
    updateWhatsappNumber: (state = initialState, action: PayloadAction<any>) => {
      state.phoneNumber = action.payload;
    },
  },
});

export const {  updateWhatsappNumber } = whatsappNumberReducer.actions;
export default whatsappNumberReducer.reducer;
