import { Image } from "@chakra-ui/image";
import { Box, Flex, Text } from "@chakra-ui/layout";
import CustomInput from "../../../components/core/input/CustomInput";
import { Button, GeneralLayout as Layout } from "../../../components";
import forget from "../../../assets/images/fo.png";
import useVerifyIdentity from "../../../hooks/auth-hooks/useVerifyIdentity";
import { Formik, FormikValues } from "formik";
import { schema } from "./utils/validationSchema";

const VerifyIdentity = () => {
  const { verifyUser, loadings } = useVerifyIdentity();
  const handleVerification = (values: FormikValues) => {
    const token = localStorage.getItem("idToken");

    if (token) {
      console.log(token);

      verifyUser({ code: values.code, idToken: token }).then(() => {
        localStorage.setItem("hasVisited", "true");
      });
    }
  };
  return (
    <>
      <Flex
        w="100%"
        height="100vh"
        flexDirection="column"
        justifyContent="space-between"
        paddingTop="50px"
        paddingBottom="20px"
      >
        <Formik
          validationSchema={schema}
          initialValues={{ code: "" }}
          onSubmit={(values) => {
            handleVerification(values);
          }}
        >
          {({ setFieldValue, handleSubmit, errors, values }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Box>
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Image objectFit="contain" w={250} src={forget} />
                </Flex>

                <Box w="100">
                  <Text fontSize="34px" fontWeight={700} marginBottom={4}>
                    Verify Identity
                  </Text>

                  <Box w="100%" mb={4}>
                    <CustomInput
                      width="auto"
                      border="none"
                      focusBorderColor="#F1F1F1"
                      placeholder="Enter code (6 digits only)"
                      background="#F1F1F1"
                      onChange={(e: any) => {
                        setFieldValue("code", e.target.value.trim());
                      }}
                      name="code"
                      id="code"
                      value={values.code}
                      max={6}
                    />
                    {errors.code && <div className="error">{errors.code}</div>}
                  </Box>
                </Box>
              </Box>

              <Button
                variant="primary"
                type="submit"
                size="normal"
                style={{ marginBottom: "12px" }}
              >
                {loadings ? "Please wait..." : "Continue"}
              </Button>
            </form>
          )}
        </Formik>
      </Flex>
    </>
  );
};

export default VerifyIdentity;
