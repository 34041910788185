import { Box, Flex, Image,Textarea } from "@chakra-ui/react";
import {
 
  OtherHeader,
  PageHeader,
} from "../../../components";
import { Button } from "../../../components";
import { useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import idea from "../../../assets/images/idea.png"
import link from "../../../assets/images/limk.png"

import ranking from "../../../assets/images/ranking.png"
import message from "../../../assets/images/message.png"
import CustomInput from "../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { countryCodes } from "../../../config/data";
import Wassaplinksuccess from "../../../components/Modals/ComingSoonModal/wassaplinksuccess";

import useFetchwhatsappSearch from "../../../hooks/whatsapplink-hooks/useFetchwhatsappSearch";
import useCreateWhatsappLink from "../../../hooks/whatsapplink-hooks/useCreateWhatsappLink";
// import useSubmitContact from "../../../../../hooks/vcf-hooks/useSubmitContact";
// import { Formik } from "formik";
import copy from "clipboard-copy";
import { showNotification } from "@mantine/notifications";

 
const Wassaplink = () => {
    const [isComingSoon, setIsComingSoon] = useState(false);
    const [isValidatingSchema, setIsValidatingSchema] = useState(false);
    const [isLoading, setIsLoading] = useState({
      createLink: false,
      searchLink: false,
    });
 
    const [brandItem, setBrandItem] = useState('');
    const { fetchSingleWhatsAppLink } = useFetchwhatsappSearch();
    const [userLink, setUserLink] = useState<any>()
const {createFreeWHatsappLink}= useCreateWhatsappLink()
 
   
    const handleInputChange = (event:any) => {
      setBrandItem(event.target.value);
    };

    const handleSubmitName = async (event:any) => {
      event.preventDefault();
      setIsValidatingSchema(true);
      setIsLoading((prevLoading) => ({ ...prevLoading, searchLink: true }));
      try {
        const response :any= await fetchSingleWhatsAppLink({brandItem} );
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsLoading((prevLoading)=>({...prevLoading, searchLink:false}))
        setIsValidatingSchema(false);
      }
    };
    const handleCreateWhatsappLink = (values: FormikValues,{resetForm}:any) => {
      setIsLoading((prevLoading)=>({...prevLoading, createLink:true}))
      createFreeWHatsappLink(values).then((res) => {
        setUserLink(res.data.data)
        // console.log("this is it", userLink)
        setIsComingSoon(true)
        resetForm({
          values: {
            countryCode: "+234",
            phoneNumber: "",
            message: "",
          },
      })
        setIsLoading((prevLoading)=>({...prevLoading, createLink:false}))
      
      }).catch((err:any)=>{
        console.log("na here the error dey", err)
      });
    };
   
const contactSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select a country code"),
    phoneNumber: Yup.string()
      .min(10)
      .max(11, "cannot add more than 11 digits")
      .required("Please enter a 11-digit number"),
    message: Yup.string().required("Please provide your message"),


  });


  const controlCopyLink = () => {
   copy(userLink?.shortLink)
   showNotification({
    message: "Link copied",
    color: "green",
  });
  };

  const closeModal = (()=>{
    setIsComingSoon(!isComingSoon)
  })
  return (
    <Box>
              {isComingSoon && <Wassaplinksuccess onclose={closeModal} link={userLink?.shortLink}  onclick={controlCopyLink} />}
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Create whatsapp link" link="/dashboard" />

       
      </Flex>
      <Box>
    <Box>
<Image src={idea} alt="idea"/>
    </Box>
</Box>

<Box padding={6} bg="#F1FFF3" fontSize={12} color="#4CB051" mt={4}>
To open a WhatsApp chat, typically, you'd require the user's phone number and go through a saving and searching process. However, WhatsApp links, like "click to chat," simplify this with a single click. wassapview offers free links with a wassapview domain and QR code, with no registration needed.  
</Box>
<Flex justifyContent="space-between"fontSize={12}  mt={10}>
    <Box>
Quick actions
    </Box>
    <Link to="/dashboard/created-links">
    <Box color="#4CB051"fontSize={10} fontWeight={600}>
        View created links
    </Box>
    </Link>
 
</Flex>
<Link to="/dashboard/whatsapp-link/create-free-link">
<Flex flex="column" mt={4} bg="#DDF0FF" w="100%" p={3} borderRadius={20}>
    {/* <Box > */}
<Image src = {link} alt=" " width={4}/>
<Box fontSize={12} ml={6}>
Create a free whatsapp link
    </Box>
    {/* </Box> */}
</Flex>
</Link>
<Link to="/dashboard/whatsapp-link/create-brand-link">
<Flex flex="column" mt={4} bg="#FFF5D9" w="100%" p={3} borderRadius={20}>
    {/* <Box > */}
<Image src = {ranking} alt=" " width={4}/>
<Box fontSize={12} ml={6}>
Create a branded whatsapp link
    </Box>
    {/* </Box> */}
</Flex>
</Link>
<Link to="/dashboard/whatsapp-link/open-whatsapp-chat">
<Flex flex="column" mt={4} bg="#E9DAFF" w="100%" p={3} borderRadius={20}>
    {/* <Box > */}
<Image src = {message} alt=" " width={4}/>
<Box fontSize={12} ml={6}>
open whatsapp chat
    </Box>
    {/* </Box> */}
</Flex>
</Link>

<Box fontSize={14} mt={6}>
Create your free link
</Box>
<Box mt={4} fontSize={12}>
Enter your whatsapp phone number
</Box>
<Box w="100%" >
<Formik 

            initialValues={{ phoneNumber: "", countryCode: "+234", message:"" }}
            validationSchema={contactSchema}
            onSubmit={(values, { resetForm }) => {
              handleCreateWhatsappLink(values, { resetForm });
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form
              
              style={{
                width:"100%",
           
              }}
                onSubmit={(e) => {
                  e.preventDefault();

                  if (Object.keys(errors).length > 0) {
                    setIsValidatingSchema(true);
                  } else {
                    setIsValidatingSchema(false);
                    handleSubmit();
                  }
                }}
              >
                {/* <InputGroup display="flex"   width="100%"> */}
                  <div  style={{
                    display:"flex",
                  
                  }}>
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "55px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "5px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                     <CustomInput
                    width="auto"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.phoneNumber
                        ? errors.phoneNumber
                        : null
                    }
                  />
                  </div>
                 
                  <div>

                
                  <div>
               
                  </div>
                  </div>

             
                {/* </InputGroup> */}
<Box fontSize={12} mb={2}>
    A custom message
</Box>
                <Textarea
                    width="100%"
                    padding={3}
                    height={40}
                    background="#F1F1F1"
                    borderRadius={5}
                    focusBorderColor="#F1F1F1"
                    border="none"
                    outline={0}
                    placeholder="Enter your custom messages that users will send you"
                    name="message"
                    value={values.message} 
                    onChange={handleChange}
                    // error={
                    //   isValidatingSchema && errors.name
                    //     ? errors.name
                    //     : null
                    // }
                    
                  />

                <Box marginBottom="20px" marginTop="30px">
                  <Button   type="submit" variant="primary" size="normal">
                    {isLoading.createLink ? "Please wait..." : "Generate your free link"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
      
         
              <form
               onSubmit={handleSubmitName}
              style={{
                width:"100%",
           
              }}
                
              >
                {/* <InputGroup display="flex"   width="100%"> */}
                <Box fontSize={12}>
                 Find whatsapp link for your brand
                 </Box>
                  <div  style={{
                    display:"flex",
                  
                  }}>
               
                     <CustomInput
                    width="auto"
                    placeholder="Your brand"
                    name="brandItem"
                    value={brandItem}
                    onChange={handleInputChange}
                    error={isValidatingSchema && !brandItem ? 'Please provide your brand' : null}
                  />
                  </div>
                 
                  <div>

                
                  <div>
               
                  </div>
                  </div>

             
                {/* </InputGroup> */}


               


                <Box marginBottom="10px" marginTop="10px">
                  <Button type="submit" variant="primary" size="normal">
                    {isLoading.searchLink ? "Please wait..." : "Search for link"}
                  </Button>
                </Box>
              </form>
            
      
</Box>

    </Box>
  );
};

export default Wassaplink;
