import React, { useState } from "react";
import { Button, MiddleModal } from "../../../../components";
import { Text } from "@chakra-ui/react";
import copy from "clipboard-copy";
import { showNotification } from "@mantine/notifications";

interface props {
  onclose: () => void;
  shareFunction: () => void;
}
const ContactSuccessModal = ({ onclose, shareFunction }: props) => {
  const [appLink] = useState(process.env.REACT_APP_LIVE_URL);
  const handleCopyLink = () => {
    copy("https://www.wassapview.com/");
    showNotification({
      message: "Link copied. Share with friends",
      color: "green",
    });
  };
  return (
    <MiddleModal onclose={onclose}>
      <Text fontSize="18px" fontWeight={700} marginBottom={4}>
        Contact Submitted successfully
      </Text>
      <Text fontSize={14} textColor="#717171" marginBottom="24px">
        You are now eligible to download free Whatsapp Contacts kindly share our
        App link to access it.
      </Text>

      <Button
        // type="button"
        variant="primary"
        size="normal"
        disabled={false}
        onClick={shareFunction}
      >
        Share app link
      </Button>
    </MiddleModal>
  );
};

export default ContactSuccessModal;
