import React from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Input } from '@chakra-ui/react'
import { CiSearch } from "react-icons/ci";
import CustomInput from '../../../components/core/input/CustomInput';
import { useNavigate } from 'react-router-dom';

const MyWhatsappContacts = () => {

  const navigate = useNavigate()

  const openDetail = ()=>{
    navigate("/dashboard/bulk/bulk-messages/uploaded-whatsapp-numbers")
  }
  return (
<>
<OtherHeader/>
<PageHeader link="/dashboard/bulk/bulk-whatsapp-message" text="My Contacts" />

<Box w="100%" bg="#f1f1f1" borderRadius="50px" h="50px">
    <Flex justifyContent="space" alignItems="center" height="100%">
        <Box w="10%" ml={4}>
        <CiSearch  fontSize={20} />

        </Box>
  <Input border={0} outline="none" w="80%" placeholder='Search for uploaded contact list' bg="transparent"/>
  
    </Flex>
</Box>

<Box mt={4} borderBottom="1px solid" p={3} borderColor="#f1f1f1" onClick={openDetail} >
  <Flex alignItems="center">
  <Box w="50px" textAlign="center" bg="#d6f2d7" height="50px" borderRadius="50%" fontWeight={600}><Flex h="100%"  justifyContent="center" alignItems="center">
    U
    </Flex></Box>
  <Box ml={3}>


  <Box>
    <Flex><Box fontWeight={600} fontSize={12}>UI/UX devs</Box>
  <Box fontSize={10} ml={2} bg="red" w="100px" borderRadius="10px" color="#fff" textAlign="center">90 contacts</Box></Flex>
  </Box>
  <Box fontSize={12} mt={2}>Uploaded 23/03/2022</Box>

  </Box>

  </Flex>

</Box>
</>
  )
}

export default MyWhatsappContacts