import { AiOutlineInstagram } from "react-icons/ai";
import {FiTwitter} from "react-icons/fi"
import { CiStar } from "react-icons/ci";
// import { FaCrown } from "react-icons/fa";
import { HiOutlineDocumentText, HiOutlineUserCircle } from "react-icons/hi";
import { TbBrandTelegram } from "react-icons/tb";

export const items = [
  {
    name: "Profile",
    Icon: HiOutlineUserCircle,
    color: "purple.830",
    link: "/dashboard/user-profile",
  },
  {
    name: "Telegram Channel",
    Icon: TbBrandTelegram,
    color: "blue.main",
    link: "https://t.me/wassapview1/",
    target: "_blank",
  },
  {
    name: "Instagram",
    Icon: AiOutlineInstagram,
    color: "orange.main",
    link: "https://www.instagram.com/wassapview_tv/?igshid=MzRlODBiNWFlZA%3D%3D",
    target: "_blank",
  },
  {
    name: "Twitter",
    Icon: FiTwitter,
    color: "purple.830",
    link: "https://x.com/wassapview_tv?s=21&t=5iqCl2Qkrkt9c8W84QpoaA",
    target: "_blank",
  },
  {
    name: "Terms and Policy",
    Icon: HiOutlineDocumentText,
    color: "red.main",
    link: "/dashboard/terms-and-privacy",
  },
  {
    name: "Contact Us",
    Icon: CiStar,
    color: "purple.830",
    link: "https://wa.me/+2347033035020",

    target: "_blank",
  },
  // {
  //   name: "Go Premium",
  //   Icon: FaCrown,
  //   color: "grey.15",
  //   link: "#",
  // },
];
