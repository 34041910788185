import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FormikValues } from "formik";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";

const useDisableWhatsAppTv = () => {
  const user = useSelector((state: RootState) => state.user);

  const disableWhatsAppTv = async () => {
    return await axiosBaseInstance
      .patch(
        "/WhatsappTv/disablewhatsapptv",
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.authToken}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return { disableWhatsAppTv };
};

export default useDisableWhatsAppTv;
