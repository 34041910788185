import { Box, Grid } from "@chakra-ui/react";

import "./transactionhistory.module.scss";
import {
  Header,
  NoTransactionNotice,
  OtherHeader,
  PageHeader,
  TransactionCard,
} from "../../../components";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { transactionInterface } from "../../../types/components/componentInterfaces";

const TransactionHistory = () => {
  const transactions = useSelector((state: RootState) => state.transactions);

  return (
    <>
      <OtherHeader />

      <PageHeader link="/dashboard/wallet" text=" Wallet Transaction" />
      {/* <Grid
        templateColumns="repeat(2, 1fr)"
        borderRadius="5px"
        padding="3px"
        margin="10px 0"
        backgroundColor="rgba(151, 151, 151, 0.1)"
      >
        <input
          type="radio"
          name="transaction"
          id="all"
          style={{ display: "none" }}
          defaultChecked
        />
        <label htmlFor="all" style={{ cursor: "pointer" }}>
          Transaction
        </label>
        
        <input
          type="radio"
          name="transaction"
          id="deposit"
          style={{ display: "none" }}
        />
        <label htmlFor="deposit" style={{ cursor: "pointer" }}>
          Deposit
        </label> *
      </Grid> */}

      <Box>
        {transactions.length === 0 ? (
          <NoTransactionNotice text="No transaction record yet" />
        ) : (
          <>
            {transactions?.map((e) => (
              <TransactionCard
                key={e.id}
                id={e.id}
                amount={e.amount}
                transactionType={e.transactionType}
                narration={e.narration}
              />
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default TransactionHistory;
