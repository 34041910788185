import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { updateTransactions } from "../../redux/reducers/transactionsReducer";

const useFetchTransactions = () => {
  const user = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const fetchTransactions = async () => {
    return await axiosBaseInstance
      .get(`/Wallet/Transactions`, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        console.log(res)
        dispatch(updateTransactions(res.data.data));

        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  return { fetchTransactions };
};

export default useFetchTransactions;
