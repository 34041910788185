import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";


interface emailFoem {
 phoneNumbers?:any
  }
  

const useSaveContacts = () => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const SubmitContacts = async (values: emailFoem) => {
    setLoading(true)
    return await axiosBaseInstance
      .post("/BulkSms/Save-Contacts", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        setLoading(false)

        // console.log(res)
        if(res.data.status === true){
showNotification({
    message: res.data.message ,
    color: "green",
})
        }else{
            showNotification({
                message: res.data.message ,
                color: "red",
            })

        setLoading(false)
            
        }
        setLoading(false)


        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
      
  };

  return { SubmitContacts , loading};
};

export default useSaveContacts;
