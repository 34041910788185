import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


const useCreateWhatsappLink = () => {
  const user = useSelector((state: RootState) => state.user);

// const [loading]
  const createFreeWHatsappLink = async (values: FormikValues) => {

    return await axiosBaseInstance
      .post("/WassapviewLinks/CreateFreeLink", values, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        console.log(res)
        return res;
      })
      .then((err) => {
        return err;
      });
  };

  return { createFreeWHatsappLink };
};

export default useCreateWhatsappLink;
