import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { HiDotsVertical } from "react-icons/hi";

import {
  Button,
  BottomModal as ModalLayout,
  OtherHeader,
  RatingModal,
  Review,
} from "../../../components";
import {
  NoReviewNotice,
  PageHeader,
  Price,
  Service,
} from "../../../components";
import useFetchMyWhatsappTv from "../../../hooks/whatsapptv-hooks/useFetchMyWhatsappTv";
import { useEffect, useState } from "react";
import {
  reviewInterface,
  whatsappTvInterface,
} from "../../../types/components/componentInterfaces";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { Rating, Loader } from "@mantine/core";
import Menu from "./components/Menu";
import useDisableWhatsAppTv from "../../../hooks/whatsapptv-hooks/useDisableWhatsAppTv";
import { Link } from "react-router-dom";
import ShareModal from "../../../components/Modals/ShareModal/ShareModal";
import useFetchTvReviews from "../../../hooks/whatsapptv-hooks/useFetchTvReviews";

const MyWhatsappTv = () => {
  const { fetchMyWhatsappTv } = useFetchMyWhatsappTv();
  const [isReview, setIsReview] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [isMenuModal, setIsMenuModal] = useState(false);
  const [isDisableModal, setIsDisableModal] = useState(false);
  const [myWhatsappTv, setMyWhatsappTv] = useState<whatsappTvInterface>(
    {} as whatsappTvInterface
  );
  const [reviews, setReviews] = useState<Array<reviewInterface>>([]);
  const [isCheckingAvailableTV, setIsCheckingAvailableTV] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const navigate = useNavigate();
  const { disableWhatsAppTv } = useDisableWhatsAppTv();
  const { getReviews } = useFetchTvReviews();

  const controlMenuModal = () => {
    setIsMenuModal(!isMenuModal);
  };

  const controlDisableModal = () => {
    setIsDisableModal(!isDisableModal);
  };

  const handleDisable = () => {
    disableWhatsAppTv().then((res) => {
      setIsCheckingAvailableTV(false);
      controlDisableModal();
      if (res.status === true) {
        navigate("/dashboard");
      }
    });
  };

  const handleEdit = () => {
    localStorage.setItem("tvInitialValues", JSON.stringify(myWhatsappTv));
  };

  const handleWhatsappTv = () => {
    setIsLoading(true);
    fetchMyWhatsappTv().then((data) => {
      setIsLoading(false);
      setIsCheckingAvailableTV(false);

      if (data.data.userId === null) {
        navigate("/dashboard/create-whatsapp-tv");
      } else {
        setMyWhatsappTv(data.data);
      }
    });
  };

  const controlShare = () => {
    setIsShareModal(!isShareModal);
  };

  const changeTvName = () => {
    const arr = myWhatsappTv.whatsAppTVName.split(" ");
    const newString = arr.join("-");
    return newString;
    // console.log(newString);
  };

  useEffect(() => {
    setIsCheckingAvailableTV(true);

    handleWhatsappTv();
  }, []);

  useEffect(() => {
    if (myWhatsappTv.id) {
      getReviews(myWhatsappTv?.id).then((data) => {
        if (data.data.length > 0) {
          console.log(data);
          setReviews(data.data);
          setIsReview(true);
        }
      });
    }
  }, [myWhatsappTv.id]);

  return (
    <>
      <Box position="relative">
        <OtherHeader />
        {isCheckingAvailableTV === true ? (
          <Box>
            <Loader
              style={{ margin: "100px auto" }}
              size="lg"
              color="#4cb051"
            />
          </Box>
        ) : (
          <>
            {isRatingModal && (
              <RatingModal
                closeModal={() => {
                  setIsRatingModal(!isRatingModal);
                }}
                whatsAppTvId={myWhatsappTv?.id}
              />
            )}
            <PageHeader
              text={myWhatsappTv?.whatsAppTVName}
              link="/dashboard"
              rightIcon={
                <HiDotsVertical onClick={controlMenuModal} cursor="pointer" />
              }
            />

            {isShareModal && (
              <ShareModal
                onclose={controlShare}
                link={`whatsapptv/${changeTvName()}`}
              />
            )}

            {isMenuModal && (
              <Menu
                controlModal={controlMenuModal}
                controlDisable={controlDisableModal}
                controlEdit={handleEdit}
                controlShare={controlShare}
              />
            )}

            {isDisableModal && (
              <ModalLayout hideModal={controlDisableModal}>
                <Text
                  fontSize="18px"
                  fontWeight="700"
                  textAlign="center"
                  color="black.main"
                  marginBottom="20px"
                >
                  Are you sure you want to disable your whatsApp TV ?
                </Text>
                <Flex gap="10px">
                  <Button
                    variant="primary"
                    size="normal"
                    onClick={handleDisable}
                  >
                    {isLoading ? "Loading..." : "Yes"}
                  </Button>

                  <Button
                    variant="secondary"
                    size="normal"
                    onClick={controlDisableModal}
                  >
                    No
                  </Button>
                </Flex>
              </ModalLayout>
            )}

            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mt="10px"
              borderColor="#979797"
              borderBottomWidth="0.2px"
              mb="10px"
            >
              <Image
                src={myWhatsappTv.whatsAppTVProfilePicture}
                boxSize="100px"
                borderRadius="8px"
                objectFit="cover"
              />

              <Box mb="10px">
                <Text fontSize="20px" fontWeight="500">
                  {myWhatsappTv.whatsAppViewRange}
                </Text>
                <Text
                  fontSize="16px"
                  fontWeight="400"
                  color="grey.dark"
                  mb="10px"
                >
                  WhatsApp Daily Views
                </Text>
                <Link
                  to={`https://wa.me/${myWhatsappTv.countryCode}${myWhatsappTv.whatsAppNumber}`}
                  target="_blank"
                >
                  <Text
                    color="green.100"
                    fontSize="16px"
                    fontWeight="400"
                    display="flex"
                    alignItems="center"
                    mb="10px"
                  >
                    <span style={{ marginRight: "5px" }}>Message</span>{" "}
                    <FaWhatsapp />
                  </Text>
                </Link>

                <Flex alignItems="center">
                  <Text color="#000" marginRight="5px ">
                    {" "}
                    4.5{" "}
                  </Text>
                  <Rating
                    value={4.5}
                    fractions={2}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsRatingModal(true);
                    }}
                  />
                </Flex>
              </Box>
            </Box>
            <Box>
              <Text
                fontSize="16px"
                fontWeight="500"
                mt="20px"
                mb="20px"
                color="black.main"
              >
                Pricing
                <span
                  style={{
                    color: "var(-chakra-colors-green-100)",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  /post
                </span>
              </Text>

              <Flex align="center" justify="space-between" w="95%">
                <Box textAlign="center">
                  <Service name="Link" />
                  <Price
                    amount={myWhatsappTv.linkPrice ? myWhatsappTv.linkPrice : 0}
                  />
                </Box>
                <span
                  style={{
                    height: "40px",
                    width: "2px",
                    backgroundColor: "#a5a5a5",
                  }}
                ></span>
                <Box textAlign="center">
                  <Service name="Status" />
                  <Price
                    amount={
                      myWhatsappTv.picturePrice ? myWhatsappTv?.picturePrice : 0
                    }
                  />
                </Box>
                <span
                  style={{
                    height: "40px",
                    width: "2px",
                    backgroundColor: "#a5a5a5",
                  }}
                ></span>
                <Box textAlign="center">
                  <Service name="Video" />
                  <Price
                    amount={
                      myWhatsappTv?.videoPrice ? myWhatsappTv.videoPrice : 0
                    }
                  />
                </Box>
              </Flex>
            </Box>

            <Box>
              {isReview ? (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt="20px"
                    mb="20px"
                  >
                    <Text fontSize="16px" fontWeight="500">
                      Reviews
                    </Text>
                    <Link
                      to={`/dashboard/whatsapp-tvs/${myWhatsappTv.id}/reviews`}
                    >
                      <span
                        style={{
                          color: "var(--chakra-colors-green-100)",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        See All
                      </span>
                    </Link>
                  </Box>

                  <Box overflowY="auto" h="300px">
                    {reviews?.map((review) => (
                      <Review
                        picture={review.picture}
                        name={review.name}
                        review={review.review}
                        createdDate={`${review.createdDate}`}
                      />
                    ))}
                  </Box>
                </>
              ) : (
                <NoReviewNotice />
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default MyWhatsappTv;
