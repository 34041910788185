import React from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import useGetCardById from '../../../hooks/creator-hooks/useGetCardById'
import useDeleteCard from '../../../hooks/creator-hooks/useDeleteCard'
import {useNavigate} from "react-router-dom"

const DeleteCard = () => {
    const {id} = useParams()
    const {data} = useGetCardById(id)
    const navigation = useNavigate()


    // console.log(data)
    const {deleteCard} = useDeleteCard(id)

    const handleDelete = async()=>{
     const deleteItem =   await deleteCard()
    //  console.log(deleteItem)
     navigation("/dashboard/creator/become-a-creator")
    }


  return (
   <>
     <OtherHeader />
       {/* {success && <OptionNav onclose={closeModal}/>} */}
     
        <PageHeader text="Card Details" link="/dashboard/creator/become-a-creator" />
        <Box height="100%">
            <Flex flexDirection="column" fontSize={12}>
                <Box mb={2} fontWeight="600">
                    Name
                </Box>
                <hr/>
                <Box mt={2} >{data.name}</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
                  Whatsapp Number
                </Box>
                <hr/>
                <Box mt={2} >{data.whatsappNumber}</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
           description
                </Box>
                <hr/>
                <Box mt={2} >{data.description}</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
                  Contact Limit
                </Box>
                <hr/>
                <Box mt={2} >{data.contactLimit}</Box>
            </Flex>
            <Box mt={14} width="100%">
<Flex justifyContent="center">
<Button onClick={handleDelete} width="full" borderRadius="60px" h="6y0px" bg="#f01111" color="#fff" p={2}>Delete Card</Button>
</Flex>
            </Box>
            
        </Box>
       
   </>
  )
}

export default DeleteCard