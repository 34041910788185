import { Box, Flex, Textarea } from "@chakra-ui/react";
import {
 
    Button,
  OtherHeader,
  PageHeader,
} from "../../../components";

// import { Link } from "react-router-dom";



import DetailsForm from "./DetailsForm";
import { RiErrorWarningLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomInput from "../../../components/core/input/CustomInput";
import useEditCard from "../../../hooks/creator-hooks/useEditCard";
import { useParams } from "react-router-dom";
import useGetCardById from "../../../hooks/creator-hooks/useGetCardById";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FaSmile } from "react-icons/fa";
import { showNotification } from "@mantine/notifications";


 
const EditCard = () => {
  
 const {id} = useParams()
 const wallet = useSelector((state: RootState) => state.wallet);
// console.log(wallet)
const Balance = wallet.balance
// console.log(Balance)

    const [percentages, setPercentages] = useState<any>(0)
    // console.log(percentages)
    const {data} = useGetCardById(id)
    const [Loading, setLoading] =useState(false) 
    // console.log(data)


  const {EditCard} = useEditCard()
    const [totalFee] = useState(50);
    const [formValues, setFormValues] = useState<any>({
        id: id,
        name: data.name || "",
        title: data.title || "",
        whatsappNumber: data.whatsappNumber || "",
        description: data.description || "",
        contactLimit: data.contactLimit || percentages,
        totalFee: data.contactLimit ? data.contactLimit * 50 : totalFee * parseInt(percentages)
      });
      
      // console.log(formValues);
      
      const handlePercentageChange = (e: any) => {
        const value = parseInt(e.target.value);
        setPercentages(value);
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          contactLimit: value,
          totalFee: value ? value * 50 : totalFee * parseInt(percentages)
        }));
      };
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormValues((prevValues: any) => ({
          ...prevValues,
          [name]: value !== "" ? value : prevValues[name]
        }));
      };
    const handleSubmit =async (event:any) => {
      event.preventDefault();
   
    
   try {
       
    // console.log(formValues);
    setLoading(true)
    const response = await EditCard(formValues).then(()=>{
        setLoading(false)
        showNotification({
            title: "Success",
            message: "Edit successful",
            color: "green",
          });
       navigation(`/dashboard/creator/single-card-detail/${id}`)

    })
  // console.log(response)
   } catch (error) {
    // console.log(error)
    setLoading(false)
   }
    //   navigation("/dashboard/creator/card-form")
    };
 
    
 
  const navigation = useNavigate()
 
 




  return (
    <Box>
            
      <OtherHeader />

        <PageHeader text="Create New Card" link={`/dashboard/creator/single-card-detail/${id}`} />

       
  
    


        <Box>
              

       
  
    





              <Box w="100%" >
              
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box mt={4} fontSize={12}>
                  Name
                </Box>
                <CustomInput
                  width="auto"
                  placeholder="Enter Card Name"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  required
                />
              
              
                <Box mt={4} fontSize={12}>
                  Title
                </Box>
                <CustomInput
                  width="auto"
                  placeholder="Enter Card Title"
                  name="title"
                  value={formValues.title}
                  onChange={handleChange}
                  required
                />
            
              
            
             
              
                <Box mt={4} fontSize={12}>
                  Description
                </Box>
                <Textarea
                  width="100%"
                  padding={3}
                  height={40}
                  background="#F1F1F1"
                  borderRadius={5}
                  focusBorderColor="#F1F1F1"
                  border="none"
                  outline={0}
                  placeholder="Enter a Description"
                  name="description"
                  value={formValues.description}
                  onChange={handleChange}
            
                />
             
                <Box fontSize={12} mb={8} mt={4} fontWeight="600">
                Contact limit
              </Box>
              <Box mt={2} textAlign="center" fontWeight="600">
                {percentages}
              </Box>
              <input
              
                type="range"
                min="1"
                max="1000"
                value={percentages}
                  onChange={handlePercentageChange}
                style={{ width: '100%', cursor: 'pointer', color:"#f0c608 !important", background:"#f0c608" }}
              />
              
              
              
                {/* <Progress value={percentage} color="#f0c608" /> */}
                
                  <Box marginTop={4}>
                    <Flex alignItems="center">
                      <RiErrorWarningLine color="#09bfe8" />
                      <Box fontSize={10} fontWeight="600" marginLeft={2}>
                        Your contact limit can't exceed 1000 contact.
                      </Box>
                    </Flex>
                  </Box>
              
                <Box marginTop={4} fontSize="10px">
                  <Flex alignItems="center">
                    <Box>Total Fee:</Box>
                    <Box fontWeight="bold" marginLeft={2}>
                    {totalFee*percentages}
                    </Box>
                  </Flex>
                </Box>
              
              {
                Balance >formValues.totalFee? <Box marginBottom="20px" marginTop="30px">
                <Button type="submit" variant="primary" size="normal">
          {
            Loading?"Please wait":"Submit"
          }
                </Button>
              </Box>: <Box marginBottom="20px" marginTop="30px">
                <Box color="red" fontSize="12px">
                    Insufficient Balance
                </Box>
              <Button disabled type="submit" variant="primary" size="normal">
                Submit
              </Button>
            </Box>
              }
               
              </form>
                    
                       
                      
                          
                    
              </Box>
              
                  </Box>


    </Box>
  );
};

export default EditCard;
