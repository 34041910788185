import { Box, Flex, Text } from "@chakra-ui/react";
import Header from "../Header/Header";

const NoReview = () => {
  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      height="40vh"
      alignItems="center"
      justifyContent="center"
      padding="0 30px"
    >
      <Text fontSize="20px" fontWeight="700" color="#000">
        No Reviews
      </Text>
      <Text fontSize="14px" color="grey.main">
        Reviews would be shown here once it is available
      </Text>
    </Flex>
  );
};

export default NoReview;
