import { useEffect, useState } from "react";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const useGetCreatedCard = () => {
  const [data, setData] = useState<any>([]);
  const user = useSelector((state: RootState) => state.user);
  useEffect(() => {
    const fetched = async () => {
      try {
        await axiosBaseInstance
          .get("/Creator/GetAllCreatedCards", {
            headers: {
              Authorization: `Bearer ${user.authToken}`,
            },
          })
          .then((res:any) => {
            console.log("card", res.data);
          setData(res.data);
          });
      } catch (error: any) {
        console.log(error);
      }
    };

    fetched();
  }, []);

  return { data };
};

export default useGetCreatedCard;
