import {
  NoTransactionNotice,
  OtherHeader,
  PageHeader,
  TransactionCard,
} from "../../../components";
import { Box, Flex, Text } from "@chakra-ui/layout";
import walletAdd from "../../../assets/svgs/wallet-add.svg";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import hide from "../../../assets/svgs/hide.svg";
import { useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useFetchBalance from "../../../hooks/wallet-hooks/useFetchBalance";
import useFetchTransactions from "../../../hooks/wallet-hooks/useFetchTransactions";
const Wallet = () => {
  const [isHidden, setIsHidden] = useState(false);
  const wallet = useSelector((state: RootState) => state.wallet);
  const { fetchBalance } = useFetchBalance();
  const { fetchTransactions } = useFetchTransactions();
  const transactions = useSelector((state: RootState) => state.transactions);
console.log(transactions)
  const hideBalance = () => {
    setIsHidden(true);
  };

  const showBalance = () => {
    setIsHidden(false);
  };

  useEffect(() => {
    fetchBalance();
    fetchTransactions();
  }, []);
  return (
    <>
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader link="/dashboard" text="Wallet " />

        <Box>
          <Link to="top-up">
            <img
              src={walletAdd}
              alt="credit wallet"
              style={{
                backgroundColor: "var(--chakra-colors-green-80)",
                borderRadius: "100%",
                padding: "8px",
              }}
            />
          </Link>
        </Box>
      </Flex>

      <Box
        borderRadius="10px"
        padding="10px"
        marginBottom="20px"
        border="grey.light"
        borderWidth="0.3px"
        minHeight="97px"
      >
        <Flex alignItems="center" color="grey.dark" fontSize="18px">
          <Text marginRight="10px">Balance</Text>
          {isHidden ? (
            <AiOutlineEyeInvisible onClick={showBalance} cursor="pointer" />
          ) : (
            <AiOutlineEye onClick={hideBalance} cursor="pointer" />
          )}
        </Flex>
        <Text fontSize="38px" color="black.main" fontWeight="800">
          {isHidden ? (
            <>
              <img
                src={hide}
                alt="hide balance"
                style={{ marginTop: "15px" }}
              />
            </>
          ) : (
            <> {`  N${wallet.balance.toFixed(2)}`}</>
          )}
        </Text>
      </Box>

      <Box>
        <Flex justifyContent="space-between" paddingBottom="20px">
          <Text>Recent Transaction</Text>
          <Text color="green.100" fontWeight="500" fontSize="14px">
            <Link to="transaction-history">view all</Link>
          </Text>
        </Flex>
        <Box>
          {transactions.length === 0 ? (
            <NoTransactionNotice text="No transaction record yet" />
          ) : (
            <>
              {transactions?.map((e) => (
                <TransactionCard
                  key={e.id}
                  id={e.id}
                  narration={e.narration}
                  createdDate={e.createdDate}
                  amount={e.amount}
                  transactionType={e.transactionType}
                />
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
