// import slide from "../../../../assets/svgs/ad.svg";
import slide1 from "../../../../assets/images/slide1.jpg";
import slide2 from "../../../../assets/images/slide2.jpg";
import slide3 from "../../../../assets/images/slide3.jpg";
import slide4 from "../../../../assets/images/slide4.jpg";
import slide5 from "../../../../assets/images/slide5.jpg";
import slide6 from "../../../../assets/images/slide6.jpg";
// import phone from "../../../../assets/svgs/phone.svg";
// import rocket from "../../../../assets/svgs/rocket.svg";
// import shop from "../../../../assets/svgs/shop.svg";
// import folder from "../../../../assets/svgs/folder.svg";
// import tv from "../../../../assets/svgs/tv.svg";
// import link from "../../../../assets/svgs/link.svg";
// import mobile from "../../../../assets/svgs/mobile.svg";
// import creator from "../../../../assets/svgs/creator.svg";
// import send from "../../../../assets/svgs/send.svg";

export const slides = [slide1, slide2, slide3, slide4, slide5, slide6];
