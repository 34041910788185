import { Box } from "@chakra-ui/react";
import { selectInputInterface } from "../../../types/components/componentInterfaces";

const SelectInput = ({
  onChange,
  required,
  disabled,
  label,
  id,
  placeholder,
  values,
  error,
  value,
}: selectInputInterface) => {
  return (
    <Box w="100%" marginBottom="15px">
      <label
        style={{
          fontSize: "14px",
          fontWeight: "500",
          marginBottom: "5px",
          color: "var(--chakra-colors-black-main)",
        }}
      >
        {label}
      </label>
      <Box>
        <select
          onChange={onChange}
          id={id}
          required={required}
          value={value}
          disabled={disabled ? disabled : null}
          placeholder={placeholder}
          style={{
            backgroundColor: "#F1F1F1",
            height: "55px",
            padding: "10px 4.5rem 10px 10px",
            // paddingRight: "4.5rem",
            border: "none",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <option
            selected
            disabled
            style={{ opacity: 1, color: "var(--chakra-colors-gray-500)" }}
          >
            {placeholder}
          </option>
          {values?.map((e) => (
            <option value={e.id}>{e.name}</option>
          ))}
        </select>
      </Box>

      {error && <div className="error">{error}</div>}
    </Box>
  );
};

export default SelectInput;
