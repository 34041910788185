import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useState } from "react";


interface CardForm {
    id: any;

    totalSpend: any;
    duration: any;

  }
  

const useDeleteCard = (cardId:any) => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user);

  const deleteCard = async () => {
    setLoading(true)
    return await axiosBaseInstance
      .delete(`/Creator/DeleteCard?cardId=${cardId}`, {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      })
      .then((res) => {
        // console.log(res)
        setLoading(false)

        return res.data;
      })
      .then((err) => {
        setLoading(false)

        return err;
      });
  };

  return { deleteCard , loading};
};

export default useDeleteCard;
