import { Box, Flex, Image, InputGroup, Text, Textarea } from "@chakra-ui/react";
import {
    ComingSoonModal,
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { Button, BottomModal as ModalLayout } from "../../../components";
import { useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import idea from "../../../assets/images/idea.png"
import link from "../../../assets/images/limk.png"

import ranking from "../../../assets/images/ranking.png"
import message from "../../../assets/images/message.png"
import CustomInput from "../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";

// import useSubmitContact from "../../../../../hooks/vcf-hooks/useSubmitContact";
// import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import useFetchwhatsappSearch from "../../../hooks/whatsapplink-hooks/useFetchwhatsappSearch";

 
const BrandLink = () => {
    const [isValidatingSchema, setIsValidatingSchema] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const { submitContact } = useSubmitContact();
//   const controlLogout = () => {
  const [brandItem, setBrandItem] = useState('');
  const { fetchSingleWhatsAppLink } = useFetchwhatsappSearch();

//     setIsConfirmLogout(!isConfirmLogout);
//   };
const handleInputChange = (event:any) => {
  setBrandItem(event.target.value);
};

const handleSubmitName = async (event:any) => {
  event.preventDefault();
  setIsValidatingSchema(true);
  setIsLoading(true);
  try {
    const response :any= await fetchSingleWhatsAppLink({brandItem} );
  } catch (error) {
    console.error('API Error:', error);
  } finally {
    setIsLoading(false)
    setIsValidatingSchema(false);
  }
};
const navigate = useNavigate()
const contactSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select a country code"),
    whatsAppNumber: Yup.string()
      .min(10)
      .max(11, "cannot add more than 11 digits")
      .required("Please enter a 11-digit number"),
    name: Yup.string().required("Please provide your name"),


  });


  const controlComingSoonModal = () => {

  };
  return (
    <Box>
           
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Whatsapp link for your brand" link="/dashboard/create-whatsapp-link" />

       
      </Flex>
      <Box>
    <Box>
<Image src={idea} alt="idea"/>
    </Box>
</Box>

<Box padding={6} bg="#F1FFF3" fontSize={12} color="#4CB051" mt={4}>
To open a WhatsApp chat, typically, you'd require the user's phone number and go through a saving and searching process. However, WhatsApp links, like "click to chat," simplify this with a single click. wassapview offers free links with a wassapview domain and QR code, with no registration needed.  
</Box>




<Box mt={4} fontSize={12}>
Find whatsapp link for your brand
</Box>
<Box w="100%" >
{/* <Formik 

            initialValues={{ whatsAppNumber: "", countryCode: "+234", daysrange:"" }}
            validationSchema={contactSchema}
            onSubmit={(values) => {
            }}
          > */}
              <form
              style={{
                width:"100%",
           

              }}
              onSubmit={handleSubmitName}
               
              >
          <CustomInput
                    width="auto"
                    placeholder="Your brand"
                    name="brandItem"
                    value={brandItem}
                    onChange={handleInputChange}
                    error={isValidatingSchema && !brandItem ? 'Please provide your brand' : null}
                  />
                   
                  <div>
  
                
                  <div>
               
                  </div>
                  </div>

             
                {/* </InputGroup> */}

              

                <Box marginBottom="20px" marginTop="30px">
                  <Button  type="submit" variant="primary" size="normal">
                    {isLoading ? "Please wait..." : "Search for brand name"}
                  </Button>
                </Box>
              </form>
          
      
</Box>

    </Box>
  );
};

export default BrandLink;
