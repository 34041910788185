import { useSelector } from "react-redux";
import { axiosBaseInstance } from "../../services/axiosBaseInstance";
import { RootState } from "../../redux/store";

import { useEffect, useState } from "react";
const useFreeFiles = () => {
  const user = useSelector((state: RootState) => state.user);
  // console.log(user)
// 
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetchFiles = async () => {
      return await axiosBaseInstance
        .get(`/WhatsappVCF/Get-Free-DownloadFiles`, {
          headers: {
            Authorization: `Bearer ${user.authToken}`,
          },
        })
        .then((res) => {
          setData(res.data.data);
          // console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchFiles();
  }, []);

  return { data };
};

export default useFreeFiles;
