import { Box, Flex,Textarea } from "@chakra-ui/react";

import { Button, OtherHeader, PageHeader } from "../../../components";
import { useParams } from "react-router-dom";
import { RiErrorWarningLine } from "react-icons/ri";

import CustomInput from "../../../components/core/input/CustomInput";


import { useNavigate } from "react-router-dom";
import useGetCardById from "../../../hooks/creator-hooks/useGetCardById";
import { Progress } from "@mantine/core";
import useRenewCard from "../../../hooks/creator-hooks/useRenewCard";



 
const RenewCard = () => {

   
 const {id} = useParams()
    
 
  const navigation = useNavigate()
 
   
const {renewCard,loading} = useRenewCard(id)
const renew = async()=>{
  await renewCard().then((res)=>{
    // console.log(res)
  }).catch((error)=>{
    console.log(error)
  })

}
   
const {data} = useGetCardById(id)
// console.log(data)

  return (
    <>
     <OtherHeader />

<PageHeader text="Renew New Card" link="/dashboard/creator/become-a-creator" />
<Box>
              

       
  
    





              <Box w="100%" >
              
              {/* <form  style={{ width: "100%" }}> */}
                <Box mt={4} fontSize={12}>
                  Name
                </Box>
                <CustomInput
                  width="auto"
                  placeholder={data.name}
                  name="name"
                
                disabled={true}
             
                />
              
              
                <Box mt={4} fontSize={12}>
                  Title
                </Box>
                <CustomInput
                  width="auto"
                  placeholder={data.title}
                  name="title"
                
                 disabled={true}
                />
           
              
                <Box mt={4} fontSize={12}>
                  WhatsApp Number
                </Box>
                <CustomInput
                  width="auto"
                  placeholder={data.whatsappNumber}
                  name="whatsappNumber"
                  disabled={true}
              
                />
              
              
                <Box mt={4} fontSize={12}>
                  Address
                </Box>
                <CustomInput
                  width="auto"
                  placeholder={data.address}
                  name="address"
                  disabled={true}
                
                />
            
              
                <Box mt={4} fontSize={12}>
                  Description
                </Box>
                <Textarea
                  width="100%"
                  padding={3}
                  height={40}
                  background="#F1F1F1"
                  borderRadius={5}
                  focusBorderColor="#F1F1F1"
                  border="none"
                  outline={0}
                  placeholder={data.description}
                  name="description"
                  disabled
                
                />
               
                <Box fontSize={12} mb={8} mt={4} fontWeight="600">
                Contact limit
              </Box>
              <Box mt={2} textAlign="center" fontWeight="600">
               {
                data.contactLimit
               }
              </Box>
            
              
              
              
                <Progress value={100} color="#f0c608" />
        
              
                <Box marginTop={4} fontSize="10px">
                  <Flex alignItems="center">
                    <Box>Total Fee:</Box>
                    <Box fontWeight="bold" marginLeft={2}>
                 {
                  data.contactLimit * 50
                 }
                    </Box>
                  </Flex>
                </Box>
              
                <Box marginBottom="20px" marginTop="30px">
                  <Button onClick={renew}  variant="primary" size="normal">
              {
                loading? "Please wait ..." :'Renew'
              }
                  </Button>
                </Box>
              {/* </form> */}
                    
                       
                      
                          
                    
              </Box>
              
                  </Box>
    </>
   
  );
};

export default RenewCard;
