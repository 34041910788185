import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { fund } from "../../types/components/componentInterfaces";

export const initialState: fund = {
    email:"",
    transactionref:"",
    amount:0,
    paymentCheckoutUrl:"",
    virtualAccountExist:false,
    virtualAccounts:[]
  };

const fundaccountReducer = createSlice({
  name: "fundwallet",
  initialState,
  reducers: {
    funds: (
        state = initialState,
        action: PayloadAction<fund>
      ) => {
        return (state = { ...action.payload });
      },
  },
});

export const { funds } = fundaccountReducer.actions;
export default fundaccountReducer.reducer;
