import React from 'react'
import { OtherHeader, PageHeader } from '../../../components'
import { Box, Button, Flex } from '@chakra-ui/react'
import useDeleteContact from '../../../hooks/creator-hooks/useDeleteContact'
import {useParams} from "react-router-dom"
const DeleteContact = () => {
  const {id} = useParams()
  const {deleteContact} = useDeleteContact(id)

  const handleDelete = async()=>{
    await deleteContact()
  }
  return (
   <>
     <OtherHeader />
       {/* {success && <OptionNav onclose={closeModal}/>} */}
     
        <PageHeader text="Card Details" link="/dashboard/creator/become-a-creator" />
        <Box height="100%">
            <Flex flexDirection="column" fontSize={12}>
                <Box mb={2} fontWeight="600">
                    Name
                </Box>
                <hr/>
                <Box mt={2} >Dickson Eneh</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
                  Whatsapp Number
                </Box>
                <hr/>
                <Box mt={2} >09130308557</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
           Address
                </Box>
                <hr/>
                <Box mt={2} >Ikeja, Lagos</Box>
            </Flex>
            <Flex flexDirection="column" fontSize={12} mt={24}>
                <Box mb={2} fontWeight="600">
                  Phone Number
                </Box>
                <hr/>
                <Box mt={2} >09130308557</Box>
            </Flex>
            <Box mt={14} width="100%">
<Flex justifyContent="center">
<Button onClick={handleDelete} width="full" borderRadius="60px" h="6y0px" bg="#f01111" color="#fff" p={2}>Delete Contact</Button>
</Flex>
            </Box>
            
        </Box>
       
   </>
  )
}

export default DeleteContact