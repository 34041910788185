import { Box, Flex, Image, InputGroup, Text, Textarea } from "@chakra-ui/react";
import {
    ComingSoonModal,
  DownloadContactTemp,
  OtherHeader,
  PageHeader,
} from "../../../components";
import { Button, BottomModal as ModalLayout } from "../../../components";
import { useState } from "react";

import idea from "../../../assets/images/idea.png"



import CustomInput from "../../../components/core/input/CustomInput";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { countryCodes } from "../../../config/data";

import useChatUser from "../../../hooks/whatsapplink-hooks/useChatUser";


 
const Openchat = () => {
    const [isComingSoon, setIsComingSoon] = useState(false);
    const [isValidatingSchema, setIsValidatingSchema] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
 

  

  const {createFreeWHatsappChat}= useChatUser()

  const handleCreateWhatsappLink = (values: FormikValues) => {
    setIsLoading((prevLoading) => !prevLoading);
    createFreeWHatsappChat(values)
      .then((res) => {
        console.log(res);
        window.location.href=res.data.whatsAppUrl
        setIsLoading(false);
        setIsComingSoon(true);
     
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error:", err);
      });
  };
  
  
//   };
const contactSchema = Yup.object().shape({
    countryCode: Yup.string().required("Please select a country code"),
    phoneNumber: Yup.string()
      .min(10)
      .max(11, "cannot add more than 11 digits")
      .required("Please enter a 11-digit number"),
    message: Yup.string().required("Please provide your message"),

  });
  const controlComingSoonModal = () => {
    setIsComingSoon(!isComingSoon);
  };
  return (
    <Box>
            
      <OtherHeader />

      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="10px 0"
        marginBottom="10px"
      >
        <PageHeader text="Whatsapp chat" link="/dashboard" />

       
      </Flex>
      <Box>
    <Box>
<Image src={idea} alt="idea"/>
    </Box>
</Box>

<Box padding={6} bg="#F1FFF3" fontSize={12} color="#4CB051" mt={4}>
To open a WhatsApp chat, typically, you'd require the user's phone number and go through a saving and searching process. However, WhatsApp links, like "click to chat," simplify this with a single click. wassapview offers free links with a wassapview domain and QR code, with no registration needed.  
</Box>

<Box fontSize={14} mt={6} fontWeight={600}>
Message any whatsapp number
</Box>
<Box mt={4} fontSize={12}>
Send a message to any number on whatsapp without saving
</Box>
<Box w="100%" >
<Formik 

            initialValues={{ phoneNumber: "", countryCode: "+234", message:"" }}
            validationSchema={contactSchema}
            onSubmit={(values) => {
              handleCreateWhatsappLink(values);
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form
              
              style={{
                width:"100%",
           
              }}
                onSubmit={(e) => {
                  e.preventDefault();

                  if (Object.keys(errors).length > 0) {
                    setIsValidatingSchema(true);
                  } else {
                    setIsValidatingSchema(false);
                    handleSubmit();
                  }
                }}
              >
                {/* <InputGroup display="flex"   width="100%"> */}
                  <div  style={{
                    display:"flex",
                  
                  }}>
                    <select
                      name="countryCode"
                      value={values.countryCode}
                      onChange={handleChange}
                      style={{
                        // width: "30%",
                        marginRight: "8px",
                        borderRadius: "10px",
                        height: "55px",
                        backgroundColor: "#F1F1F1",
                        marginTop: "5px",
                      }}
                    >
                      {countryCodes?.map((code) => (
                        <option value={code}>{code}</option>
                      ))}
                    </select>
                    {errors.countryCode && (
                      <div className="error">{errors.countryCode}</div>
                    )}
                     <CustomInput
                    width="auto"
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={
                      isValidatingSchema && errors.phoneNumber
                        ? errors.phoneNumber
                        : null
                    }
                  />
                  </div>
                 
                  <div>

                
                  <div>
               
                  </div>
                  </div>

             
                {/* </InputGroup> */}
<Box fontSize={12} mb={2}>
    A custom message
</Box>
                <Textarea
                    width="100%"
                    padding={3}
                    height={40}
                    background="#F1F1F1"
                    borderRadius={5}
                    focusBorderColor="#F1F1F1"
                    border="none"
                    outline={0}
                    placeholder="Enter your custom messages that users will send you"
                    name="message"
                    value={values.message} 
                    onChange={handleChange}
                    // error={
                    //   isValidatingSchema && errors.name
                    //     ? errors.name
                    //     : null
                    // }
                    
                  />

                <Box marginBottom="20px" marginTop="30px">
                  <Button   type="submit" variant="primary" size="normal">
                    {isLoading? "Please wait..." : "Open chat"}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        
</Box>

    </Box>
  );
};

export default Openchat;
