import React from 'react'
import { Button, OtherHeader, PageHeader } from '../../../components'
import { Box, Flex, Textarea } from '@chakra-ui/react'
import CustomInput from '../../../components/core/input/CustomInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { whatsappNumberInterface } from '../../../types/auth/authInterfaces'

const DispatchWhatsappMessage = () => {
  const whatsappNumber = useSelector((state: RootState) => state.whatsAppNumber);
  console.log(whatsappNumber)
  return (
    <>
      <OtherHeader />
    <Box>
<Flex alignItems="center">

<PageHeader link="/dashboard/bulk/bulk-whatsapp-message" text="Send Whatsapp Message" />

</Flex>
</Box>
<Box mt={3}>
    <Box fontWeight="bold">
        Send your Whatsapp message
    </Box>
    <Box mt={3}>

        <Box fontWeight={600}>Title</Box>
        <CustomInput placeholder='Enter title of message'/>
    </Box>
    <Box mt={4} fontWeight={600}>
        Contacts
    </Box>
    <Box mt={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
    {
    whatsappNumber?.phoneNumber?.map((props: whatsappNumberInterface, index: number) => (
      <React.Fragment key={index}>
        {props.phoneNumber}{index !== whatsappNumber?.phoneNumber?.length - 1 && ','}
      </React.Fragment>
    ))
  }
   <Box bg="#00000070" textAlign="center" p="3px" w="80px" borderRadius={10} color="#fff">{whatsappNumber?.phoneNumber?.length} {" "} {"contacts"}</Box>
    </Box>
    <Box mt={4} fontWeight={600}>
      Text Message
    </Box>
    <Box mt={4} p={4} borderRadius="10px" w="100%" h="250px" bg="#f2f2f2">
<Textarea h="85%" w="100%" bg="transparent" border={0} outline="none">

</Textarea>
<Box textAlign="right">
    1/200
</Box>
    </Box>
    <Box mt={36}>
        <Flex>
            <Box>Total Fee</Box>
            <Box fontWeight="bold">N400.00</Box>
        </Flex>
    </Box>
    <Box>
    <Button variant="primary" size="normal">
      Send Message
    </Button>
    </Box>

</Box>
</>
  )
}

export default DispatchWhatsappMessage 